import React, { useState } from "react";
import CatalogLayout from "../CatalogLayout";
import { Flex, Space, Button, Dropdown,  Table } from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  SettingOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

const EmissionFactor = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "EF Set Name",
      dataIndex: "equipment",
      key: "equipment",
      width: 660,
      sorter: (a, b) => a.equipment - b.equipment,
    },
    {
      title: "Calculation Method",
      dataIndex: "category",
      key: "category",
      width: 300,
    },
    {
      title: "Category",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Singapore EMA Energy Statistics 2023 (2022 grid year - preliminary)",
      category: "Location-Based",
      itemSource: "Singapore",
    },
    {
      key: "2",
      equipment: "US EPA - eGRID 2022 State (Publication Year 2024)",
      category: "Location-Based",
      itemSource: "Japan",
    },
    {
      key: "3",
      equipment: "Singapore EMA Energy Statistics 2023 (2022 grid year - preliminary)",
      category: "Location-Based",
      itemSource: "Singapore",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];
  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Emission Factors (Default)</h4>
        </Space>
      </Flex>

      <Flex justify="space-between" align="center" className="mt-24">
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Electric Grid Emission Factors
          </Button>
        </Dropdown>
        <Button
          shape="circle"
          icon={<SettingOutlined />}
          iconPosition="end"
          className="fw-500 text-black"
        ></Button>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>
    </CatalogLayout>
  );
};

export default EmissionFactor;
