import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_KEY, token } from "../../config";

const initState = {
  data: null,
  loading: false,
  error: "",
};

export const saveBankInfo = createAsyncThunk(
  "verification/bank-info",
  async (data, { rejectWithValue }) => {
    console.log("data?.raw ", data?.raw);
    const raw = {
      iban: data?.raw?.iban,
      bankName: data?.raw?.bankName,
      accountTitle: data?.raw?.accountTitle,
      branch_location: data?.raw?.branchLocation,
      country_id: data?.raw?.country,
      city_id: data?.raw?.city,
    };
    console.log("raw ", raw);
    const config = {
      method: "post",
      url: data?.url,
      headers: {
        "x-api-key": API_KEY,
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
      },
      data: raw,
    };

    try {
      const response = await axios(config);
      console.log("Response:", response);
      return response.data;
    } catch (err) {
      console.error("Error details:", err);

      let result;
      if (err.response && err.response.data && err.response.data.detail) {
        if (typeof err.response.data.detail === "string") {
          try {
            const parsedDetail = JSON.parse(err.response.data.detail);
            result = parsedDetail?.detail?.[0]?.msg || parsedDetail;
          } catch (parseError) {
            result = err.response.data.detail;
          }
        } else {
          result =
            err.response.data.detail?.detail?.[0]?.msg ||
            err.response.data.detail;
        }
      } else {
        result = "An error occurred";
      }

      console.error("Parsed error message:", result);
      return rejectWithValue(result);
    }
  }
);

const bankInfoSlice = createSlice({
  name: "bankInfoSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveBankInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBankInfo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(saveBankInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default bankInfoSlice.reducer;
