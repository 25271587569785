import React from "react";
import { Card, Button, Space, Dropdown, Row, Col, Tabs } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DoughnutChart from "../../../sharedModules/charts/DoughnutChart";
import EnergyCalculator from "./EnergyCalculator";

const EnergyTransition = () => {
  const scope = [
    {
      title: "Acme Factory",
      size: "20000 sqft",
      intensity: "4",
      consumption: "75.24",
      scopeCount: "81,855.4",
    },
    {
      title: "Acme Factory",
      size: "1200 sqft",
      intensity: "22",
      consumption: "24.75",
      scopeCount: "26,930.23",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];
  return (
    <>
      <Row gutter={[20, 16]}>
        <Col md={6}>
          <Card className="" title="Modeled footprint reduction">
            <Space direction="vertical" size="middle" className="w-100">
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  108,788.36{" "}
                  <sup className="text-black-60 fw-500 fs-12">tCO2e</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Current total footprint
                </p>
              </Card>
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  0 <sup className="text-black-60 fw-500 fs-12">tCO2e</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Modeled footprint reduction with <br />
                  clean electricity model
                </p>
              </Card>
              <Card className="text-center body-pad-16">
                <h3 className="mb-2 mt-0 fw-700">
                  0 <sup className="text-black-60 fw-500 fs-12">%</sup>
                </h3>
                <p className="fw-500 fs-12 text-black m-0">
                  Footprint reduction %
                </p>
              </Card>
            </Space>
            <div className="mt-24 dounot-chart">
              <DoughnutChart />
            </div>
          </Card>
        </Col>
        <Col md={18}>
          <Card
            title="Update to see how your footprint can be reduced"
            extra={
              <Space>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                  >
                    Sort by
                  </Button>
                </Dropdown>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                  >
                    Filter Facilities
                  </Button>
                </Dropdown>
                <Button shape="round" className="btn-h-40 fw-500 text-black">
                  Reset All
                </Button>
              </Space>
            }
          >
            <Row justify="space-between">
              <Col>
                <p className="m-0 text-black fs-14 fw-500">
                  2 Total Facilities
                </p>
              </Col>
              <Col>
                <div className="text-right">
                  <h4 className="m-0 fw-600 ff-mont">108,788.36</h4>
                  <p className="m-0 text-black fs-12 fw-400">tCO₂e</p>
                </div>
              </Col>
            </Row>

            <Row align="middle" className="py-2" gutter={[16, 8]}>
              <Col md={7}>
                <p className="m-0 text-black fs-12 fw-600">Facility Details</p>
              </Col>
              <Col md={11}>
                <p className="m-0 text-black fs-12 fw-600">
                  % of currency scope 1 emissions decrese
                </p>
              </Col>
              <Col md={6}>
                <div className="text-right">
                  <p className="m-0 text-black fs-12 fw-600">
                    Modeled emissions
                  </p>
                </div>
              </Col>
            </Row>

            {scope.map((val) => {
              return <EnergyCalculator val={val} />;
            })}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EnergyTransition;
