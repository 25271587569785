import { Form, Select, Spin } from 'antd';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useFetchStatesData } from '../../hooks/useApiHook';
import { API_SETTINGS_BASE_URL } from '../../config';

const StatesSelect = ({ setStateId, countryId, isPersonalInfo }) => {

    const { states, statesLoader, statesErr } = useFetchStatesData(
        `${API_SETTINGS_BASE_URL}/v1/general_settings/states/?skip=0&limit=250&country_id=${countryId}`
    );


    return (
        <>
            <Form.Item
                label="State / province"
                name="state"
                rules={[
                    {
                        required: true,
                        message: "Required state",
                    },
                ]}
            >
                <Select
                    placeholder="Please Select"
                    style={{ position: "rel" }}
                    onSelect={(selected) => {
                        if (isPersonalInfo) {
                            let result = states?.find(
                                (state) => state?.name === selected
                            );
                            setStateId(result?.id);
                        } else {
                            setStateId(selected)
                        }
                    }}
                    disabled={countryId ? false : true}
                    dropdownRender={(menu) => (
                        <div>
                            {statesErr && <p style={{ padding: "1rem" }}>
                                Something went wrong, please refresh the page
                            </p>}
                            {menu}
                            {statesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <Spin />
                                </div>
                            )}
                        </div>
                    )}
                >
                    {states?.length > 0
                        ? states?.map((state) => (
                            <Select.Option key={state.id} value={isPersonalInfo ? state?.name : state?.id} >
                                {state?.name}
                            </Select.Option>
                        ))
                        : null}
                </Select>
            </Form.Item>
        </>
    )
}

export default StatesSelect
