import React from "react";
import { Layout, Button, Space, Dropdown, Row, Col, Tabs } from "antd";

import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import FooprintReduction from "./FooprintReduction";
import EnergyTransition from "./EnergyTransition";

const { Header } = Layout;

const Reduce = () => {
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const tabItems = [
    {
      key: "Fooprint reduction",
      label: "Fooprint reduction",
      children: <FooprintReduction />,
    },
    {
      key: "Energy transition",
      label: "Energy transition",
      children: <EnergyTransition />,
    },
  ];
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Footprint Source Checklist</h4>
        <Space>
          <Button
            type="gray"
            shape="round"
            icon={<CalendarOutlined />}
            iconPosition="start"
            size="default"
            className="btn-h-40"
          >
            Past Year
          </Button>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button
              type="gray"
              shape="round"
              icon={<DownOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
            >
              Acme Corporation
            </Button>
          </Dropdown>
        </Space>
      </Header>
      <div className="main-content">
        <div className="steps-wizard">
          <Tabs
            defaultActiveKey="Account settings"
            items={tabItems}
            // onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Reduce;
