import React, { useState } from "react";
import ConfirmationLayout from "../../../sharedModules/confirmationLayout";
import { Button, Col, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import envelope from "../../../images/envelope.png";
import { Link, useLocation } from "react-router-dom";
import { resetPasswordRequest } from "../../../utils/api-services/auth";

const ResetConfirm = () => {
  const location = useLocation();
  const [msg, setMsg] = useState({ type: null, message: null });
  const [loading, setLoading] = useState(false);

  const { email } = location.state || {};

  const onFinish = async () => {
    const url = "/v1/auth_sso/forgot-password-sso/";
    try {
      setLoading(true);
      const response = await resetPasswordRequest(email, url);
      setLoading(false);
      if (response?.status === 200) {
        console.log("success");
        setMsg({ type: "success", message: "Email resent successfully." });
        setTimeout(() => {
          setMsg({ type: null, message: null });
        }, 5000);
      } else {
        console.log("failed");
      }
    } catch (error) {
      setLoading(false);
      console.log("errorr ", error);
      setMsg({ type: "error", message: error?.message });

      setTimeout(() => {
        setMsg({ type: null, message: null });
      }, 5000);
    }
  };
  return (
    <ConfirmationLayout>
      <div className="process-complete">
        <div>
          <img src={envelope} alt="img" className="img-fluid" />
        </div>
        <div>
          <h2 className="m-0 fw-600">Please check your email</h2>
          <p className="text-black-60 mt-10 mb-0">
            We have sent a reset password link to your email{" "}
            <span className="text-black">{email ?? ""}</span> <br /> Please
            click on the link to reset your password.
          </p>
        </div>
        <div>
          <p className="text-black-60 mb-20">
            Not received the email yet?{" "}
            <Link className="fw-600 text-black-60">Resend the email</Link>
          </p>

          <Col md={24}>
            {msg.type && (
              <p
                style={{
                  color: msg.type === "error" ? "#FF4D4F" : "#25956a",
                  marginTop: "-15px",
                }}
              >
                {msg?.message}
              </p>
            )}
          </Col>

          <Button
            onClick={onFinish}
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="large"
          >
            {loading && (
              <Spin tip="Loading" size="small" className="custom-spinner" />
            )}
            Resend the email
          </Button>
        </div>
      </div>
    </ConfirmationLayout>
  );
};

export default ResetConfirm;
