import React from "react";
import ConfirmationLayout from "../../sharedModules/confirmationLayout";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import envelope from "../../images/envelope.png";
import { useNavigate } from "react-router-dom";

const VerificationComp = () => {
  const navigate = useNavigate();
  const onFinish = () => {
    navigate("/verification");
  };
  return (
    <ConfirmationLayout>
      <div className="process-complete">
        <div>
          <img src={envelope} className="img-fluid" />
        </div>
        <div>
          <h2 className="m-0 fw-600">Verification completed</h2>
          <p className="text-black-60 mt-10 mb-0">
            We have sent your uploaded information to our verification team. It
            will take upto 48 hours for them to verify your account details.
          </p>
        </div>
        <div>
          <Button
            onClick={onFinish}
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="large"
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    </ConfirmationLayout>
  );
};

export default VerificationComp;
