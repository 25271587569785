import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_KEY, API_SETTINGS_BASE_URL, token } from "../../config";

const initState = {
  data: null,
  goalsAndTargets: null,
  loading: false,
  error: "",
  goalsAndTargetsErr: "",
};

export const getGoalsAndTargets = createAsyncThunk(
  "verification/get-goals-and-targets",
  async (url, { rejectWithValue }) => {
    let values = [];
    const sectionNames = [
      "Number of AhyaOS Account members",
      "Accomplishments with AhyaOS",
      "Scope 3 sub-categories",
      "Emissions measurement history",
      "AI enabled measurement interest",
      "AhyaOS Support",
      "Emission reduction target",
      "Additional support with AhyaOS",
      "Sustainability standard",
    ];

    for (let sectionName of sectionNames) {
      let url = `${API_SETTINGS_BASE_URL}/v1/general_settings/user-settings/?skip=0&limit=50&section_name=${sectionName}`;
      try {
        const response = await axios.get(url, {
          headers: {
            "x-api-key": API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });
        values.push({ sectionName, values: response.data });
      } catch (error) {
        console.log("error ", error);
      }
    }

    console.log("values ", values);
    return values;
    //   try {
    //     const response = await apiClient.get(url);
    //     return response.data;
    //   } catch (err) {
    //     if (!err.response) {
    //       throw err;
    //     }
    //     console.log("err ", err);
    //     const message = err.response.data?.message ?? "An error occurred";
    //     return rejectWithValue(message);
  }
);

export const saveGoalsAndTargets = createAsyncThunk(
  "verification/tax-info",
  async (data, { rejectWithValue }) => {
    console.log("files", data?.raw);
    console.log("files", data?.raw?.files);
    let formData = new FormData();
    formData.append("certificate_or_license", data?.raw?.files?.companyIdImage);
    formData.append("tax_document", data?.raw?.files?.taxDocument);
    formData.append("others", data?.raw?.files?.otherImage);
    formData.append("tax_number", data?.raw?.taxNumber);

    const formDataEntries = [...formData.entries()];
    console.log("FormData contents:", formDataEntries);

    const config = {
      method: "post",
      url: data?.url,
      headers: {
        "x-api-key": API_KEY,
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      console.log("Response:", response);
      return response.data;
    } catch (err) {
      console.error("Error details:", err);

      let result;
      if (err.response && err.response.data && err.response.data.detail) {
        if (typeof err.response.data.detail === "string") {
          try {
            const parsedDetail = JSON.parse(err.response.data.detail);
            result = parsedDetail?.detail?.[0]?.msg || parsedDetail;
          } catch (parseError) {
            result = err.response.data.detail;
          }
        } else {
          result =
            err.response.data.detail?.detail?.[0]?.msg ||
            err.response.data.detail;
        }
      } else {
        result = "An error occurred";
      }

      console.error("Parsed error message:", result);
      return rejectWithValue(result);
    }
  }
);

const generalSettingsSlice = createSlice({
  name: "generalSettingsSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveGoalsAndTargets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveGoalsAndTargets.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(saveGoalsAndTargets.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getGoalsAndTargets.pending, (state) => {
        state.loading = true;
        state.goalsAndTargetsErr = null;
      })
      .addCase(getGoalsAndTargets.fulfilled, (state, action) => {
        state.goalsAndTargets = action.payload;
        state.loading = false;
      })
      .addCase(getGoalsAndTargets.rejected, (state, action) => {
        state.goalsAndTargetsErr = action.payload;
        state.loading = false;
      });
  },
});

export default generalSettingsSlice.reducer;
