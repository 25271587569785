import React from "react";
import { Button, Row, Col } from "antd";
import swap from "../../../images/swap.svg";
const AddCatalog = ({
  onClose,
  equipmentModalData,
  addManully,
  learnMore = "true",
}) => {
  return (
    <>
      <div className="px-40">
        {equipmentModalData?.content.map((data, ind) => {
          return (
            <p className="text-blad" key={ind}>
              {data}
            </p>
          );
        })}
        {learnMore ? (
          <Button
            type="gray"
            shape="round"
            className="btn-h-40 mt-24"
            onClick={onClose}
          >
            Learn More
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className="activity-row mt-24">
        <div className="activity-head">
          <h5 className="m-0 text-black">Upload options</h5>
          <p className="m-0 text-blad fs-12">
            How would you like to upload this data?
          </p>
        </div>
        <div className="activity-body">
          <Row gutter={[21, 21]}>
            <Col md={12}>
              <div
                style={{
                  padding: 40,
                  textAlign: "center",
                  borderRadius: 24,
                  border: "1px solid rgba(10, 15, 41, 0.08)",
                  cursor: "pointer",
                }}
                onClick={addManully}
              >
                <img src={swap} />
                <h4 className="m-0 mt-24 ff-mont fw-500 text-black">
                  Add {equipmentModalData.type.toLowerCase()} manually
                </h4>
              </div>
            </Col>
            <Col md={12}>
              <div
                style={{
                  padding: 40,
                  textAlign: "center",
                  borderRadius: 24,
                  border: "1px solid rgba(10, 15, 41, 0.08)",
                }}
              >
                <img src={swap} />
                <h4 className="m-0 mt-24 ff-mont fw-500 text-black">
                  Upload File as Excel or CSV
                </h4>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddCatalog;
