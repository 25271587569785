import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Space,
  Dropdown,
  Row,
  Col,
  Divider,
  Flex,
  Radio,
  Tabs,
  DatePicker,
  Form,
} from "antd";
import {
  CalendarOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import ScopeStats from "./scopeStats";

const { Header } = Layout;
const Analytics = () => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const handleOk = () => {
    setPickerOpen(false);
  };

  const handleCancel = () => {
    setPickerOpen(false);
  };

  const allScope = {
    title: "All scope emissions",
    lineChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Assets",
          lineTension: 0,
          pointRadius: 0,
          data: [5, 4, 5, 6, 9, 8, 12, 15, 12, 9, 12, 14],
          borderColor: "#7F7C71",
          backgroundColor: "#7F7C71",
        },
        {
          label: "Sites",
          lineTension: 0,
          pointRadius: 0,
          data: [2, 6, 5, 8, 2, 11, 10, 14, 18, 19, 10, 8],
          borderColor: "#556956",
          backgroundColor: "#556956",
        },
        {
          label: "Supply chain",
          lineTension: 0,
          pointRadius: 0,
          data: [6, 8, 9, 12, 11, 10, 12, 14, 15, 18, 19, 17],
          borderColor: "#3F686E",
          backgroundColor: "#3F686E",
        },
        {
          label: "People activity",
          lineTension: 0,
          pointRadius: 0,
          data: [5, 7, 6, 2, 1, 0, 2, 4, 18, 16, 18, 19],
          borderColor: "#4F1619",
          backgroundColor: "#4F1619",
        },
        {
          label: "Financed emissions",
          lineTension: 0,
          pointRadius: 0,
          data: [5],
          borderColor: "#D95E28",
          backgroundColor: "#D95E28",
        },
      ],
    },
    barChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Financed emissions",
          data: [15.0, 0, 0, 0, 0, 0, 0, 0, 5.0, 0, 0, 0],
          backgroundColor: "#D95E28",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "People activity",
          data: [
            30.0, 20.0, 18.0, 22.0, 20.0, 25.0, 20.0, 22.0, 22.0, 25.0, 24.0,
            22.0,
          ],
          backgroundColor: "#4F1619",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Supply chain",
          data: [
            25.0, 22.0, 24.0, 20.0, 22.0, 22.0, 25.0, 22.0, 23.0, 22.0, 20.0,
            23.0,
          ],
          backgroundColor: "#3F686E",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Sites",
          data: [
            10.0, 15.0, 14.0, 14.0, 10.0, 15.0, 14.0, 15.0, 16.0, 17.0, 15.0,
            15.0,
          ],
          backgroundColor: "#556956",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Assets",
          data: [
            20.0, 33.0, 36.0, 30.0, 36.0, 33.0, 36.0, 36.0, 34.0, 31.0, 29.0,
            30.0,
          ],
          backgroundColor: "#7F7C71",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
      ],
    },
    tableData: [
      {
        key: "1",
        category: "Assets",
        jan23: "26 tCO2e",
        feb23: "24 tCO2e",
        mar23: "26 tCO2e",
        apr23: "26 tCO2e",
        may23: "26 tCO2e",
        jun23: "26 tCO2e",
        july23: "26 tCO2e",
        aug23: "26 tCO2e",
        sep23: "26 tCO2e",
        oct23: "26 tCO2e",
        nov23: "26 tCO2e",
        dec23: "26 tCO2e",
      },
      {
        key: "2",
        category: "Financed emissions",
        jan23: "15 tCO2e",
        feb23: "---",
        mar23: "---",
        apr23: "---",
        may23: "---",
        jun23: "---",
        july23: "---",
        aug23: "---",
        sep23: "17 tCO2e",
        oct23: "26 tCO2e",
        nov23: "---",
        dec23: "---",
      },
      {
        key: "3",
        category: "People activity",
        jan23: "6 tCO2e",
        feb23: "7 tCO2e",
        mar23: "6 tCO2e",
        apr23: "5 tCO2e",
        may23: "7 tCO2e",
        jun23: "6 tCO2e",
        july23: "7 tCO2e",
        aug23: "7 tCO2e",
        sep23: "7 tCO2e",
        oct23: "26 tCO2e",
        nov23: "4 tCO2e",
        dec23: "6 tCO2e",
      },
      {
        key: "4",
        category: "Sites",
        jan23: "28 tCO2e",
        feb23: "31 tCO2e",
        mar23: "29 tCO2e",
        apr23: "29 tCO2e",
        may23: "31 tCO2e",
        jun23: "31 tCO2e",
        july23: "34 tCO2e",
        aug23: "32 tCO2e",
        sep23: "40 tCO2e",
        oct23: "26 tCO2e",
        nov23: "38 tCO2e",
        dec23: "34 tCO2e",
      },
      {
        key: "5",
        category: "Supply chain",
        jan23: "80 tCO2e",
        feb23: "77 tCO2e",
        mar23: "90 tCO2e",
        apr23: "69 tCO2e",
        may23: "71 tCO2e",
        jun23: "80 tCO2e",
        july23: "86 tCO2e",
        aug23: "79 tCO2e",
        sep23: "80 tCO2e",
        oct23: "26 tCO2e",
        nov23: "99 tCO2e",
        dec23: "73 tCO2e",
      },
    ],
  };

  const Scope1 = {
    title: "Scope1 emissions",
    lineChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Assets",
          lineTension: 0.05,
          pointRadius: 0,
          data: [30, 40, 30, 60, 70, 40, 50, 45, 60, 30, 35, 50, 78],
          borderColor: "#7F7C71",
          backgroundColor: "#7F7C71",
        },
        {
          label: "Sites",
          lineTension: 0.05,
          pointRadius: 0,
          data: [2, 6, 5, 8, 2, 11, 10, 14, 18, 19, 10, 8],
          borderColor: "#556956",
          backgroundColor: "#556956",
        },
      ],
    },
    barChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Sites",
          data: [2, 6, 5, 8, 2, 11, 10, 14, 18, 19, 10, 8],
          backgroundColor: "#556956",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Assets",
          data: [5, 4, 5, 6, 9, 8, 12, 15, 12, 9, 12, 14],
          backgroundColor: "#7F7C71",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
      ],
    },
    tableData: [
      {
        key: "1",
        category: "Assets",
        jan23: "16 tCO2e",
        feb23: "12 tCO2e",
        mar23: "18 tCO2e",
        apr23: "19 tCO2e",
        may23: "15 tCO2e",
        jun23: "16 tCO2e",
        july23: "15 tCO2e",
        aug23: "16 tCO2e",
        sep23: "15 tCO2e",
        oct23: "13 tCO2e",
        nov23: "17 tCO2e",
        dec23: "16 tCO2e",
      },
      {
        key: "2",
        category: "Sites",
        jan23: "15 tCO2e",
        feb23: "20 tCO2e",
        mar23: "15 tCO2e",
        apr23: "15 tCO2e",
        may23: "16 tCO2e",
        jun23: "17 tCO2e",
        july23: "15 tCO2e",
        aug23: "19 tCO2e",
        sep23: "20 tCO2e",
        oct23: "20 tCO2e",
        nov23: "20 tCO2e",
        dec23: "19 tCO2e",
      },
    ],
  };

  const Scope2 = {
    title: "Scope2 emissions",
    lineChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Assets",
          lineTension: 0.05,
          pointRadius: 0,
          data: [30, 40, 30, 60, 70, 40, 50, 45, 60, 30, 35, 50, 78],
          borderColor: "#7F7C71",
          backgroundColor: "#7F7C71",
        },
        {
          label: "Sites",
          lineTension: 0.05,
          pointRadius: 0,
          data: [2, 6, 5, 8, 2, 11, 10, 14, 18, 19, 10, 8],
          borderColor: "#556956",
          backgroundColor: "#556956",
        },
      ],
    },
    barChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Assets",
          data: [45, 55, 60, 72, 85, 90, 110, 120, 100, 85, 95, 110],
          backgroundColor: "#7F7C71",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Sites",
          data: [30, 40, 35, 50, 45, 60, 65, 70, 85, 90, 80, 75],
          backgroundColor: "#556956",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
      ],
    },
    tableData: [
      {
        key: "1",
        category: "Assets",
        jan23: "1 tCO2e",
        feb23: "1 tCO2e",
        mar23: "2 tCO2e",
        apr23: "3 tCO2e",
        may23: "1 tCO2e",
        jun23: "1 tCO2e",
        july23: "1 tCO2e",
        aug23: "3 tCO2e",
        sep23: "2 tCO2e",
        oct23: "1 tCO2e",
        nov23: "1 tCO2e",
        dec23: "2 tCO2e",
      },
      {
        key: "2",
        category: "Sites",
        jan23: "10 tCO2e",
        feb23: "8 tCO2e",
        mar23: "11 tCO2e",
        apr23: "11 tCO2e",
        may23: "12 tCO2e",
        jun23: "11 tCO2e",
        july23: "16 tCO2e",
        aug23: "10 tCO2e",
        sep23: "17 tCO2e",
        oct23: "9 tCO2e",
        nov23: "15 tCO2e",
        dec23: "12 tCO2e",
      },
    ],
  };

  const Scope3 = {
    title: "Scope3 emissions",
    lineChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Assets",
          lineTension: 0.05,
          pointRadius: 0,
          data: [5, 4, 5, 6, 9, 8, 12, 15, 12, 9, 12, 14],
          borderColor: "#7F7C71",
          backgroundColor: "#7F7C71",
        },
        {
          label: "Sites",
          lineTension: 0.05,
          pointRadius: 0,
          data: [2, 6, 5, 8, 2, 11, 10, 14, 18, 19, 10, 8],
          borderColor: "#556956",
          backgroundColor: "#556956",
        },
        {
          label: "Supply chain",
          lineTension: 0.05,
          pointRadius: 0,
          data: [80, 79, 100, 70, 82, 100, 79, 80, 85, 105, 100, 80],
          borderColor: "#3F686E",
          backgroundColor: "#3F686E",
        },
        {
          label: " People activity",
          lineTension: 0.05,
          pointRadius: 0,
          data: [5, 7, 6, 2, 1, 0, 2, 4, 18, 16, 18, 19],
          borderColor: "#4F1619",
          backgroundColor: "#4F1619",
        },
        {
          label: "Financed emissions",
          lineTension: 0.05,
          pointRadius: 0,
          data: [5, 7, 6, 2, 1, 0, 2, 4, 18, 16, 18, 19],
          borderColor: "#D95E28",
          backgroundColor: "#D95E28",
        },
      ],
    },
    barChart: {
      labels: [
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
      datasets: [
        {
          label: "Financed emissions",
          data: [10, 15, 12, 14, 9, 10, 11, 12, 13, 15, 14, 11],
          backgroundColor: "#D95E28",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "People activity",
          data: [12, 15, 18, 20, 19, 16, 17, 19, 22, 23, 20, 19],
          backgroundColor: "#4F1619",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Supply chain",
          data: [14, 18, 16, 20, 17, 16, 18, 20, 22, 23, 21, 18],
          backgroundColor: "#3F686E",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Sites",
          data: [10, 12, 11, 14, 12, 15, 13, 16, 18, 19, 14, 13],
          backgroundColor: "#556956",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
        {
          label: "Assets",
          data: [11, 14, 12, 15, 13, 14, 15, 17, 16, 14, 13, 15],
          backgroundColor: "#7F7C71",
          borderWidth: 1,
          borderColor: "#fff",
          borderSkipped: false,
          barThickness: 43,
        },
      ],
    },
    tableData: [
      {
        key: "1",
        category: "Assets",
        jan23: "9 tCO2e",
        feb23: "11 tCO2e",
        mar23: "11 tCO2e",
        apr23: "8 tCO2e",
        may23: "10 tCO2e",
        jun23: "10 tCO2e",
        july23: "9 tCO2e",
        aug23: "11 tCO2e",
        sep23: "8 tCO2e",
        oct23: "11 tCO2e",
        nov23: "8 tCO2e",
        dec23: "11 tCO2e",
      },
      {
        key: "2",
        category: "Financed emissions",
        jan23: "15 tCO2e",
        feb23: "---",
        mar23: "---",
        apr23: "---",
        may23: "---",
        jun23: "---",
        july23: "---",
        aug23: "---",
        sep23: "17 tCO2e",
        oct23: "18 tCO2e",
        nov23: "---",
        dec23: "---",
      },
      {
        key: "3",
        category: "People activity",
        jan23: "6 tCO2e",
        feb23: "7 tCO2e",
        mar23: "6 tCO2e",
        apr23: "5 tCO2e",
        may23: "7 tCO2e",
        jun23: "6 tCO2e",
        july23: "7 tCO2e",
        aug23: "7 tCO2e",
        sep23: "7 tCO2e",
        oct23: "6 tCO2e",
        nov23: "4 tCO2e",
        dec23: "6 tCO2e",
      },
      {
        key: "4",
        category: "Sites",
        jan23: "20 tCO2e",
        feb23: "25 tCO2e",
        mar23: "22 tCO2e",
        apr23: "20 tCO2e",
        may23: "22 tCO2e",
        jun23: "25 tCO2e",
        july23: "30 tCO2e",
        aug23: "28 tCO2e",
        sep23: "35 tCO2e",
        oct23: "22 tCO2e",
        nov23: "30 tCO2e",
        dec23: "28 tCO2e",
      },
      {
        key: "5",
        category: "Supply chain",
        jan23: "70 tCO2e",
        feb23: "65 tCO2e",
        mar23: "75 tCO2e",
        apr23: "60 tCO2e",
        may23: "65 tCO2e",
        jun23: "70 tCO2e",
        july23: "75 tCO2e",
        aug23: "68 tCO2e",
        sep23: "70 tCO2e",
        oct23: "20 tCO2e",
        nov23: "85 tCO2e",
        dec23: "60 tCO2e",
      },
    ],
  };

  const tabItems = [
    {
      key: "1",
      label: "All",
      children: <ScopeStats scopeData={allScope} />,
    },
    {
      key: "2",
      label: "Scope 1",
      children: <ScopeStats scopeData={Scope1} />,
    },
    {
      key: "3",
      label: "Scope 2",
      children: <ScopeStats scopeData={Scope2} />,
    },
    {
      key: "4",
      label: "Scope 3",
      children: <ScopeStats scopeData={Scope3} />,
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Analytics</h4>
        <Space>
          <div className="overlay-date">
            <Button
              type="gray"
              shape="round"
              icon={<CalendarOutlined />}
              iconPosition="start"
              className="btn-h-40 fw-500"
              onClick={() => setPickerOpen(true)}
            >
              All time
            </Button>
          </div>

          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button
              type="gray"
              shape="round"
              icon={<DownOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
            >
              Acme Corporation
            </Button>
          </Dropdown>
        </Space>
      </Header>

      <div className="main-content">
        <Row gutter={[20, 16]}>
          <Col md={24}>
            <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} />
          </Col>
        </Row>
      </div>

      <Modal
        open={pickerOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_) => (
          <Flex
            justify="space-between"
            align="center"
            gap="middle"
            style={{
              paddingTop: 24,
              borderTop: "1px solid rgba(5, 5, 5, 0.06)",
            }}
          >
            <Button type="gray" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="start"
              size="default"
            >
              Search
            </Button>
          </Flex>
        )}
      >
        <Form layout="vertical">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="pq">Past quarter</Radio>
              <Radio value="pm">Past month</Radio>
              <Radio value="py">Past year</Radio>
              <Radio value="at">All time</Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Form.Item label="Custom date range">
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Analytics;
