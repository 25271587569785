import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Flex,
  Button,
  Card,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const PasswordChange = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    // Checks if there are any errors
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    // Check if any field is empty
    const formValues = form.getFieldsValue(); // Get all field values as an object
    const hasEmptyFields = Object.values(formValues).some((value) => !value); // Check for empty values

    // Disable the button if there are errors or missing fields
    setIsButtonDisabled(hasErrors || hasEmptyFields);
  };
  return (
    <>
      <Card style={{ marginTop: 24, height: "72vh" }}>
        <div className="card-header">
          <h3 className="m-0">Password</h3>
          <p className="mt-6 mb-0 text-black-60">Update your password here.</p>
        </div>
        <div className="card-body">
          <Form
            layout="vertical"
            form={form}
            className="mt-24"
            onFieldsChange={handleFormChange}
          >
            <Row gutter={[24, 0]}>
              <Col md={24}>
                <Form.Item label="Current password" name="password">
                  <Input.Password placeholder="eg. ●●●●●●●●●●" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="New password" name="newpassword">
                  <Input.Password placeholder="eg. ●●●●●●●●●●" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Re-enter password" name="repassword">
                  <Input.Password placeholder="eg. ●●●●●●●●●●" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          className="btn-h-40 mt-24"
          onClick={() => form.submit()}
          disabled={isButtonDisabled}
        >
          Update & save
        </Button>
      </Flex>
    </>
  );
};

export default PasswordChange;
