import React, { useState } from "react";
import { Button, Flex, Badge, Space, Radio, Layout, Steps } from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import ScopeEmission from "./ScopeEmission";
import BusinessAssets from "./businessAssets";
import ReviewGuided from "./reviewGuided";

const { Header, Footer } = Layout;

const GuidedConfigration = () => {
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const handleButton = (link) => {
    navigate(link);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const steps = [
    {
      title: "Scope and emissions",
      content: <ScopeEmission />,
    },
    {
      title: "Business and assets",
      content: <BusinessAssets />,
    },
    {
      title: "Review",
      content: <ReviewGuided />,
    },
    {
      title: "Completed",
      content: (
        <Flex justify="center" align="center" gap={16} vertical>
          <h3 className="m-0">Guided configuration completed</h3>
          <p className="m-0 text-black-60">
            You can now proceed back to the page of your choice.
          </p>
          <Button
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="large"
            className="btn-h-40"
            onClick={() => handleButton("/measure/footprint")}
          >
            Back to footprint sources
          </Button>
        </Flex>
      ),
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const onChange = (val) => {
    setCurrent(val);
  };

  const guideComplete = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };
  const guideCompleted = {
    flex: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space size="middle">
          <Button
            type="gray"
            shape="circle"
            icon={
              <ArrowLeftOutlined style={{ color: "rgba(15, 19, 36, 0.6)" }} />
            }
            iconPosition="start"
            size="large"
            style={{ width: 48 }}
            onClick={() =>
              handleButton("/measure/footprint/configure-checklist")
            }
          ></Button>
          <h4 className="m-0">Guided Configuration</h4>
        </Space>
        {current < 2 ? (
          <Button
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="default"
            className="btn-h-40"
            onClick={next}
          >
            Save
          </Button>
        ) : current === 2 ? (
          <Button
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="default"
            className="btn-h-40"
            onClick={next}
          >
            Submit
          </Button>
        ) : (
          ""
        )}
      </Header>
      <div
        className="main-content pt-2"
        style={current === 3 ? guideComplete : {}}
      >
        <Steps
          progressDot
          onChange={onChange}
          size="small"
          current={current}
          items={items}
        />
        <div className="mt-3" style={current === 3 ? guideCompleted : {}}>
          {steps[current].content}
        </div>
      </div>
      {current != 3 && (
        <Footer
          style={{
            background: "#fff",
            padding: "17px 40px",
            position: "sticky",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Flex gap="middle" justify="space-between" align="center">
            <Button
              shape="round"
              size="default"
              className="btn-h-40 text-black fw-500"
              onClick={() =>
                handleButton("/measure/footprint/configure-checklist")
              }
            >
              Cancel
            </Button>
            {current != 2 ? (
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                size="default"
                className="btn-h-40"
                onClick={next}
              >
                Save
              </Button>
            ) : (
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                size="default"
                className="btn-h-40"
                onClick={next}
              >
                Submit
              </Button>
            )}
          </Flex>
        </Footer>
      )}
    </>
  );
};

export default GuidedConfigration;
