import React, { useState } from "react";
import {
  Button,
  Flex,
  Space,
  Input,
  Switch,
  Select,
  Table,
  Dropdown,
  Drawer,
  Row,
  Col,
  Form,
  Radio,
} from "antd";
import {
  SettingOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Search from "../../../images/search.svg";
import AddCatalog from "../../measure/catalog/addCatalog";

const OrganizationType = () => {
  const [form] = Form.useForm();
  const equipmentData = {
    type: "Organization",
    content: [
      "An Organizational Type is the naming convention an organization uses to define the structure in which its constituent organizations operate in relation to the parent organization. Common examples are Division, Fund, Portfolio, Company, Subsidiary, etc., but you can define your Organizational Type any way you'd like.",
    ],
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addOrganization, setAddOrganization] = useState(false);
  const [equipmentModalData, setEquipmentModalData] = useState(equipmentData);
  const [addManually, setAddManually] = useState(false);
  const [legalValue, setLegalValue] = useState(false);
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const optionsAS = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const addManully = () => {
    setAddManually(true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const column = [
    {
      title: "Organization Type",
      dataIndex: "organizationType",
      key: "organizationType",
      sorter: (a, b) => a.organizationType - b.organizationType,
      render: (_, { organizationType }) => (
        // onClick={openDrawer}
        <p className="m-0 cursor-pointer fw-500">{organizationType}</p>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description - b.description,
    },
    {
      title: "Financial Accounting Categories",
      dataIndex: "financialAccountingCategories",
      key: "financialAccountingCategories",
      sorter: (a, b) =>
        a.financialAccountingCategories - b.financialAccountingCategories,
    },
    {
      title: <div className="text-right pr-3">Action</div>,
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: editRowDD,
              onClick: (event) => {
                const { key } = event;
                editRow(key, record);
              },
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<EllipsisOutlined />}
              iconPosition="start"
              style={{ transform: "rotate(90deg)", float: "right" }}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const editRow = (key, data) => {
    // if (key === "view") {
    //   setDrawerOpenView(true);
    // }
    // if (key === "edit") {
    //   setDrawerOpenEdit(true);
    // }
    console.log(key);
  };

  const editRowDD = [
    {
      label: "View Details",
      key: "view",
      icon: <EyeOutlined />,
    },
    {
      label: "Edit Details",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const data = [
    {
      key: "1",
      organizationType: "Acme warehouse",
      description: "Retail division",
      financialAccountingCategories: "--",
    },
    {
      key: "1",
      organizationType: "Energy and power station",
      description: "Energy department",
      financialAccountingCategories: "--",
    },
    {
      key: "1",
      organizationType: "Grocery shop",
      description: "Product division",
      financialAccountingCategories: "Group Company/Subsidiary",
    },
  ];

  const onClose = () => {
    setAddManually(false);
    setAddOrganization(false);
  };
  const handleLegalChange = (e) => {
    setLegalValue(e);
  };
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        className="mt-3"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500, borderRadius: 25 }}
        />
        <Space size={[12, 12]}>
          <Button shape="round" className="btn-h-40 fw-500">
            <Space>
              <Switch size="small" />
              <p className="m-0 text-black">Show Archived</p>
            </Space>
          </Button>
          <Button
            shape="circle"
            icon={<SettingOutlined />}
            iconPosition="start"
            className="btn-h-40 fw-500 text-black px-2"
          ></Button>
        </Space>
      </Flex>

      <div className="mt-24">
        <Flex justify="space-between">
          <Select
            placeholder="Type"
            options={[
              {
                value: "AcmeCorporation",
                label: "Acme Corporation",
              },
            ]}
          />
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            className="btn-h-40"
            onClick={() => setAddOrganization(true)}
          >
            Add
          </Button>
        </Flex>
        <div
          className="adapt-height mt-24"
          style={{ height: "calc(100vh - 480px)" }}
        >
          <Table
            dataSource={data}
            columns={column}
            pagination={false}
            rowSelection={rowSelection}
          />
        </div>
      </div>
      <Drawer
        className="footprintDrawer"
        title="Add Organization Type"
        onClose={onClose}
        open={addOrganization}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <AddCatalog
          onClose={onClose}
          equipmentModalData={equipmentModalData}
          addManully={addManully}
          learnMore={false}
        />
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="Add Organization Type"
        onClose={onClose}
        open={addManually}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="px-40">
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Organization Type Name"
              name="fName"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input placeholder="e.g grocery shop " />
            </Form.Item>
            <Form.Item
              label="Organization Type Description"
              name="typeDesc"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input placeholder="e.g " />
            </Form.Item>
            <Form.Item
              label="Legal Organization"
              name="legal"
              extra="Select no if this Organization type is not a legal organization."
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                placeholder="Please Select"
                options={optionsAS}
                onChange={handleLegalChange}
              />
            </Form.Item>
            {legalValue === "yes" && (
              <Form.Item
                label="Financial Accounting Category"
                name="finance"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    <Radio value={1}>Group Company/Subsidiary</Radio>
                    <Radio value={2}>Parent Company</Radio>
                    <Radio value={3}>Associated/Affiliated Company</Radio>
                    <Radio value={4}>
                      Non-incorporated Joint Ventures/Partnerships/Operations
                    </Radio>
                    <Radio value={5}>Fixed Asset Investments</Radio>
                    <Radio value={6}>Franchise</Radio>
                    <Radio value={7}>None of these</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default OrganizationType;
