import React from "react";
import ConfirmationLayout from "../../../sharedModules/confirmationLayout";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import Lock from "../../../images/lock.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PasswordConfirm = () => {
  const navigate = useNavigate();
  const onFinish = () => {
    navigate("/login");
  };
  return (
    <ConfirmationLayout>
      <div className="process-complete">
        <div>
          <img src={Lock} className="img-fluid" alt="lock" />
        </div>
        <div>
          <h2 className="m-0 fw-600">Password reset successful</h2>
          <p className="text-black-60 mt-10 mb-0">
            Your password has been successfully reset. You can now login to your
            dashboard <br /> using the new password.
          </p>
        </div>
        <div>
          <Button
            onClick={onFinish}
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="large"
          >
            Login
          </Button>
        </div>
      </div>
    </ConfirmationLayout>
  );
};

export default PasswordConfirm;
