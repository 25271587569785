import React, { useState } from "react";
import {
  Space,
  Layout,
  Button,
  Input,
  Dropdown,
  Row,
  Col,
  Flex,
  Table,
  Tag,
  Drawer,
  Form,
  Select,
} from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  DownOutlined,
  SyncOutlined,
  DownloadOutlined,
  ClockCircleOutlined,
  CheckCircleFilled,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Search from "../../images/search.svg";

const { Header } = Layout;
const { Column } = Table;

const ReportTable = () => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const [open, setOpen] = useState(false);
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const options = [
    { value: "jack", label: "GHG Invertory Report" },
    { value: "lucy", label: "PCAF by GHG Protocol Report" },
    { value: "Yiminghe", label: "GRI Metrics Report" },
    { value: "Yiminghe1", label: "GDP Metrics Report" },
    { value: "Yiminghe2", label: "IFRS Metrics Report" },
  ];

  const columns = [
    {
      title: "Report Title",
      dataIndex: "jobID",
      key: "jobID",
      sorter: (a, b) => a.jobID - b.jobID,
    },

    {
      title: "Description",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Created",
      dataIndex: "uploadedOn",
      key: "uploadedOn",
      sorter: (a, b) => a.uploadedOn - b.uploadedOn,
      render: (_, record) => (
        <>
          <p className="m-0 fw-500 text-black whitespace-nowrap">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.recordBy}</p>
        </>
      ),
    },
    {
      title: "Reporting year",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category - b.category,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "In progress") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#AE590A",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#FDEAD8",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  <ClockCircleOutlined style={{ marginRight: 3 }} /> {tag}
                </Tag>
              );
            }
            if (tag === "Completed") {
              return (
                <Tag
                  key={tag}
                  color="warning"
                  style={{
                    color: "#166E3F",
                    fontSize: 12,
                    fontWeight: 500,
                    background: "#D1FAE4",
                    border: 0,
                    borderRadius: 6,
                  }}
                >
                  <CheckCircleFilled style={{ marginRight: 3 }} /> {tag}
                </Tag>
              );
            }
          })}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Button
            type="text"
            icon={<DownloadOutlined />}
            iconPosition="start"
            style={{ float: "right" }}
            disabled={record.status[0] === "Completed" ? false : true}
          ></Button>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      jobID: "GHG Inventory Report - Acme Corporation (2023)",
      status: ["In progress"],
      fileName:
        "Generate a file detailing your enterprise’s total greenhouse gas emissions.",
      category: "2023",
      uploadedOn: "04-20-2024 5:44 PM",
      recordBy: "By m.mazhar",
    },
    {
      key: "2",
      jobID: "GHG Inventory Report - Acme Corporation (2024)",
      status: ["Completed"],
      fileName:
        "Generate a file detailing your enterprise’s total greenhouse gas emissions.",
      category: "2024",
      uploadedOn: "04-20-2024 5:44 PM",
      recordBy: "By jayant rao",
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const handleValuesChange = (_, allValues) => {
    // Check if all fields are filled
    const allFilled = Object.values(allValues).every((value) => value);
    setIsButtonDisabled(!allFilled);
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Report</h4>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          iconPosition="start"
          className="btn-h-40"
          onClick={() => setOpen(!open)}
        >
          Request Report
        </Button>
      </Header>

      <div className="main-content">
        <Row justify="space-between" alignItems="center" gutter={[16]}>
          <Col md={8}>
            <Input
              placeholder="Search"
              prefix={<img src={Search} />}
              style={{ borderRadius: 25 }}
            />
          </Col>
          <Col md={8}>
            <Flex align="center" justify="end" gap={12}>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button
                  shape="round"
                  icon={<DownOutlined />}
                  iconPosition="end"
                  className="btn-h-40 fw-500 text-black"
                >
                  Report type
                </Button>
              </Dropdown>
              <Button
                shape="round"
                icon={<SyncOutlined />}
                iconPosition="start"
                className="btn-h-40 fw-500 text-black"
              >
                Refresh
              </Button>
              <Button
                shape="circle"
                icon={<SettingOutlined />}
                iconPosition="start"
                className="btn-h-40 fw-500 text-black px-2"
              ></Button>
            </Flex>
          </Col>
        </Row>
        <div className="mt-24">
          <div
            className="adapt-height mt-24"
            style={{ height: "calc(100vh - 210px)" }}
          >
            <Table
              dataSource={data}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"],
                itemRender: (current, type, originalElement) => {
                  if (type === "prev") {
                    return <ArrowLeftOutlined />;
                  }
                  if (type === "next") {
                    return <ArrowRightOutlined />;
                  }
                  return originalElement;
                },
              }}
              columns={columns}
            ></Table>
          </div>
        </div>
      </div>
      <Drawer
        className="footprintDrawer"
        title={
          <Flex justify="start" align="center" gap="middle">
            <h4 className="m-0 pl-3">Request report</h4>
          </Flex>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                type="default"
                shape="round"
                className="btn-h-40"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                className="btn-h-40"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                onClick={onClose}
                disabled={isButtonDisabled}
              >
                Generate Report
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="activity-row">
          <div className="activity-head">
            <h5 className="m-0 text-black">Reporting criteria</h5>
          </div>
          <div className="activity-body">
            <p className="m-0 mb-3">
              Reports are generated as .xls files and will be deleted and become
              unavailable after 30 days.
            </p>
            <Form
              layout="vertical"
              form={form}
              requiredMark={requiredMark}
              onValuesChange={handleValuesChange}
            >
              <Form.Item
                label="Report Type"
                name="reportType"
                rules={[
                  {
                    required: true,
                    message: "Required Report Type",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Reporting year"
                name="reportyear"
                rules={[
                  {
                    required: true,
                    message: "Required Report year",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Form.Item
                label="Report title"
                extra="If you don't give your report a title, it will default to the name of the Reporting Period selected."
                name="Reporttitle"
                rules={[
                  {
                    message: "Required title",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    message: "Required Description",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ReportTable;
