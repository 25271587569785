import React, { useState } from "react";
import {
  Card,
  Avatar,
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Button,
  Flex,
} from "antd";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import dummy from "../../../images/dummy.jfif";

const BussinessDetail = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { Option } = Select;
  const [form] = Form.useForm();
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];
  const selectBefore = (
    <Select defaultValue="add" style={{ width: 80 }}>
      <Option value="add">971</Option>
      <Option value="minus">+92</Option>
    </Select>
  );
  const onFinish = (values) => {
    // onNext();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = () => {
    // Checks if there are any errors
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    // Check if any field is empty
    const formValues = form.getFieldsValue(); // Get all field values as an object
    const hasEmptyFields = Object.values(formValues).some((value) => !value); // Check for empty values

    // Disable the button if there are errors or missing fields
    setIsButtonDisabled(hasErrors || hasEmptyFields);
  };
  return (
    <>
      <Card>
        <div className="card-header">
          <h3 className="m-0">Business details</h3>
          <p className="text-black-60 m-0">
            Your business information helps us with verification of your
            enterprise.
          </p>
        </div>
        <div className="card-body">
          <Form
            layout="vertical"
            form={form}
            className="mt-24"
            onFieldsChange={handleFormChange}
          >
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={dummy}
            />
            <p className="text-black-60 fw-500">
              Change Image <EditOutlined />
            </p>

            <Row gutter={[24, 0]}>
              <Col md={12}>
                <Form.Item
                  label="Company legal name"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Required  company legal name",
                    },
                  ]}
                >
                  <Input placeholder="eg. Ahya Technologies LTD." />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Required  industry",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Corporate website"
                  name="websiteURL"
                  rules={[
                    {
                      required: true,
                      message: "Required corporate website",
                    },
                  ]}
                >
                  <Input
                    addonBefore="Website"
                    placeholder="Enter website URL"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Contact Number" name="contactNumber">
                  <InputNumber
                    addonBefore={selectBefore}
                    placeholder="Enter number"
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Business address (line 1)"
                  name="address1"
                  rules={[
                    {
                      required: true,
                      message: "Required business address (line 1)",
                    },
                  ]}
                >
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Business address (line 2)"
                  name="address2"
                  rules={[
                    {
                      required: true,
                      message: "Required business address (line 2)",
                    },
                  ]}
                >
                  <Input placeholder="eg. 301, Building 01, Gate Village, DIFC" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Required  country",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Code / P.O. box"
                  name="poBox"
                  rules={[
                    {
                      required: true,
                      message: "Required P.O box",
                    },
                  ]}
                >
                  <Input placeholder="eg. 29670" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="State / province">
                  <Input placeholder="eg. Dubai" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Required city",
                    },
                  ]}
                >
                  <Input placeholder="eg. Dubai" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Flex justify="end">
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          className="btn-h-40 mt-24"
          onClick={() => form.submit()}
          disabled={isButtonDisabled}
        >
          Update & save
        </Button>
      </Flex>
    </>
  );
};

export default BussinessDetail;
