import React from "react";
import { Row, Col, Button, Card, Badge, Space, Alert, Layout } from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import { BulbOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import swap from "../../../images/swap.svg";
import manual from "../../../images/manual.png";

const { Header } = Layout;
const ConfigureChecklist = () => {
  const navigate = useNavigate();
  const handleButton = (link) => {
    navigate(link);
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="m-0">Configure checklist</h3>
      </Header>
      <div className="main-content">
        <Alert
          message={
            <Space className="mt-8 mb-8" size={8}>
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <BulbOutlined
                  style={{ fontSize: 20, color: "rgba(15, 19, 36, 0.6)" }}
                />
              </div>
              <div>
                <p className="m-0 fs-12">
                  To calculate your footprint, we have to configure your
                  Footprint Checklist. We'll walk you through a short
                  questionnaire to help you create the frameworks for building
                  your full footprint report. To configure your Footprint
                  Checklist you can choose between a guided questionnaire or
                  manual setup. Once saved, you'll be able to edit or review at
                  any time.
                </p>
              </div>
            </Space>
          }
          type="simple"
          closable
        ></Alert>

        <Row className="mt-3" gutter={[24, 24]}>
          <Col md={12}>
            <Card className="configure-card">
              <Row justify="space-between" style={{ marginBottom: 24 }}>
                <Col>
                  <img src={swap} />
                </Col>
                <Col>
                  <Badge
                    count="Recommended"
                    style={{
                      backgroundColor: "rgba(254, 169, 35, .1)",
                      color: "#FEA923",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                </Col>
              </Row>
              <Space direction="vertical" size={[16, 16]}>
                <h3 className="m-0">Guided Configuration</h3>
                <p className="text-black-60 m-0 fs-16">
                  We'll help you build your Checklist by asking you guiding
                  questions that'll help you understand what sources to track
                  based on your Organization's needs.
                </p>
                <Button
                  type="primary"
                  shape="round"
                  icon={<ArrowRightOutlined />}
                  iconPosition="end"
                  size="large"
                  onClick={() =>
                    handleButton(
                      "/measure/footprint/configure-checklist/guided-configration"
                    )
                  }
                >
                  Use Guided Configuration
                </Button>
              </Space>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="configure-card">
              <Row justify="space-between" style={{ marginBottom: 24 }}>
                <Col>
                  <img src={manual} />
                </Col>
              </Row>
              <Space direction="vertical" size={[16, 16]}>
                <h3 className="m-0">Manual Configuration</h3>
                <p className="text-black-60 m-0 fs-16">
                  We'll show you a full list of all available Footprint Sources
                  and you can manually select any sources you'd like to track.
                </p>
                <Button
                  type="primary"
                  shape="round"
                  icon={<ArrowRightOutlined />}
                  iconPosition="end"
                  size="large"
                  onClick={() =>
                    handleButton(
                      "/measure/footprint/configure-checklist/manual-configration"
                    )
                  }
                >
                  Use Manual Configuration
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConfigureChecklist;
