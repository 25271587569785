import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../config";

const initState = {
  data: "",
  error: "",
  loading: false,
};

export const fetchSolutations = createAsyncThunk(
  "data/fetchSolutations",
  async (url, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(url);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      const message = err.response.data?.message ?? "An error occurred";
      return rejectWithValue(message);
    }
  }
);

const solutationSlice = createSlice({
  name: "solutationSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSolutations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSolutations.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchSolutations.rejected, (state, action) => {
        state.error = action.payload ?? "Unknown error occurred";
        state.loading = false;
      });
  },
});

export default solutationSlice.reducer;
