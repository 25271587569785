import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_KEY, token } from "../../config";

const initState = {
  data: null,
  loading: false,
  error: "",
};

export const saveTaxInfo = createAsyncThunk(
  "verification/tax-info",
  async (data, { rejectWithValue }) => {
    console.log("files", data?.raw);
    console.log("files", data?.raw?.files);
    let formData = new FormData();
    formData.append("certificate_or_license", data?.raw?.files?.companyIdImage);
    formData.append("tax_document", data?.raw?.files?.taxDocument);
    formData.append("others", data?.raw?.files?.otherImage);
    formData.append("tax_number", data?.raw?.taxNumber);

    const formDataEntries = [...formData.entries()];
    console.log("FormData contents:", formDataEntries);

    const config = {
      method: "post",
      url: data?.url,
      headers: {
        "x-api-key": API_KEY,
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      console.log("Response:", response);
      return response.data;
    } catch (err) {
      console.error("Error details:", err);

      let result;
      if (err.response && err.response.data && err.response.data.detail) {
        if (typeof err.response.data.detail === "string") {
          try {
            const parsedDetail = JSON.parse(err.response.data.detail);
            result = parsedDetail?.detail?.[0]?.msg || parsedDetail;
          } catch (parseError) {
            result = err.response.data.detail;
          }
        } else {
          result =
            err.response.data.detail?.detail?.[0]?.msg ||
            err.response.data.detail;
        }
      } else {
        result = "An error occurred";
      }

      console.error("Parsed error message:", result);
      return rejectWithValue(result);
    }
  }
);

const taxInfoSlice = createSlice({
  name: "taxInfoSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveTaxInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveTaxInfo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(saveTaxInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default taxInfoSlice.reducer;
