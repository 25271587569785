import React, { useState } from "react";
import CatalogLayout from "../CatalogLayout";
import {
  Flex,
  Space,
  Button,
  Dropdown,
  Switch,
  Input,
  Table,
  Drawer,
  Form,
  Row,
  Col,
  Select,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  EnvironmentFilled,
} from "@ant-design/icons";
import AddCatalog from "../addCatalog";
import Search from "../../../../images/search.svg";

const { TextArea } = Input;
const Location = () => {
  const equipmentData = {
    type: "Location",
    content: [
      "Locations are the physical addresses of any place of business you'd like to track. If a space has a mailing address and is part of an Organization in your GHG Inventory, then it's likely worth adding to Persefoni.",
      "As you divide up your company into physical locations, consider how these locations represent the different types of emissions in the GHG accounting standard.",
      "Your direct (Scope 1) and indirect (Scope 2 and 3) should align with the physical locations of your sites, assets, people, and supply chain.",
    ],
  };
  const [equipmentModalData, setEquipmentModalData] = useState(equipmentData);
  const [addManually, setAddManually] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onClose = () => {
    setOpen(false);
    setAddManually(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const columns = [
    {
      title: "Location Name",
      dataIndex: "equipment",
      key: "equipment",
      width: 660,
      sorter: (a, b) => a.equipment - b.equipment,
    },
    {
      title: "Street Address",
      dataIndex: "category",
      key: "category",
      width: 300,
    },
    {
      title: "City",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
      sorter: (a, b) => a.itemSource - b.itemSource,
    },
    {
      title: "Item Source",
      dataIndex: "itemSource",
      key: "itemSource",
      width: 150,
      sorter: (a, b) => a.itemSource - b.itemSource,
    },
  ];
  const data = [
    {
      key: "1",
      equipment: "Acme KSA",
      category: "Riyadh 13322, Saudi Arabia",
      itemSource: "Riyadh Province",
    },
    {
      key: "2",
      equipment: "Acme UAE",
      category: "Sharjah 13511, UAE",
      itemSource: "Sharjah Province",
    },
    {
      key: "3",
      equipment: "Acme USA",
      category: "Miami 1234 USA",
      itemSource: "Miami",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const addManully = () => {
    setAddManually(true);
  };
  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];
  return (
    <CatalogLayout>
      <Flex justify="space-between" align="center">
        <Space>
          <Button type="text" className="p-0">
            <ArrowLeftOutlined />
          </Button>
          <h4 className="m-0">Locations</h4>
        </Space>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Export
          </Button>
        </Dropdown>
      </Flex>
      <Space className="mt-24">
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            Country
          </Button>
        </Dropdown>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            State
          </Button>
        </Dropdown>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            shape="round"
            icon={<DownOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500 text-black"
          >
            City
          </Button>
        </Dropdown>
      </Space>
      <Flex
        justify="space-between"
        align="center"
        className="mt-24"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500 }}
        />
        <Space size={[20, 20]}>
          <Space>
            <Switch />
            <p className="m-0">Show archived</p>
          </Space>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            type="primary"
            className="btn-h-40 fw-500"
            onClick={() => setOpen(true)}
          >
            Add Location
          </Button>
        </Space>
      </Flex>

      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table
          dataSource={data}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <ArrowLeftOutlined />;
              }
              if (type === "next") {
                return <ArrowRightOutlined />;
              }
              return originalElement;
            },
          }}
          rowSelection={rowSelection}
          columns={columns}
        ></Table>
      </div>
      <Drawer
        className="footprintDrawer"
        title="Add Location"
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <AddCatalog
          onClose={onClose}
          equipmentModalData={equipmentModalData}
          addManully={addManully}
        />
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="Add Location"
        onClose={onClose}
        open={addManually}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" className="btn-h-40" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
              onClick={onClose}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <div className="activity-row">
          <div className="activity-head">
            <h5 className="m-0 text-black">Location details</h5>
          </div>
          <div className="activity-body">
            <Form layout="vertical">
              <Form.Item
                label="Location name"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Required location name",
                  },
                ]}
              >
                <Input placeholder="Riyadh" />
              </Form.Item>
              <Form.Item
                label="Search for address to autofill, or enter it below"
                name="address"
                extra="Selected address will pre-fill the fields below"
              >
                <Input
                  placeholder="Please Select"
                  prefix={<EnvironmentFilled />}
                />
              </Form.Item>
              <Form.Item
                label="Address"
                name="aaddress"
                rules={[
                  {
                    required: true,
                    message: "Required address",
                  },
                ]}
              >
                <TextArea maxLength={100} placeholder="Write here" />
              </Form.Item>
              <Form.Item label="Apt/Suite" name="industry">
                <Input placeholder="Please Select" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Required country",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={options} />
              </Form.Item>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Required city",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={options} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Required state",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={options} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="ZIP"
                name="ZIP"
                rules={[
                  {
                    required: true,
                    message: "Required ZIP",
                  },
                ]}
              >
                <Input placeholder="Please Select" />
              </Form.Item>
              <Form.Item
                label="Description (Required by GHGP)"
                name="Description"
                rules={[
                  {
                    required: true,
                    message: "Required description",
                  },
                ]}
              >
                <TextArea maxLength={100} placeholder="Write here" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </CatalogLayout>
  );
};

export default Location;
