import React, { useState } from "react";
import { Tabs, Layout, Button, Card } from "antd";
import AccountSettings from "./AccountSettings";
import ExportAudit from "./ExportAudit";
import OrganizationSettings from "./OrganizationSettings";
import ReportSetting from "./ReportSetting";
const { Header } = Layout;

const Configration = () => {
  const [title, setTitle] = useState("Account settings");
  const items = [
    {
      key: "Account Settings",
      label: "Account settings",
      children: <AccountSettings />,
    },
    {
      key: "Organization Settings",
      label: "Organization settings",
      children: <OrganizationSettings />,
    },
    {
      key: "Reporting Settings",
      label: "Reporting settings",
      children: <ReportSetting />,
    },
    {
      key: "Export audit history",
      label: "Export audit history",
      children: <ExportAudit />,
    },
  ];

  const onChange = (key) => {
    setTitle(key);
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="m-0">{title}</h3>
      </Header>
      <div className="main-content">
        <div className="steps-wizard">
          <Tabs
            defaultActiveKey="Account settings"
            items={items}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Configration;
