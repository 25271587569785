import React, { useState } from "react";
import {
  Layout,
  Button,
  Card,
  Flex,
  Row,
  Col,
  message,
  Divider,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import LineChart from "../../../sharedModules/charts/LineChart";

const { Header } = Layout;

const Decarbonization = () => {
  const [dropdownValue, setDropdownValue] = useState("Business as usual");

  const dataBar = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [null, null, null, null, null, null, 85000, 85000, 85000],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [null, null, null, null, null, null, 255000, 275000, 305000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [null, null, null, null, null, null, 75000, 75000, 75000],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          null,
          null,
          null,
          null,
          null,
          400000,
          440000,
          480000,
          520000,
          560000,
          600000,
          640000,
          680000,
          720000,
          760000,
          800000,
          840000,
          880000,
          920000,
          960000,
          1000000,
          1040000,
          1080000,
          1120000,
          1160000,
          1200000,
          1240000,
          1280000,
          1320000,
          1360000,
          1400000,
          1440000,
          1480000,
          1520000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0.5,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarWP = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarBau = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Business as usual (BaU) Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          7000,
          620000,
          680000,
          740000,
          800000,
          850000,
          910000,
          1000000,
          1040000,
          1100000,
          1150000,
          1220000,
          1280000,
          1300000,
          1380000,
          1420000,
          1440000,
          1500000,
          1510000,
          1520000,
          1530000,
          1540000,
          1550000,
          1560000,
          1570000,
          1580000,
          1590000,
          1610000,
        ],
        borderColor: "#4778F5",
        backgroundColor: "#4778F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarET = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Enterprise Targets",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          10000,
          10100,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#26BD6C",
        backgroundColor: "#26BD6C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Business as usual (BaU) Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          7000,
          100000,
          300000,
          540000,
          700000,
          850000,
          910000,
          1000000,
          1040000,
          1140000,
          1150000,
          1220000,
          1280000,
          1300000,
          1380000,
          1420000,
          1440000,
          1500000,
          1510000,
          1520000,
          1530000,
          1540000,
          1550000,
          1560000,
          1570000,
          1580000,
          1590000,
          1610000,
        ],
        borderColor: "#4778F5",
        backgroundColor: "#4778F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarFI = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Forecast with Initiatives",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#D80041",
        backgroundColor: "#D80041",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Enterprise Targets",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          10000,
          10100,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#26BD6C",
        backgroundColor: "#26BD6C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Business as usual (BaU) Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          7000,
          100000,
          300000,
          540000,
          700000,
          850000,
          910000,
          1000000,
          1040000,
          1140000,
          1150000,
          1220000,
          1280000,
          1300000,
          1380000,
          1420000,
          1440000,
          1500000,
          1510000,
          1520000,
          1530000,
          1540000,
          1550000,
          1560000,
          1570000,
          1580000,
          1590000,
          1610000,
        ],
        borderColor: "#4778F5",
        backgroundColor: "#4778F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarST1 = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "SBTi Target - Scope 1 & 2",
        data: [
          1000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 305000,
          175000, 180000, 100000, 150000, 140000, 160000, 180000, 200000,
          220000, 200000, 180000, 160000, 150000, 170000, 180000, 200000,
          190000, 175000, 180000, 165000, 185000, 175000, 165000, 185000,
          175000, 180000,
        ],
        borderColor: "#F5D947",
        backgroundColor: "#F5D947",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Forecast with Initiatives",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#D80041",
        backgroundColor: "#D80041",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Enterprise Targets",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          10000,
          10100,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#26BD6C",
        backgroundColor: "#26BD6C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Business as usual (BaU) Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          7000,
          100000,
          300000,
          540000,
          700000,
          850000,
          910000,
          1000000,
          1040000,
          1140000,
          1150000,
          1220000,
          1280000,
          1300000,
          1380000,
          1420000,
          1440000,
          1500000,
          1510000,
          1520000,
          1530000,
          1540000,
          1550000,
          1560000,
          1570000,
          1580000,
          1590000,
          1610000,
        ],
        borderColor: "#4778F5",
        backgroundColor: "#4778F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBarSTAll = {
    labels: [
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    datasets: [
      {
        label: "Sum of Scope 1 (Historical)",
        data: [
          85000, 95000, 102000, 105000, 110000, 120000, 110000, 105000, 107000,
        ],
        backgroundColor: "#717684",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 2 (Historical)",
        data: [55000, 65000, 65000, 75000, 65000, 35000, 40000, 50000, 40000],
        backgroundColor: "#F48E2F",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },
      {
        label: "Sum of Scope 3 (Historical)",
        data: [
          310000, 310000, 310000, 310000, 310000, 310000, 340000, 330000,
          430000,
        ],
        backgroundColor: "#B4C7F8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 24,
        stack: "combined",
        type: "bar",
      },

      {
        type: "line",
        label: "Total Emissions (Historic)",
        data: [
          452000, 474000, 480000, 493000, 489000, 478000, 492000, 490000,
          580000,
        ],
        borderColor: "#EB632C",
        backgroundColor: "#EB632C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "SBTi Target - All Scopes",
        data: [
          1000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 305000,
          175000, 180000, 100000, 150000, 140000, 160000, 180000, 200000,
          220000, 200000, 180000, 160000, 150000, 170000, 180000, 200000,
          190000, 175000, 180000, 165000, 185000, 155000, 125000, 105000, 85000,
          30000,
        ],
        borderColor: "#BD47F5",
        backgroundColor: "#BD47F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Forecast with Initiatives",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          503000,
          405000,
          306000,
          305000,
          304800,
          304600,
          311200,
          305000,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#D80041",
        backgroundColor: "#D80041",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Enterprise Targets",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          10,
          10,
          50,
          40,
          30,
          350,
          300,
          30400,
          31120,
          30500,
          205200,
          305200,
          305200,
          305200,
          305200,
          352200,
          355200,
          358200,
          351200,
          345200,
          335200,
          345200,
          358200,
          375200,
          384200,
          346500,
          345200,
          348200,
          360500,
        ],
        borderColor: "#26BD6C",
        backgroundColor: "#26BD6C",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
      {
        type: "line",
        label: "Business as usual (BaU) Forecast",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          7000,
          1000,
          3000,
          5000,
          7000,
          8500,
          9100,
          100000,
          104000,
          114000,
          115000,
          122000,
          128000,
          130000,
          138000,
          142000,
          144000,
          150000,
          151000,
          152000,
          153000,
          154000,
          155000,
          156000,
          157000,
          158000,
          159000,
          161000,
        ],
        borderColor: "#4778F5",
        backgroundColor: "#4778F5",
        pointStyle: "circle",
        lineTension: 0,
        pointRadius: 0,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: false,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 6,
          topRight: 6,
          bottomLeft: 6,
          bottomRight: 6,
        },
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "#000",
          font: {
            size: 10,
            weight: "500",
            family: "Montserrat",
            color: "#000",
          },
        },
      },
      y: {
        stacked: true,
        display: true,
        position: "left",
        title: {
          display: true,
          text: "GHG Emissions (tCO2e)",
          color: "#14151a",
          font: {
            size: 14,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        display: false,
        font: {
          weight: "600",
          family: "'Montserrat', sans-serif",
          size: 10,
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          padding: 16,
          color: "#14151a",
          usePointStyle: true,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, i) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: "#000",
              pointStyle: dataset.type === "line" ? "circle" : "rect", // Different styles for bar and line
              hidden: !chart.isDatasetVisible(i),
              datasetIndex: i,
            }));
          },
          // pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#14151A",
        bodyColor: "#14151A",
        borderColor: "white",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue + " tCO₂e";
          },
          title: function (data) {
            return data[0].dataset.label;
          },
        },
      },
      // tooltip: false,
    },
  };

  const items = [
    {
      label: "Business as usual",
      key: "Business as usual",
    },
    {
      label: "Without projections",
      key: "Without projections",
    },
    {
      label: "BaU (without minimum requirements)",
      key: "BaU (without minimum requirements)",
    },
    {
      label: "Enterprise targets",
      key: "Enterprise targets",
    },
    {
      label: "Forecast with initiatives",
      key: "Forecast with initiatives",
    },
    {
      label: "SBTi Targets - Scope 1 & 2",
      key: "SBTi Targets - Scope 1 & 2",
    },
    {
      label: "SBTi Targets - (All Scopes)",
      key: "SBTi Targets - (All Scopes)",
    },
  ];

  const onClick = ({ key }) => {
    setDropdownValue(key);
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Decarbonization pathway</h4>
      </Header>
      <div className="main-content">
        <Card
          title="Decarbonization target"
          extra={
            <Button type="primary" shape="round" className="btn-h-40">
              Configure target
            </Button>
          }
        >
          <Flex gap="middle">
            <Card className="text-center body-pad-16 w-100">
              <h3 className="mb-2 mt-0 fw-700">SBT - Long term</h3>
              <p className="fw-500 fs-12 text-black m-0">
                Time-Frame (Before the year 2050)
              </p>
            </Card>
            <Card className="text-center body-pad-16 w-100">
              <h3 className="mb-2 mt-0 fw-700">1.5°C</h3>
              <p className="fw-500 fs-12 text-black m-0">Target degree</p>
            </Card>
            <Card className="text-center body-pad-16 w-100">
              <h3 className="mb-2 mt-0 fw-700">51.41 %</h3>
              <p className="fw-500 fs-12 text-black m-0">
                Total reduction amibition
              </p>
            </Card>
            <Card className="text-center body-pad-16 w-100">
              <h3 className="mb-2 mt-0 fw-700">2015</h3>
              <p className="fw-500 fs-12 text-black m-0">Base year</p>
            </Card>
            <Card className="text-center body-pad-16 w-100">
              <h3 className="mb-2 mt-0 fw-700">2030</h3>
              <p className="fw-500 fs-12 text-black m-0">Target year</p>
            </Card>
          </Flex>
          <Divider />
          <Card style={{ background: "#E9EAEC" }}>
            <Flex gap="middle" align="center">
              <div className="add-seperator">
                <h3 className="mb-2 mt-0 fw-700">22%</h3>
                <p className="fw-500 fs-12 text-black m-0 whitespace-nowrap">
                  Progress towards total reduction ambition
                </p>
              </div>
              <div>
                <p className="fw-500 fs-16 text-black m-0">
                  This year <span className="fw-700">(2024)</span>, your current
                  total carbon emissions show a decrease of{" "}
                  <span className="fw-700">-135.88 tCO₂e</span> from your base
                  year emissions, resulting in a{" "}
                  <span className="fw-700">0%</span> reduction towards your{" "}
                  <span className="fw-700">51.41 %</span> Total Reduction
                  Ambition.
                </p>
              </div>
            </Flex>
          </Card>
        </Card>
        <Card
          className="mt-24"
          title="Reduction Map"
          extra={
            <Dropdown menu={{ items, onClick }} trigger={["click"]}>
              <Button
                type="gray"
                shape="round"
                icon={<DownOutlined />}
                iconPosition="end"
                className="btn-h-40 fw-500"
              >
                {dropdownValue}
              </Button>
            </Dropdown>
          }
        >
          <div className="p-12 mt-2" style={{ height: 600 }}>
            {dropdownValue === "Business as usual" ? (
              <LineChart data={dataBar} options={optionsBar} />
            ) : dropdownValue === "Without projections" ? (
              <LineChart data={dataBarWP} options={optionsBar} />
            ) : dropdownValue === "BaU (without minimum requirements)" ? (
              <LineChart data={dataBarBau} options={optionsBar} />
            ) : dropdownValue === "Enterprise targets" ? (
              <LineChart data={dataBarET} options={optionsBar} />
            ) : dropdownValue === "Forecast with initiatives" ? (
              <LineChart data={dataBarFI} options={optionsBar} />
            ) : dropdownValue === "SBTi Targets - Scope 1 & 2" ? (
              <LineChart data={dataBarST1} options={optionsBar} />
            ) : dropdownValue === "SBTi Targets - (All Scopes)" ? (
              <LineChart data={dataBarSTAll} options={optionsBar} />
            ) : (
              "ji"
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Decarbonization;
