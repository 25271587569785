import { Form, Select, Spin } from "antd";
import React from "react";
import useFetchCountriesData from "../../hooks/useApiHook";
import { API_SETTINGS_BASE_URL } from "../../config";

const CountriesSelect = ({ setCountryId, isPersonalInfo }) => {
  const { countries, countryErr, coutryLoader } = useFetchCountriesData(
    `${API_SETTINGS_BASE_URL}/v1/general_settings/countries/?skip=0&limit=400`
  );
  return (
    <>
      <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: "Required  country",
          },
        ]}
      >
        <Select
          placeholder="Please Select"
          style={{ position: "rel" }}
          dropdownRender={(menu) => (
            <div>
              {countryErr && (
                <p style={{ padding: "1rem" }}>
                  Something went wrong, please refresh the page
                </p>
              )}
              {menu}
              {coutryLoader && (
                <div style={{ textAlign: "center", padding: 8 }}>
                  <Spin />
                </div>
              )}
            </div>
          )}
          onSelect={(selected) => {
            if (isPersonalInfo) {
              let result = countries?.find(
                (country) => country?.name === selected
              );
              setCountryId(result?.id);
            } else {
              setCountryId(selected);
            }
          }}
        >
          {countries?.length > 0
            ? countries?.map((country) => (
                <Select.Option
                  key={country.id}
                  value={isPersonalInfo ? country?.name : country?.id}
                >
                  {country?.name}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </>
  );
};

export default CountriesSelect;
