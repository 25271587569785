import { Form, Select, Spin } from 'antd';
import React from 'react'
import { useFetchCitiesData } from '../../hooks/useApiHook';
import { API_SETTINGS_BASE_URL } from '../../config';

const CitiesSelect = ({ stateId, countryId, isPersonalInfo }) => {

    const { cities, citiesLoader, citiesErr } = useFetchCitiesData(
        `${API_SETTINGS_BASE_URL}/v1/general_settings/cities/?skip=0&limit=450&state_id=${stateId ? stateId : countryId}`
    );
    return (
        <>
            <Form.Item
                label="City"
                name="city"
                rules={[
                    {
                        required: true,
                        message: "Required city",
                    },
                ]}
            >
                <Select
                    placeholder="Please Select"
                    style={{ position: "rel" }}
                    disabled={stateId | countryId ? false : true}
                    dropdownRender={(menu) => (
                        <div>
                            {citiesErr && <p style={{ padding: "1rem" }}>
                                Something went wrong, please refresh the page
                            </p>}
                            {menu}
                            {citiesLoader && (
                                <div style={{ textAlign: "center", padding: 8 }}>
                                    <Spin />
                                </div>
                            )}
                        </div>
                    )}
                >
                    {cities?.length > 0
                        ? cities?.map((city) => (
                            <Select.Option key={city.id} value={isPersonalInfo ? city?.name : city?.id}>
                                {city?.name}
                            </Select.Option>
                        ))
                        : null}
                </Select>
            </Form.Item>
        </>
    )
}

export default CitiesSelect
