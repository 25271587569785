import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_KEY, token } from "../../config";

const initState = {
  data: null,
  loading: false,
  error: "",
};

export const savePersonalDetails = createAsyncThunk(
  "verification/personal-details",
  async (data, { rejectWithValue }) => {
    console.log("data?.raw ", data?.raw);
    let formData = new FormData();
    formData.append("salutation", data?.raw?.solutation);
    formData.append("first_name", data?.raw?.firstName);
    formData.append("last_name", data?.raw?.lastName);
    formData.append("corporate_email", data?.raw?.username);
    formData.append("phone_number", data?.raw?.phone?.toString());
    formData.append("address_line_1", data?.raw?.address1);
    formData.append("address_line_2", data?.raw?.address2);
    formData.append("company_id_number", data?.raw?.IDnumber);
    formData.append("country", data?.raw?.country);
    formData.append("state", data?.raw?.state);
    formData.append("job_title", "MERN");
    formData.append("city", data?.raw?.city);
    formData.append("postal_code", data?.raw?.poBox);

    if (data?.raw?.companyIdImage) {
      formData.append("files", data.raw.companyIdImage);
    }

    const formDataEntries = [...formData.entries()];
    console.log("FormData contents:", formDataEntries);

    const config = {
      method: "post",
      url: data?.url,
      headers: {
        "x-api-key": API_KEY,
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      console.log("Response:", response);
      return response.data;
    } catch (err) {
      console.error("Error details:", err);

      let result;
      if (err.response && err.response.data && err.response.data.detail) {
        if (typeof err.response.data.detail === "string") {
          try {
            const parsedDetail = JSON.parse(err.response.data.detail);
            result = parsedDetail?.detail?.[0]?.msg || parsedDetail;
          } catch (parseError) {
            result = err.response.data.detail;
          }
        } else {
          result =
            err.response.data.detail?.detail?.[0]?.msg ||
            err.response.data.detail;
        }
      } else {
        result = "An error occurred";
      }

      console.error("Parsed error message:", result);
      return rejectWithValue(result);
    }
  }
);

const personalDetailsSlice = createSlice({
  name: "personalDetailsSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(savePersonalDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePersonalDetails.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(savePersonalDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default personalDetailsSlice.reducer;
