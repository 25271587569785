import React, { useState } from "react";
import { Row, Col, Layout } from "antd";
import logo1 from "../../images/reportIcons/icon1.svg";
import logo2 from "../../images/reportIcons/icon2.svg";
import logo3 from "../../images/reportIcons/icon3.svg";
import logo4 from "../../images/reportIcons/icon4.svg";
import logo5 from "../../images/reportIcons/icon5.svg";
import icon6 from "../../images/reportIcons/icon6.svg";
import ReportCards from "./ReportCards";

const { Header } = Layout;

const Report = () => {
  const IntegrationCardData = [
    {
      isLock: false,
      logo: logo1,
      heading: "Greenhouse Gas Protocol Inventory Report",
      paragraph:
        "Generate a file detailing your enterprise’s total greenhouse gas emissions.",
    },
    {
      isLock: false,
      logo: logo3,
      heading: "PCAF by GHG Protocol",
      paragraph:
        "If Scope 3 financed emissions are measured, then PCAF Guidelines will be applicable.",
    },
    {
      isLock: true,
      logo: logo2,
      heading: "Global Reporting Initiative Metrics Report",
      paragraph:
        "GHG metrics of an enterprise’s climate impact and its management as per the GRI 305: Emissions Standard.",
    },
    {
      isLock: true,
      logo: logo4,
      heading: "CDP Metrics Report",
      paragraph:
        "GHG metrics for enhancing transparency of corporate emissions through CDP.",
    },
    {
      isLock: false,
      logo: logo5,
      heading: "IFRS-S2 Climate-related Metrics Report",
      paragraph:
        "GHG metrics for Climate-related Disclosures Standard (IFRS S2) incorporates the TCFD recommendations, SASB Standards, CDSB Framework, Integrated Reporting Framework and World Economic Forum metrics.",
    },
    {
      isLock: true,
      logo: icon6,
      heading: "ESRS-E1 Climate Change Metrics Report",
      paragraph:
        "Enterprises report their climate change impacts per the European Sustainability Reporting Standards (ESRS) under the EU's Corporate Sustainability Reporting Directive.",
    },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Voluntary and regulatory reporting support </h4>
        <p
          className="fs-12 fw-500 m-0 text-right"
          style={{ maxWidth: 500, lineHeight: "16px" }}
        >
          Interested in a quantitative report? Generate one yourself, or request
          one from your Customer Support team today
        </p>
      </Header>

      <div className="main-content">
        <Row gutter={[20, 24]} className="mt-8">
          {IntegrationCardData.map((card, index) => (
            <Col md={6} key={index}>
              <ReportCards
                isLock={card.isLock}
                logo={card.logo}
                heading={card.heading}
                paragraph={card.paragraph}
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Report;
