/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AuthLayout from "../../../sharedModules/authtLayout";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import { hanldeAuthLoginRequest } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const { data, loading, signinError } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState(false);

  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
    const url = `${API_BASE_URL}/v1/auth_sso_otp/login-sso/`;
    const raw = {
      username: values?.username,
      password: values?.password,
    };
    dispatch(hanldeAuthLoginRequest({ url, raw })).then((action) => {
      console.log("userdata login ", action);
      if (hanldeAuthLoginRequest.fulfilled.match(action)) {
        if (action?.payload?.access_token) {
          navigate("/verify-code", {
            state: {
              isLogin: true,
              user: {
                ...action?.payload?.user,
                corporate_email: action?.payload?.user?.companyEmail,
              },
              corporate_email: action?.payload?.user?.companyEmail,
              token: action?.payload?.access_token,
            },
          });
        }
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (signinError) {
      setMsg(signinError);
      setTimeout(() => {
        setMsg(null);
      }, 5000);
    }
  }, [data, signinError]);

  return (
    <AuthLayout>
      <Card bordered={false}>
        <h3 className="text-center m-0 fw-700">Login</h3>
        <p className="mt-8 text-black-60 mb-0 text-center">
          Welcome back. Log in below.
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mt-24"
        >
          <Row gutter={[16, 0]}>
            <Col md={24}>
              <Form.Item
                label="Corporate email"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Required  corporate email",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="eg. name@company.com" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Required  password!" }]}
              >
                <Input.Password placeholder="eg. ●●●●●●●●●●" />
              </Form.Item>
            </Col>
            <Col md={24}>
              {msg && (
                <p style={{ color: "#FF4D4F", marginTop: "-15px" }}>{msg}</p>
              )}
            </Col>
            <Col md={24} className="mt-10 mb-20">
              <p className="text-center m-0">
                Forgot password?{" "}
                <Link className="text-primary fw-500" to="/reset-password">
                  Reset
                </Link>
              </p>
            </Col>
            <Col md={24} className="mt-10">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { username, password } = getFieldsValue();
                  const formIsComplete = !!username && !!password;
                  return (
                    <Button
                      // onClick={onFinish}
                      type="primary"
                      shape="round"
                      icon={<ArrowRightOutlined />}
                      disabled={!formIsComplete || loading}
                      iconPosition="end"
                      block
                      size="large"
                      htmlType="submit"
                    >
                      {loading && <Spin tip="Loading" size="small" />}
                      Login
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={24}>
              <p className="text-center">
                Already have an account?{" "}
                <Link className="text-primary fw-500" to="/signup">
                  Sign up
                </Link>
              </p>
            </Col>
          </Row>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default Login;
