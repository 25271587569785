import React, { useState } from "react";
import { Button, Flex, Badge, Space, Radio, Layout } from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Scope1Tab from "./Scope1Tab";
import Scope2Tab from "./Scope2Tab";
import Scope3Tab from "./Scope3Tab";
import Unscoped from "./Unscoped";

const { Header, Footer } = Layout;

const ManualConfigration = () => {
  const radioValue = [
    {
      id: "scope1",
      title: "Scope1",
      items: "84",
    },
    {
      id: "scope2",
      title: "Scope2",
      items: "84",
    },
    {
      id: "scope3",
      title: "Scope3",
      items: "84",
    },
    {
      id: "unscoped",
      title: "Unscoped",
      items: "2",
    },
  ];
  const [scopeValue, setScopeValue] = useState(radioValue[0].id);
  const [ststusConf, setStstusConf] = useState(false);
  const navigate = useNavigate();
  const handleButton = (link) => {
    navigate(link);
  };
  const onChangeScope = (e) => {
    setScopeValue(e.target.value);
  };

  const handleSelectNextRadio = () => {
    const currentIndex = radioValue.findIndex(
      (value) => value.id === scopeValue
    );
    const nextIndex = (currentIndex + 1) % radioValue.length;
    if (nextIndex === 0) {
      setStstusConf(true);
    } else {
      setScopeValue(radioValue[nextIndex].id);
    }
  };
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space size="middle">
          <Button
            type="gray"
            shape="circle"
            icon={
              <ArrowLeftOutlined style={{ color: "rgba(15, 19, 36, 0.6)" }} />
            }
            iconPosition="start"
            size="large"
            style={{ width: 48 }}
            onClick={() =>
              handleButton("/measure/footprint/configure-checklist")
            }
          ></Button>
          <h3 className="m-0">Manual Configuration</h3>
        </Space>
        {!ststusConf && (
          <Button
            type="primary"
            shape="round"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            size="default"
            className="btn-h-40"
            onClick={handleSelectNextRadio}
          >
            Save
          </Button>
        )}
      </Header>
      {!ststusConf ? (
        <>
          <div className="main-content">
            <div className="steps-wizard">
              <div className="ant-form radio-round">
                <Radio.Group
                  buttonStyle="solid"
                  size="large"
                  value={scopeValue}
                  onChange={onChangeScope}
                >
                  {radioValue.map((val, index) => {
                    return (
                      <Radio.Button key={index} value={val.id}>
                        {val.title}
                        <Badge
                          count={val.items}
                          style={{
                            backgroundColor: "#E9EAEC",
                            color: "#14151A",
                            fontWeight: "500",
                            fontSize: 12,
                            borderRadius: "6px",
                            boxShadow: "none",
                            marginLeft: 4,
                          }}
                        />
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>

            {scopeValue === "scope1" && <Scope1Tab />}
            {scopeValue === "scope2" && <Scope2Tab />}
            {scopeValue === "scope3" && <Scope3Tab />}
            {scopeValue === "unscoped" && <Unscoped />}
          </div>
          <Footer
            style={{
              background: "#fff",
              padding: "17px 40px",
              position: "sticky",
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Flex gap="middle" justify="space-between" align="center">
              <Button
                shape="round"
                size="default"
                className="btn-h-40 text-black fw-500"
              >
                Cancel
              </Button>
              <p className="m-0 text-black-60 fw-600">2 out of 44 selected</p>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                size="default"
                className="btn-h-40"
                onClick={handleSelectNextRadio}
              >
                Save
              </Button>
            </Flex>
          </Footer>
        </>
      ) : (
        <div
          className="main-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flex justify="center" align="center" gap={16} vertical>
            <h3 className="m-0">Manual configuration completed</h3>
            <p className="m-0 text-black-60">
              You can now proceed back to the page of your choice.
            </p>
            <Button
              type="primary"
              shape="round"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              size="large"
              className="btn-h-40"
              onClick={() => handleButton("/measure/footprint")}
            >
              Back to footprint sources
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

export default ManualConfigration;
