import React from "react";
import { Badge, Space, Card, Row, Col, Dropdown } from "antd";
import { EllipsisOutlined, DeleteOutlined } from "@ant-design/icons";

const NotifyCard = ({ unread }) => {
  const items = [
    {
      label: "Delete",
      key: "0",
      icon: <DeleteOutlined />,
    },
  ];
  return (
    <Card>
      <Row>
        <Col md={20}>
          <Space size={[13, 0]}>
            {unread && <Badge dot style={{ width: 8, height: 8 }} />}
            <div>
              <h5 className="mt-0 mb-0">Hierarchy Export</h5>
              <p className="m-0">
                Your Acme Corporation Organization Hierarchy Export is Complete
              </p>
            </div>
          </Space>
        </Col>
        <Col md={4} className="text-right">
          <Space direction="vertical" className="text-center">
            <p className="m-0">55m</p>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <EllipsisOutlined style={{ fontSize: "18px" }} />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default NotifyCard;
