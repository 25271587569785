import React from "react";
import { Table, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Column } = Table;
const ReviewGuided = () => {
  const data = [
    {
      key: "1",
      name: "How much of your footprint do you want to calculate?",
      answer: "Scope 1, Scope 2, Scope 3 (Full)",
    },
    {
      key: "2",
      name: "Let's start with your operating assets. Do you have any operating assets in your business",
      answer: "Yes",
    },
    {
      key: "3",
      name: "Does your business operate at any physical locations? This includes any locations leased, operated, and/or owned by your business.",
      answer: "No",
    },
    {
      key: "4",
      name: "Which of the following employee-related emission sources are relevant to your business?",
      answer: "Employee Commuting and Teleworking, Business Travel",
    },
    {
      key: "5",
      name: "Which part of your value chain emissions you would like to account for. (Scope 3 emissions that extend beyond travel and commuting)",
      answer:
        "Downstream Emissions (These are the 'outputs' of your business such as the end of life treatment of products sold), Upstream Emissions (These are the 'inputs' in to your business such as purchased goods and services)",
    },
    {
      key: "6",
      name: "Does your business have significant investment activities?",
      answer: "No",
    },
    {
      key: "7",
      name: "Which of the following asset types do you have in your business? These may be owned or leased assets.",
      answer:
        "Fleet (e.g. car, motorbikes, planes, ships, etc.), Leased Assets, Equipment (e.g. boilers, air conditioning units, chillers, furnaces, etc.), Emissions For Oil and Gas Operations",
    },
  ];
  return (
    <>
      <h4 className="mt-3 mb-3 fs-18">Review Selections</h4>
      <Table dataSource={data} pagination={false}>
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Answer" dataIndex="answer" key="answer" />
        <Column
          title=""
          key="action"
          render={(_, record) => (
            <Button
              type="text"
              icon={<EditOutlined />}
              iconPosition="start"
              size="default"
            >
              Edit
            </Button>
          )}
        />
      </Table>
    </>
  );
};

export default ReviewGuided;
