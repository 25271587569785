import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../config";

const initState = {
  data: "",
  error: "",
  loading: false,
};

export const fetchStates = createAsyncThunk(
  "data/fetchStates",
  async (url, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(url);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log("err ", err);
      const message = err.response.data?.message ?? "An error occurred";
      return rejectWithValue(message);
    }
  }
);

const stateSlice = createSlice({
  name: "stateSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.error = action.payload ?? "Unknown error occurred";
        state.loading = false;
      });
  },
});

export default stateSlice.reducer;
