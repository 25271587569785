import React, { useRef, useState, useEffect } from "react";
import {
  Space,
  Layout,
  Button,
  Table,
  Flex,
  DatePicker,
  Switch,
  Input,
  Radio,
  Divider,
  Drawer,
  Tabs,
  Collapse,
  Modal,
  Row,
  Col,
  Form,
  Select,
  Upload,
} from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import {
  DownloadOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
  DownOutlined,
  SearchOutlined,
  EditOutlined,
  CloseOutlined,
  MinusOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DetailList from "./DetailList";
import Search from "../../../images/search.svg";

const { Header } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const ActivityLedger = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState("");
  const toggleFilters = useRef(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFootprint, setOpenFootprint] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [attachFile, setAttachFile] = useState(false);
  const [handleASValue, setHandleASValue] = useState("");
  const [valueCM, setValueCM] = useState("");
  const [valueEFS, setValueEFS] = useState("");

  const getItems = () => [
    {
      key: "1",
      label: "Activity Details",
      children: <DetailList />,
    },
    {
      key: "2",
      label: "Energy Details",
      children: <DetailList />,
    },
    {
      key: "3",
      label: "Fuel Details",
      children: <DetailList />,
    },
    {
      key: "4",
      label: "Gasses",
      children: <DetailList />,
    },
    {
      key: "5",
      label: "Accounting Standing & Emission Factor Set",
      children: <DetailList />,
    },
  ];

  const getItemsFormula = () => [
    {
      key: "1",
      label: "Calculation formula",
      children: <DetailList />,
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const openDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Activity ID",
      dataIndex: "activityID",
      key: "activityID",
      width: 295,
      render: (_, { activityID }) => (
        <p className="m-0 cursor-pointer fw-500" onClick={openDrawer}>
          {activityID}
        </p>
      ),
    },
    {
      title: "Job ID",
      dataIndex: "jobID",
      key: "jobID",
      width: 240,
      render: (_, { jobID }) => <p className="m-0 fw-500">{jobID}</p>,
    },
    {
      title: "Job File Name",
      dataIndex: "jobFileName",
      key: "jobFileName",
      width: 240,
      render: (_, { jobFileName }) => (
        <p className="m-0 fw-500">{jobFileName}</p>
      ),
    },
    {
      title: "Activity start",
      dataIndex: "activityStart",
      key: "activityStart",
      width: 165,
      sorter: (a, b) => a.activityStart - b.activityStart,
    },
    {
      title: "Activity end",
      dataIndex: "activityEnd",
      key: "activityEnd",
      width: 145,
      sorter: (a, b) => a.activityEnd - b.activityEnd,
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      width: 135,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 130,
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
      width: 130,
    },
    {
      title: "Footprint source",
      dataIndex: "footprintSource",
      key: "footprintSource",
      width: 225,
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      width: 140,
    },
    {
      title: "Attachment file name",
      dataIndex: "attachmentFileName",
      key: "attachmentFileName",
      width: 185,
    },
    {
      title: "Calculation framwork",
      dataIndex: "calculationFramwork",
      key: "calculationFramwork",
      width: 185,
    },
    {
      title: "Calculation method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 185,
    },
    {
      title: "Energy consumed",
      dataIndex: "energyConsumed",
      key: "energyConsumed",
      width: 140,
    },
    {
      title: "Fuel",
      dataIndex: "fuel",
      key: "fuel",
      width: 140,
    },
    {
      title: "Fuel amount",
      dataIndex: "fuelAmount",
      key: "fuelAmount",
      width: 140,
    },
    {
      title: "Heart and steam type",
      dataIndex: "heartandsteamttype",
      key: "heartandsteamttype",
      width: 185,
    },
    {
      title: "CO₂e emissions",
      dataIndex: "cO2eEmissions",
      key: "cO2eEmissions",
      width: 185,
    },
    {
      title: "Emissions factor set name",
      dataIndex: "emissionsfactorsetname",
      key: "emissionsfactorsetname",
      width: 215,
    },
  ];
  const data = [
    {
      key: "1",
      activityID: "MC-Acme-GHGP-140524-01",
      jobID: "INT-Acmecorporation-JR-140524-01",
      jobFileName: "Acme Corporation _CO₂e_Ledger1.csv",
      activityStart: "03-01-2024",
      activityEnd: "10-01-2024",
      organization: "Acme Corporation",
      category: "Fleet",
      subcategory: "Mobile Combustion",
      footprintSource: "Mobile Combustion",
      scope: "1",
      attachmentFileName: "--",
      calculationFramwork: "Greenhouse gas protocol",
      calculationMethod: "Distance-Based",
      energyConsumed: "10kWh",
      fuel: "Diesel fuel",
      fuelAmount: "5 L",
      heartandsteamttype: "--",
      cO2eEmissions: "0.0052 t",
      emissionsfactorsetname: "US EPA - Emission Factor Hub 2021",
    },
    {
      key: "2",
      activityID: "PE-Acme-GHGP-140524-01",
      jobID: "INT-Acmecorporation-JR-140524-01",
      jobFileName: "Acme Corporation _CO₂e_Ledger1.csv",
      activityStart: "03-01-2024",
      activityEnd: "10-01-2024",
      organization: "Acme Corporation",
      category: "Fleet",
      subcategory: "Purchased Electricity",
      footprintSource: "Purchased Electricity - Fleet On Site Fueling",
      scope: "2",
      attachmentFileName: "--",
      calculationFramwork: "Greenhouse gas protocol",
      calculationMethod: "DistLocationance-Based",
      energyConsumed: "100kWh",
      fuel: "Diesel fuel",
      fuelAmount: "5 L",
      heartandsteamttype: "--",
      cO2eEmissions: "<0.0001 t",
      emissionsfactorsetname: "IEA International Electricity Factors (2020)",
    },
  ];

  const handleChange = (selectedDate) => {
    console.log(selectedDate);
  };
  const toggleDatePicker = () => {
    setPickerOpen(!pickerOpen);
  };
  const toggleActivity = () => {
    setCheckedSwitch(!checkedSwitch);
  };

  const toggleFilter = (e) => {
    if (e === "organization") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "organization") {
          return "";
        } else {
          return "organization";
        }
      });
    } else if (e === "investment") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "investment") {
          return "";
        } else {
          return "investment";
        }
      });
    } else if (e === "category") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "category") {
          return "";
        } else {
          return "category";
        }
      });
    } else if (e === "subcategory") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "subcategory") {
          return "";
        } else {
          return "subcategory";
        }
      });
    } else if (e === "scope") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "scope") {
          return "";
        } else {
          return "scope";
        }
      });
    } else if (e === "footprintSource") {
      setIsFilterOpen((prevIsFilterOpen) => {
        if (prevIsFilterOpen === "footprintSource") {
          return "";
        } else {
          return "footprintSource";
        }
      });
    }
  };

  const handleClickOutside = (event) => {
    if (
      toggleFilters.current &&
      !toggleFilters.current.contains(event.target)
    ) {
      setIsFilterOpen("");
    }
  };

  const showMemberModal = () => {
    setOpenDelete(true);
  };
  const handleOk = () => {
    setOpenDelete(false);
    setDeleteRecord(false);
    setAttachFile(false);
  };
  const handleCancel = () => {
    setOpenDelete(false);
    setDeleteRecord(false);
    setAttachFile(false);
  };

  const addFootprint = () => {
    setOpenFootprint(true);
  };

  const onCloseFootprint = () => {
    setOpenFootprint(false);
  };

  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const optionsAS = [
    { value: "EC", label: "External Calculation" },
    { value: "GGP", label: "Greenhouse Gas Protocol" },
  ];

  const handleASChange = (val) => {
    setHandleASValue(val);
  };

  const onChangeCM = (val) => {
    setValueCM(val.target.value);
  };
  const onChangeEFS = (val) => {
    setValueEFS(val.target.value);
  };

  const toggleSelect = (e) => {
    if (e === "all") {
      setSelectedRowKeys(true ? data.map((item) => item.key) : []);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        onCloseFootprint();
        onClose();
      })
      .catch((errorInfo) => {
        // onFinishFailed(errorInfo);
      });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, isFilterOpen);

    return () => {
      document.removeEventListener("click", handleClickOutside, !isFilterOpen);
    };
  }, [toggleFilters, isFilterOpen]);

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0"> Facility Analysis </h4>
        <Space>
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            iconPosition="end"
            className="btn-h-40 fw-500"
          >
            Export
          </Button>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="end"
            className="btn-h-40"
          >
            Add Activity
          </Button>
        </Space>
      </Header>

      <div className="main-content">
        <Flex align="center" justify="space-between" gap="middle">
          {selectedRowKeys.length > 0 ? (
            <>
              <Space split={<Divider type="vertical" />}>
                <div style={{}}>
                  <p className="m-0 fw-500">
                    {selectedRowKeys.length} activities selected
                  </p>
                  <Space size={[46, 0]}>
                    <p
                      style={{ color: "#AE590A" }}
                      className="m-0 fw-500 cursor-pointer"
                      onClick={() => toggleSelect("all")}
                    >
                      Select All
                    </p>
                    <p
                      style={{ color: "#AE590A" }}
                      className="m-0 fw-500 cursor-pointer"
                      onClick={() => toggleSelect("false")}
                    >
                      Deselect All
                    </p>
                  </Space>
                </div>
                <Button
                  shape="round"
                  icon={<PaperClipOutlined />}
                  iconPosition="end"
                  className="btn-h-40 fw-500"
                  onClick={() => setAttachFile(true)}
                >
                  Attach File
                </Button>
                <Button
                  danger
                  shape="round"
                  icon={<DeleteOutlined />}
                  iconPosition="end"
                  className="btn-h-40 fw-500"
                  onClick={() => setDeleteRecord(true)}
                >
                  Delete Activity Record
                </Button>
              </Space>
            </>
          ) : (
            <>
              <Space ref={toggleFilters}>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("organization")}
                  >
                    Organization
                  </Button>
                  {isFilterOpen === "organization" && (
                    <div className="filter-dd">
                      <div className="filter-head">
                        <Input
                          placeholder="Search organization"
                          prefix={<img src={Search} />}
                        />
                      </div>
                      <div className="filter-body">
                        <div className="result-sugg">
                          <Divider />
                          <p className="m-0">Organization list</p>
                        </div>
                        <Radio>Prime Materials Inc.</Radio>
                        <Radio>Reliant Resources Co.</Radio>
                        <Radio>ProSource Supplies Ltd.</Radio>
                        <Radio>Quality Components Group</Radio>
                        <Radio>Essential Elements Corp.</Radio>
                        <Radio>Stellar Supply Chain Solutions</Radio>
                        <Radio>Nexus Parts & Services</Radio>
                        <Radio>Keystone Distributors Inc.</Radio>
                        <Radio>Superior Logistics Co.</Radio>
                        <Radio>Benchmark Goods LLC</Radio>
                      </div>
                      <div className="filter-footer">
                        <Button type="gray" className="fw-500">
                          Clear Filters
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("investment")}
                  >
                    Investment
                  </Button>
                  {isFilterOpen === "investment" && (
                    <div className="filter-dd">
                      <div className="filter-head">
                        <Input
                          placeholder="Search organization"
                          prefix={<img src={Search} />}
                        />
                      </div>
                      <div className="filter-body">
                        <div className="result-sugg">
                          <Divider />
                          <p className="m-0">Investment list</p>
                        </div>
                        <Radio>Apex Innovations Inc.</Radio>
                        <Radio>Summit Solutions Co.</Radio>
                        <Radio>Vertex Ventures LLC</Radio>
                        <Radio>Pinnacle Partners Ltd.</Radio>
                        <Radio>Peak Performance Solutions</Radio>
                        <Radio>Skyward Strategies Inc.</Radio>
                      </div>
                      <div className="filter-footer">
                        <Button type="gray" className="fw-500">
                          Apply Filters
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("category")}
                  >
                    Category
                  </Button>
                  {isFilterOpen === "category" && (
                    <div className="filter-dd">
                      <div className="filter-body">
                        <Radio>All Categories</Radio>
                        <Radio>Assets</Radio>
                        <Radio>Financed Emissions</Radio>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("subcategory")}
                  >
                    Subcategory
                  </Button>
                  {isFilterOpen === "subcategory" && (
                    <div className="filter-dd">
                      <div className="filter-head">
                        <Input
                          placeholder="Search organization"
                          prefix={<img src={Search} />}
                        />
                      </div>
                      <div className="filter-body">
                        <div className="result-sugg">
                          <Divider />
                          <p className="m-0">Subcategory list</p>
                        </div>
                        <Radio>All subcategories</Radio>
                        <Radio>Business travel</Radio>
                        <Radio>Combustion</Radio>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("scope")}
                  >
                    Scope
                  </Button>
                  {isFilterOpen === "scope" && (
                    <div className="filter-dd">
                      <div className="filter-body">
                        <Radio>Scope 1</Radio>
                        <Radio>Scope 2</Radio>
                        <Radio>Scope 3</Radio>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggleFilter">
                  <Button
                    shape="round"
                    icon={<DownOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => toggleFilter("footprintSource")}
                  >
                    Footprint Source
                  </Button>
                  {isFilterOpen === "footprintSource" && (
                    <div className="filter-dd">
                      <div className="filter-head">
                        <Input
                          placeholder="Search organization"
                          prefix={<img src={Search} />}
                        />
                      </div>
                      <div className="filter-body">
                        <div className="result-sugg">
                          <Divider />
                          <p className="m-0">Footprint source list</p>
                        </div>
                        <Radio>All footprint source</Radio>
                        <Radio>Acid Gas Removal Units</Radio>
                        <Radio>Associated Gas Venting and Flaring</Radio>
                      </div>
                    </div>
                  )}
                </div>
              </Space>
            </>
          )}
          <Space>
            <div className="overlay-date">
              <DatePicker open={pickerOpen} onChange={handleChange} />
              <Button
                shape="round"
                icon={<CalendarOutlined />}
                iconPosition="start"
                className="btn-h-40 fw-500"
                onClick={toggleDatePicker}
              >
                All time
              </Button>
            </div>
            <Button
              shape="round"
              className="btn-h-40 fw-500"
              onClick={toggleActivity}
            >
              <Space>
                <Switch checked={checkedSwitch} />
                <p className="m-0">Show Activity with Attachments</p>
              </Space>
            </Button>
          </Space>
        </Flex>
        <div className="mt-24">
          <div
            className="adapt-height mt-24"
            style={{ height: "calc(100vh - 210px)" }}
          >
            <Table
              dataSource={data}
              scroll={{
                x: 4000,
              }}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"],
                itemRender: (current, type, originalElement) => {
                  if (type === "prev") {
                    return <ArrowLeftOutlined />;
                  }
                  if (type === "next") {
                    return <ArrowRightOutlined />;
                  }
                  return originalElement;
                },
              }}
              rowSelection={rowSelection}
              columns={columns}
            ></Table>
          </div>
        </div>
      </div>
      <Drawer
        className="footprintDrawer"
        title={
          <Flex justify="start" align="center" gap="middle">
            <h4 className="m-0">Activity Ledger</h4>
          </Flex>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Flex justify="space-between" align="center" gap="middle">
            <Button
              type="gray"
              shape="round"
              className="btn-h-40"
              onClick={showMemberModal}
            >
              Delete
            </Button>
            <Button
              shape="round"
              icon={<EditOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
              onClick={addFootprint}
            >
              Edit
            </Button>
          </Flex>
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            <div className="px-40 mt-2">
              <div className="collapse-panel">
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={
                    ({ isActive }) =>
                      isActive ? <MinusOutlined /> : <PlusOutlined />
                    // <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  }
                  items={getItems()}
                  expandIconPosition="end"
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Calculation Formula" key="2">
            <div className="px-40 mt-2">
              <div className="collapse-panel">
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={
                    ({ isActive }) =>
                      isActive ? <MinusOutlined /> : <PlusOutlined />
                    // <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  }
                  items={getItemsFormula()}
                  expandIconPosition="end"
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Audit History" key="3">
            <div className="px-40 mt-2">
              <p className="m-0 fw-500">No results found</p>
            </div>
          </TabPane>
        </Tabs>
      </Drawer>

      <Drawer
        className="footprintDrawer"
        title={<h4 className="m-0">Edit activity</h4>}
        onClose={onCloseFootprint}
        open={openFootprint}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onCloseFootprint}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40"
                onClick={onCloseFootprint}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                onClick={handleNext}
                className="btn-h-40"
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form layout="vertical" form={form}>
          <div className="activity-row">
            <div className="activity-body pt-0">
              <div className="mb-20">
                <p className="mt-0">
                  Footprint activities are the actual activities that produce
                  emissions and account for all three scopes defined by the
                  GHGP.
                </p>
                <p>
                  For a transaction, the footprint activity is the lowest level
                  of detail for the footprint categories. This can include
                  activities such as hotel stays, airfare, mobile combustion,
                  utility consumption, etc.
                </p>
                <Button shape="round">Learn more</Button>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Organization Name</h5>
            </div>
            <div className="activity-body">
              <div className="mt-0">
                <Form.Item
                  label="Organization"
                  name="organization"
                  rules={[
                    {
                      required: true,
                      message: "Required organization",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Activity footprint</h5>
            </div>
            <div className="activity-body">
              <p className="m-0 mb-8 fw-600">Footprint Source</p>
              <p className="m-0">
                Don't see the Footprint Source you're looking for?{" "}
                <span style={{ color: "#D95E28" }}>
                  Review and configure your Footprint Source Checklist
                </span>{" "}
                to add more Footprint Sources.
              </p>
              <div className="mt-24">
                <Form.Item
                  label="Footprint source"
                  name="footprint"
                  rules={[
                    {
                      required: true,
                      message: "Required footprint source",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" options={options} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">File attachment</h5>
            </div>
            <div className="activity-body pb-24">
              <p className="m-0 mb-8">
                Attach a file of original source material related to this
                activity entry. Some examples include invoices and energy bills.
              </p>
              <p className="m-0">
                Supported files: pdf, doc, docx, csv, xls, xlsx, jpeg, png{" "}
                <br />
                Maximum file size: 10mb
              </p>
              <div className="drager-upload">
                <Dragger>
                  <p className="ant-upload-text">Drop, Browse, or Import</p>
                </Dragger>
              </div>
            </div>
          </div>
          <div className="activity-row">
            <div className="activity-head">
              <h5 className="m-0 text-black">Activity standard</h5>
            </div>
            <div className="activity-body">
              <div className="mt-0">
                <Form.Item
                  label="Accounting Standard"
                  name="as"
                  rules={[
                    {
                      required: true,
                      message: "Required accounting standard",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    options={optionsAS}
                    onChange={handleASChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {handleASValue === "EC" ? (
            <>
              <div className="activity-row">
                <div className="activity-head">
                  <h5 className="m-0 text-black">Calculation method</h5>
                </div>
                <div className="activity-body">
                  <div className="mt-0">
                    <Form.Item
                      label="Calculation Method*"
                      name="cm"
                      rules={[
                        {
                          required: true,
                          message: "Required accounting standard",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChangeCM} value={valueCM}>
                        <Space direction="vertical">
                          <Radio value="reportedEmission">
                            Custom Footprint Source: Reported Emissions
                          </Radio>
                          <Radio value="reportedTotalEmission">
                            Custom Footprint Source: Reported Total Emissions
                          </Radio>

                          
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {valueCM && (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Location</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Location" name="location">
                          <Select
                            placeholder="Please Select"
                            options={optionsAS}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">
                        Global Warming Potential Set
                      </h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item
                          label="Global warming potential set"
                          name="gwps"
                        >
                          <Select
                            placeholder="Please Select"
                            options={optionsAS}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emissions Information</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item label="CO₂ emissions" name="emissionCo">
                            <Input placeholder="Write here" />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="CO₂ Emissions UOM"
                            name="emissionUOM"
                          >
                            <Select
                              placeholder="Please Select"
                              options={optionsAS}
                            />
                          </Form.Item>
                        </Col>
                        {valueCM === "reportedEmission" ? (
                          <>
                            <Col md={12}>
                              <Form.Item
                                label="CO₂ emissions"
                                name="emissionCo"
                              >
                                <Input placeholder="Write here" />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                label="CO₂ Emissions UOM"
                                name="emissionUOM"
                              >
                                <Select
                                  placeholder="Please Select"
                                  options={optionsAS}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Reference Data</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="External Data Source" name="eds">
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item
                          label="External Calculation Method"
                          name="ecm"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item
                          label="External Emission Factor Set"
                          name="eefs"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        {valueCM === "reportedTotalEmission" ? (
                          <>
                            <Form.Item
                              label="Reported Emissions Global Warming Potential Set"
                              name="regps"
                            >
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item
                              label="External Global Warming Potential Value"
                              name="egwpv"
                            >
                              <Input placeholder="Write here" />
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                        <Form.Item
                          label="External Documentation Reference"
                          name="edr"
                        >
                          <Input placeholder="Write here" />
                        </Form.Item>
                        <Form.Item label="External Activity ID" name="eds">
                          <Input placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Comments</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Comments" name="comments">
                          <TextArea maxLength={100} placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : handleASValue === "GGP" ? (
            <>
              <div className="activity-row">
                <div className="activity-head">
                  <h5 className="m-0 text-black">Calculation method</h5>
                </div>
                <div className="activity-body">
                  <div className="mt-0">
                    <Form.Item
                      label="Calculation Method*"
                      name="cm"
                      rules={[
                        {
                          required: true,
                          message: "Required accounting standard",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChangeCM} value={valueCM}>
                        <Space direction="vertical">
                          <Radio value="distanceBased">Distance-Based</Radio>
                          <Radio value="fuelBased">Fuel-Based: Fuel Type</Radio>

                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {valueCM === "fuelBased" ? (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emission Factor Source</h5>
                    </div>
                    <div className="activity-body">
                      <Form.Item
                        label="Emission Factor Source"
                        name="efs"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value="distanceBased">
                              Customer-Managed
                            </Radio>
                            <Radio value="fuelBased">AhyaOS-Managed</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Comments</h5>
                    </div>
                    <div className="activity-body">
                      <div className="mt-0">
                        <Form.Item label="Comments" name="comments">
                          <TextArea maxLength={100} placeholder="Write here" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </>
              ) : valueCM === "distanceBased" ? (
                <>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Activity date</h5>
                    </div>
                    <div className="activity-body">
                      <Row gutter={[16]}>
                        <Col md={12}>
                          <Form.Item
                            label="Activity start"
                            name="actStart"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="Activity end"
                            name="actEnd"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="DD / MM / YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black">Emission Factor Source</h5>
                    </div>
                    <div className="activity-body">
                      <Form.Item
                        label="Emission Factor Source"
                        name="efs"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group onChange={onChangeEFS} value={valueEFS}>
                          <Space direction="vertical">
                            <Radio value="cusManage">Customer-Managed</Radio>
                            <Radio value="ahyaManage">AhyaOS-Managed</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                  {valueEFS === "ahyaManage" ? (
                    <>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Emission Factor Set
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Emission Factor Set" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Mobile Combustion Details
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item
                              label={
                                <span>
                                  Vehicle Type{" "}
                                  <span
                                    style={{ color: "#0D1126", opacity: 0.4 }}
                                  >
                                    (Vehicle type is based on emission factor
                                    set)
                                  </span>{" "}
                                </span>
                              }
                              name="gwps"
                            >
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Fuel Name" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Row gutter={[16]}>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Input placeholder="Write here" />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Select
                                    placeholder="Please Select"
                                    options={optionsAS}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Vehicle Details</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="VIN" name="vin">
                              <Input placeholder="234" />
                            </Form.Item>
                            <Form.Item label="Internal Identifier" name="iD">
                              <Input placeholder="234" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Comments</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Comments" name="comments">
                              <TextArea
                                maxLength={100}
                                placeholder="Write here"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : valueEFS === "cusManage" ? (
                    <>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Emission Factor Set
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Emission Factor Set" name="gwps">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Emission Factor Name" name="efm">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item
                              label={
                                <span>
                                  Emission Factor{" "}
                                  <span
                                    style={{ color: "#0D1126", opacity: 0.4 }}
                                  >
                                    (Description)
                                  </span>
                                </span>
                              }
                              name="ef"
                            >
                              <Input placeholder="Write here" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Location</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Location" name="location">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                            <Form.Item label="Facility" name="facility">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">
                            Mobile Combustion Details
                          </h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Row gutter={[16]}>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Input placeholder="Write here" />
                                </Form.Item>
                              </Col>
                              <Col md={12}>
                                <Form.Item label="Distance Traveled" name="dt">
                                  <Select
                                    placeholder="Please Select"
                                    options={optionsAS}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Other Details</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Supplier Name" name="location">
                              <Select
                                placeholder="Please Select"
                                options={optionsAS}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="activity-row">
                        <div className="activity-head">
                          <h5 className="m-0 text-black">Comments</h5>
                        </div>
                        <div className="activity-body">
                          <div className="mt-0">
                            <Form.Item label="Comments" name="comments">
                              <TextArea
                                maxLength={100}
                                placeholder="Write here"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Form>
      </Drawer>

      <Modal
        open={attachFile}
        title="Attach a file"
        onOk={handleOk}
        width={645}
        onCancel={handleCancel}
        footer={(_) => (
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" onClick={handleCancel}>
              Close
            </Button>
            <Button type="primary" shape="round" onClick={handleCancel}>
              Save
            </Button>
          </Flex>
        )}
      >
        <p className="text-blad-60 fs-14">
          Attach a file of original source material related to this activity
          entry. Some examples include invoices and energy bills.
        </p>
        <p className="text-blad-60 fs-14">
          Supported files: pdf, doc, docx, csv, xls, xlsx, jpeg, pngMaximum file
          size: 10mb
        </p>
        <div className="drager-upload mb-20">
          <Dragger>
            <p className="ant-upload-text">Drop, Browse, or Import</p>
          </Dragger>
        </div>
      </Modal>
      <Modal
        open={deleteRecord}
        title="Are you sure?"
        onOk={handleOk}
        width={645}
        onCancel={handleCancel}
        footer={(_) => (
          <Flex justify="space-between" align="center" gap="middle">
            <Button shape="round" onClick={handleCancel}>
              Close
            </Button>
            <Button type="primary" shape="round" onClick={handleCancel}>
              Delete
            </Button>
          </Flex>
        )}
      >
        <p className="text-black-60 fs-14">
          You are about to delete {selectedRowKeys.length} activities and any
          linked data. Do you want to continue?
        </p>
      </Modal>

      <Modal
        open={openDelete}
        title="Are you sure?"
        onOk={handleOk}
        width={400}
        onCancel={handleCancel}
        footer={(_) => (
          <Flex justify="space-between" align="center" gap="middle">
            <Button type="primary" shape="round" onClick={handleCancel}>
              Delete
            </Button>
          </Flex>
        )}
      >
        <p className="m-0 text-black-60 mb-2 fs-16">
          You are about to delete and any linked data. <br /> Do you want to
          continue?
        </p>
        <p className="m-0 text-black fs-14 fw-500 mb-3">
          8 Uncategorized Dependency
        </p>
      </Modal>
    </>
  );
};

export default ActivityLedger;
