import React, { useState } from "react";
import {
  Button,
  Flex,
  Space,
  Input,
  Switch,
  Select,
  Table,
  Dropdown,
  Drawer,
  Row,
  Col,
  Form,
  Radio,
  DatePicker,
} from "antd";
import {
  SettingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Search from "../../../images/search.svg";

const ReportingPeriods = () => {
  const [form] = Form.useForm();
  const [addYear, setAddYear] = useState("false");
  const [addPeriod, setAddPeriod] = useState("false");
  const column = [
    {
      title: "Reporting Year",
      dataIndex: "reportingYear",
      key: "reportingYear",
      sorter: (a, b) => a.reportingYear - b.reportingYear,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) => a.startDate - b.startDate,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => a.endDate - b.endDate,
    },
    {
      title: <div className="text-right pr-3">Action</div>,
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: editRowDD,
              onClick: (event) => {
                const { key } = event;
                editRow(key, record);
              },
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<EllipsisOutlined />}
              iconPosition="start"
              style={{ transform: "rotate(90deg)", float: "right" }}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const editRow = (key, data) => {
    // if (key === "view") {
    //   setDrawerOpenView(true);
    // }
    // if (key === "edit") {
    //   setDrawerOpenEdit(true);
    // }
    console.log(key);
  };

  const editRowDD = [
    {
      label: "Edit Year",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete Year",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  const data = [
    {
      key: "1",
      reportingYear: "FY2023",
      startDate: "10-12-2023",
      endDate: "06-30-2023",
    },
    {
      key: "2",
      reportingYear: "FY2023",
      startDate: "10-12-2023",
      endDate: "06-30-2023",
    },
    {
      key: "3",
      reportingYear: "FY2023",
      startDate: "10-12-2023",
      endDate: "06-30-2023",
    },
  ];

  const onClose = () => {
    setAddYear(false);
    setAddPeriod(false);
  };

  const optionsAS = [
    { value: "yes", label: "IPCC Fourth Assessment Report (AR4)" },
  ];
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        className="mt-3"
        gap="middle"
      >
        <Input
          placeholder="Search"
          prefix={<img src={Search} />}
          style={{ maxWidth: 500, borderRadius: 25 }}
        />
        <Space size={[12, 12]}>
          <Button
            shape="circle"
            icon={<SettingOutlined />}
            iconPosition="start"
            className="btn-h-40 fw-500 text-black px-2"
          ></Button>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            className="btn-h-40 fw-500"
            onClick={() => setAddYear(true)}
          >
            Add Reporting Year
          </Button>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            iconPosition="start"
            className="btn-h-40 fw-500"
            onClick={() => setAddPeriod(true)}
          >
            Add Reporting Period
          </Button>
        </Space>
      </Flex>
      <div
        className="adapt-height mt-24"
        style={{ height: "calc(100vh - 480px)" }}
      >
        <Table dataSource={data} columns={column} pagination={false} />
      </div>

      <Drawer
        className="footprintDrawer"
        title="Add Reporting Year"
        onClose={onClose}
        open={addYear}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Add
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="px-40">
          <p className="text-blad">
            Generally, the reporting year coincides with the fiscal year of your
            organization. Choosing a reporting year is completely optional, but
            it makes finding relevant data much easier by allowing you to
            quickly view emissions data according to your organization's
            calendar.
          </p>
          <Button
            type="gray"
            shape="round"
            className="btn-h-40 mt-24"
            onClick={onClose}
          >
            Learn More
          </Button>
        </div>
        <div className="activity-row mt-24">
          <div className="activity-head">
            <h5 className="m-0 text-black">Reporting Year Details</h5>
          </div>
          <div className="activity-body">
            <Form layout="vertical" form={form}>
              <Form.Item
                label="Parent Reporting Year"
                name="fName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Please Select" />
              </Form.Item>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <Form.Item
                    label="Date Start"
                    name="ds"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select..."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Date End"
                    name="de"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select..."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    label="Global Warming Potential Set"
                    name="gwps"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={optionsAS} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="footprintDrawer"
        title="Add Reporting Period"
        onClose={onClose}
        open={addPeriod}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Add
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="px-40">
          <p className="text-blad">
            Reporting periods specify a date range within a reporting year that
            you would like to filter for. For example, you can set a reporting
            year for January 1, 2022 to December 31, 2022 with a reporting
            period of January 1, 2022 to March 31, 2022. You can create as many
            reporting years and reporting periods as you'd like based on your
            organization's needs.
          </p>
          <Button
            type="gray"
            shape="round"
            className="btn-h-40 mt-24"
            onClick={onClose}
          >
            Learn More
          </Button>
        </div>
        <div className="activity-row mt-24">
          <div className="activity-head">
            <h5 className="m-0 text-black">Reporting Period Details</h5>
          </div>
          <div className="activity-body">
            <Form layout="vertical" form={form}>
              <Row gutter={[24, 0]}>
                <Col md={24}>
                  <Form.Item
                    label="Parent Reporting Year"
                    name="gwps"
                    extra="Choose the reporting year for which you'd like to create a reporting period"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select" options={optionsAS} />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    label="Name"
                    name="fName"
                    extra="Name your reporting period for easier selection"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Please Select" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Date Start"
                    name="ds"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select..."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Date End"
                    name="de"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select..."
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ReportingPeriods;
