import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import MenuIcon from "../../../images/catalogIcon/1.png";
import MenuIcon1 from "../../../images/catalogIcon/2.png";
import MenuIcon2 from "../../../images/catalogIcon/3.png";
const InnerSidebar = () => {
  const location = useLocation();
  const menuObj = [
    {
      id: 0,
      name: "Assets",
      icon: MenuIcon,
      innerLink: [
        {
          id: "assets-1",
          link: "",
          label: "Equipment",
        },
        {
          id: "assets-2",
          link: "/facilities",
          label: "Facilities",
        },
        {
          id: "assets-3",
          link: "/locations",
          label: "Location",
        },
      ],
    },
    {
      id: 1,
      name: "Catalogs",
      icon: MenuIcon1,
      innerLink: [
        {
          id: "catalogs-1",
          link: "/financed-entity",
          label: "Financed Entity",
        },
        {
          id: "catalogs-2",
          label: "Products",
        },
        {
          id: "catalogs-3",
          label: "Product Subcategory",
        },
        {
          id: "catalogs-4",
          label: "Supplier",
        },
      ],
    },
    {
      id: 1,
      name: "Calculations",
      icon: MenuIcon2,
      innerLink: [
        {
          id: "calculation-1",
          link: "/emission-factor",
          label: "Emissions factors (default)",
        },
        {
          id: "calculation-2",
          label: "Emissions factors (custom)",
        },
        {
          id: "calculation-3",
          label: "Emissions factor sets",
        },
        {
          id: "calculation-4",
          link: "/footprint-source",
          label: "Footprint sources (default)",
        },
        {
          id: "calculation-5",
          label: "Footprint sources (custom)",
        },
      ],
    },
  ];
  const [menuItem, setMenuItem] = useState(menuObj);
  const [menuName, setMenuName] = useState("");

  useEffect(() => {
    let x = localStorage.getItem("manuName");
    setMenuName(x);
  }, []);

  const handleMenu = (e) => {
    localStorage.setItem("manuName", e);
  };
  return (
    <div className="upload-section">
      <div className="upload-body">
        <ul className="inner-menu">
          {menuItem.map((val, ind) => {
            return (
              <li key={ind}>
                <div className="menu-head">
                  <img src={val.icon} />
                  {val.name}
                </div>
                <div className="menu-body">
                  <ul className="menu-list">
                    {val?.innerLink.map((data, key) => {
                      return (
                        <li key={key}>
                          <Link
                            to={`/measure/catalog${data.link}`}
                            onClick={() => handleMenu(data.link)}
                            className={data.link === menuName ? "active" : ""}
                          >
                            {data.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default InnerSidebar;
