import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Space,
  Dropdown,
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Flex,
  Radio,
  Progress,
  Drawer,
  DatePicker,
  Form,
} from "antd";
import Icon, {
  CalendarOutlined,
  DownOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  LineChartOutlined,
  PercentageOutlined,
  BarChartOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Assets from "../../../images/assets.png";
import LineChart from "../../../sharedModules/charts/LineChart";
import BarChart from "../../../sharedModules/charts/BarChart";
import assetsIcon from "../../../images/overviewIcon/assetsicon.png";
import financedEmissionIcon from "../../../images/overviewIcon/financeEmission.png";
import poepleActivityIcon from "../../../images/overviewIcon/PeopleActivity.png";
import sitesIcon from "../../../images/overviewIcon/sites.png";
import supplyChainIcon from "../../../images/overviewIcon/supplyChain.png";

const { Header } = Layout;
const BarChartI = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 10.0001H5.83333V17.5001H2.5V10.0001ZM14.1667 6.66675H17.5V17.5001H14.1667V6.66675ZM8.33333 1.66675H11.6667V17.5001H8.33333V1.66675Z"
      // fill="#0F1324"
    />
  </svg>
);
const BarChartIcon = (props) => <Icon component={BarChartI} {...props} />;
const Overview = () => {
  const dataStack = {
    labels: ["Scope"],
    datasets: [
      {
        label: "Scope 1",
        data: [2123.35],
        backgroundColor: "#FFF5F1",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 30,
      },
      {
        label: "Scope 2",
        data: [1800],
        backgroundColor: "#F2BFAB",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 30,
      },
      {
        label: "Scope 3",
        data: [4123],
        backgroundColor: "#CC4D19",
        borderWidth: 3,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 30,
      },
    ],
  };

  const optionStack = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8,
        },
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        stacked: true,
        display: false,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "#000",
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#14151A",
        bodyColor: "#14151A",
        borderColor: "white",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue + " tCO₂e";
          },
          title: function (data) {
            return data[0].dataset.label;
          },
        },
      },
    },
  };

  const dataBar = {
    labels: [
      "Jan 23",
      "Feb 23",
      "Mar 23",
      "Apr 23",
      "May 23",
      "Jun 23",
      "Jul 23",
      "Aug 23",
      "Sep 23",
      "Oct 23",
      "Nov 23",
      "Dec 23",
    ],
    datasets: [
      {
        label: "Assets",
        data: [22, 30, 25, 22, 30, 34, 35, 19, 42, 25, 47, 46],
        value: "1,002.37",
        backgroundColor: "#3F686E",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 43,
      },
      {
        label: "Sites",
        data: [22, 30, 25, 22, 30, 34, 35, 19, 42, 25, 47, 46],
        value: "26,660.92",
        backgroundColor: "#556956",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 43,
      },
      {
        label: "Supply chain",
        data: [68, 49, 55, 64, 52, 45, 46, 67, 37, 53, 32, 32],
        value: "600,356.23",
        backgroundColor: "#7F7C71",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 43,
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 4,
          bottomRight: 4,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };
  const data = {
    labels: [
      "Jan 23",
      "Feb 23",
      "Mar 23",
      "Apr 23",
      "May 23",
      "Jun 23",
      "Jul 23",
      "Aug 23",
      "Sep 23",
      "Oct 23",
      "Nov 23",
      "Dec 23",
    ],
    datasets: [
      {
        label: "Scope 1",
        lineTension: 0.05,
        pointRadius: 0,
        data: [5, 27, 35, 10, 20, 10, 20, 40, 45, 20, 22, 10],
        borderColor: "#556956",
        backgroundColor: "#556956",
      },
      {
        label: "Scope 2",
        lineTension: 0.05,
        pointRadius: 0,
        data: [110, 100, 110, 100, 120, 105, 115, 100, 110, 120, 115, 105],
        borderColor: "#7F7C71",
        backgroundColor: "#7F7C71",
      },
      {
        label: "Scope 3",
        lineTension: 0.05,
        pointRadius: 0,
        data: [25, 20, 40, 10, 30, 40, 20, 25, 40, 20],
        borderColor: "#3F686E",
        backgroundColor: "#3F686E",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };
  const radioValueEmission = [
    {
      id: "line",
      title: <LineChartOutlined />,
      items: "",
    },
    {
      id: "percent",
      title: <PercentageOutlined />,
      items: "",
    },
    {
      id: "bar",
      title: <BarChartIcon />,
      items: "",
    },
  ];
  const radioValue = [
    {
      id: "all",
      title: "All",
      items: "",
    },
    {
      id: "scope1",
      title: "Scope1",
      items: "",
    },
    {
      id: "scope2",
      title: "Scope2",
      items: "",
    },
    {
      id: "scope3",
      title: "Scope3",
      items: "",
    },
  ];
  const [pickerOpen, setPickerOpen] = useState(false);
  const [scopeValue, setScopeValue] = useState(radioValue[0].id);
  const [emissionValue, setEmissionValue] = useState(radioValueEmission[0].id);
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];
  const allCounts = [
    {
      label: "10",
      key: "1",
    },
    {
      label: "20",
      key: "2",
    },
    {
      label: "30",
      key: "3",
    },
  ];

  const onChangeScope = (e) => {
    setScopeValue(e.target.value);
  };

  const onChangeEmision = (e) => {
    setEmissionValue(e.target.value);
  };

  const ViewByScopeData = [
    {
      index: `1`,
      catagory: "Purchased Goods and Services (Category 1)",
      value: "6000,221.23",
      percentage: 58.79,
      color: "#7A884E",
    },
    {
      index: `2`,
      catagory: "Equity - Listed (Category 15)",
      value: "316,556",
      percentage: 31.01,
      color: "#3F686E",
    },
    {
      index: `3`,
      catagory: "Project Finance - Debt (Category 15)",
      value: "66,177.98",
      percentage: 6.48,
      color: "#87B997",
    },
    {
      index: `4`,
      catagory: "Stationary Combustion - Facility",
      value: "26,660.92",
      percentage: 2.61,
      color: "#F48E2F",
    },
  ];

  const handleOk = () => {
    setPickerOpen(false);
  };

  const handleCancel = () => {
    setPickerOpen(false);
  };

  const overviewCard = [
    {
      id: 0,
      image: assetsIcon,
      label: "Assets",
      count: "1002.37",
      badgeVal: "tCO₂e",
    },
    {
      id: 3,
      image: financedEmissionIcon,
      label: "Financed Emissions",
      count: "392,959.29",
      badgeVal: "tCO₂e",
    },
    {
      id: 4,
      image: poepleActivityIcon,
      label: "People Activity",
      count: "--",
      badgeVal: "tCO₂e",
    },
    {
      id: 1,
      label: "Sites",
      count: "26,660.92",
      badgeVal: "tCO₂e",
      image: sitesIcon,
    },
    {
      id: 2,
      image: supplyChainIcon,
      label: "Supply Chain",
      count: "600,356.23",
      badgeVal: "tCO₂e",
    },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Overview</h4>
        <Space>
          <div className="overlay-date">
            <Button
              type="gray"
              shape="round"
              icon={<CalendarOutlined />}
              iconPosition="start"
              className="btn-h-40 fw-500"
              onClick={() => setPickerOpen(true)}
            >
              All time
            </Button>
          </div>

          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button
              type="gray"
              shape="round"
              icon={<DownOutlined />}
              iconPosition="end"
              className="btn-h-40 fw-500"
            >
              Acme Corporation
            </Button>
          </Dropdown>
        </Space>
      </Header>

      <div className="main-content">
        <Row gutter={[20, 16]}>
          <Col md={16}>
            <Card
              className="header-pad-12"
              title="My footprint"
              extra={
                <>
                  {/* Organization Details */}
                  <Button
                    type="text"
                    icon={<InfoCircleOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => setOpen(true)}
                  >
                    Organization Details
                  </Button>
                </>
              }
            >
              <Flex
                gap="middle"
                className="large-divider"
                justify="space-between"
                wrap
              >
                <Card className="text-center" style={{ maxWidth: 229 }}>
                  <p className="m-0 text-black fs-12">Total Footprint</p>
                  <div className="price-sec m-0">
                    <h3 className="fw-700 m-0">1.021 M</h3>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-500 fs-12 text-black mt-2">All time</p>
                </Card>
                <span className="ant-space-item-split">
                  <div
                    className="ant-divider css-var-r2pb ant-divider-vertical"
                    role="separator"
                  ></div>
                </span>
                <Card className="text-center" style={{ maxWidth: 229 }}>
                  <Badge
                    count="10.58%"
                    style={{
                      backgroundColor: "#FFF5F1",
                      color: "#14151A",
                      fontWeight: "500",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h3 className="fw-700 m-0">7,732.56</h3>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-500 fs-12 text-black mt-2">Scope 1</p>
                </Card>
                <Card className="text-center" style={{ maxWidth: 229 }}>
                  <Badge
                    count="2.58%"
                    style={{
                      backgroundColor: "#F2BFAB",
                      color: "#000",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h3 className="fw-700 m-0">7,732.56</h3>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-500 fs-12 text-black mt-2">Scope 2</p>
                </Card>
                <Card className="text-center" style={{ maxWidth: 229 }}>
                  <Badge
                    count="79.58%"
                    style={{
                      backgroundColor: "#CC4D19",
                      color: "#fff",
                      fontWeight: "600",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="price-sec m-0">
                    <h3 className="fw-700 m-0">57,732.56</h3>
                    <Badge
                      count="tCO₂e"
                      style={{
                        backgroundColor: "#E9EAEC",
                        color: "rgba(15, 19, 36, 0.6)",
                        fontWeight: "600",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                  <p className="mb-0 fw-500 fs-12 text-black mt-2">Scope 3</p>
                </Card>
              </Flex>

              <div className="graph-100 mt-24 h-20 ">
                <BarChart data={dataStack} options={optionStack} />
              </div>
            </Card>
          </Col>
          <Col md={8}>
            <Space direction="vertical" className="w-100">
              {overviewCard.map((elementInArray, index) => (
                <Card className="body-pad-12" key={index}>
                  <Flex gap="middle" justify="space-between">
                    <Space>
                      <img src={elementInArray.image} alt="img" />
                      <p className="m-0 fw-500"> {elementInArray.label} </p>
                    </Space>
                    <Space>
                      <p className="m-0 fw-500"> {elementInArray.count} </p>
                      <Badge
                        count={elementInArray.badgeVal}
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </Space>
                  </Flex>
                </Card>
              ))}
            </Space>
          </Col>
          <Col md={24}>
            <Card className="scope-card">
              <div className="card-header">
                <Flex justify="space-between" align="center" gap="middle">
                  <div>
                    <h4 className="m-0  fs-16">View by Scope Source</h4>
                    <p className="m-0 fs-12">Data measured in tCO2e</p>
                  </div>
                  <div className="steps-wizard">
                    <div className="ant-form radio-round">
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        value={scopeValue}
                        onChange={onChangeScope}
                      >
                        {radioValue.map((val, index) => {
                          return (
                            <Radio.Button key={index} value={val.id}>
                              {val.title}
                              <Badge
                                count={val.items}
                                style={{
                                  backgroundColor: "#E9EAEC",
                                  color: "#14151A",
                                  fontWeight: "500",
                                  fontSize: 12,
                                  borderRadius: "6px",
                                  boxShadow: "none",
                                  marginLeft: 4,
                                }}
                              />
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                </Flex>
              </div>
              <div className="card-body p-0">
                <ul className="scope-visual">
                  {ViewByScopeData.map((elementInArray, index) => (
                    <li key={index}>
                      <Flex gap="middle" justify="space-between" align="center">
                        <p className="text-black fw-500 m-0">
                          {elementInArray.catagory}
                        </p>
                        <Flex align="start" gap="small">
                          <p className="text-black fw-500 m-0">
                            {" "}
                            {elementInArray.value}{" "}
                          </p>
                          <Progress
                            percent={elementInArray.percentage}
                            percentPosition={{ align: "end", type: "outer" }}
                            size={[550, 20]}
                            strokeColor={elementInArray.color}
                          />
                        </Flex>
                      </Flex>
                    </li>
                  ))}
                  <li>
                    <Dropdown menu={{ items: allCounts }} trigger={["click"]}>
                      <Button type="gray" className="btn-h-40">
                        Show all
                        <Badge
                          count="10"
                          style={{
                            backgroundColor: "#fff",
                            color: "rgba(15, 19, 36, 0.6)",
                            fontWeight: "600",
                            borderRadius: "6px",
                            padding: "0 6px",
                          }}
                        />
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
          <Col md={24}>
            <Card className="scope-card">
              <div className="card-header">
                <Flex justify="space-between" align="center" gap="middle">
                  <div>
                    <h4 className="m-0  fs-16">
                      Footprint Breakdown by GHG (tCO₂e)
                    </h4>
                  </div>
                </Flex>
              </div>
              <div className="card-body">
                <Row gutter={[10, 10]}>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">26,635.15</p>
                    <p className="m-0 text-black-60 fs-12">
                      CO<sub>2</sub>
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">11.87</p>
                    <p className="m-0 text-black-60 fs-12">
                      CH<sub>4</sub>
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">14.15</p>
                    <p className="m-0 text-black-60 fs-12">
                      C<sub>2</sub>O
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">0</p>
                    <p className="m-0 text-black-60 fs-12">HFCs</p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">0</p>
                    <p className="m-0 text-black-60 fs-12">PFC</p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">0</p>
                    <p className="m-0 text-black-60 fs-12">
                      SF<sub>6</sub>
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">0</p>
                    <p className="m-0 text-black-60 fs-12">
                      NF<sub>3</sub>
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0 text-black fw-500">0</p>
                    <p className="m-0 text-black-60 fs-12">Unspecified</p>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col md={24}>
            <Card className="scope-card">
              <div className="card-header">
                <Flex justify="space-between" align="center" gap="middle">
                  <div>
                    <h4 className="m-0 fs-16">
                      {" "}
                      {emissionValue === "line"
                        ? "Total Emissions"
                        : emissionValue === "percent"
                        ? "All scope emissions (Percentage)"
                        : emissionValue === "bar"
                        ? "All scope emissions (Absolute)"
                        : "Total Emissions"}{" "}
                    </h4>
                  </div>
                  <div className="steps-wizard">
                    <Space split={<Divider type="vertical" />}>
                      {emissionValue !== "line" && (
                        <Space>
                          <Dropdown menu={{ items }} trigger={["click"]}>
                            <Button
                              shape="round"
                              type="gray"
                              className="btn-h-40 fw-500 text-black"
                            >
                              <CalendarOutlined /> Months <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Button
                            shape="round"
                            type="gray"
                            icon={<DownOutlined />}
                            iconPosition="end"
                            className="btn-h-40 fw-500 text-black"
                          >
                            All footprint categories
                          </Button>
                        </Space>
                      )}
                      <div className="ant-form radio-round">
                        <Radio.Group
                          buttonStyle="solid"
                          size="large"
                          value={emissionValue}
                          onChange={onChangeEmision}
                        >
                          {radioValueEmission.map((val, index) => {
                            return (
                              <Radio.Button
                                key={index}
                                value={val.id}
                                style={{
                                  fontSize: 18,
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 10,
                                }}
                              >
                                {val.title}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </Space>
                  </div>
                </Flex>
              </div>
              <div className="card-body">
                {emissionValue === "line" ? (
                  <>
                    <div
                      className="price-sec m-0"
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <h1 className="fw-400 text-black m-0 line-1">26,661</h1>
                      <Badge
                        count="ktCO₂e"
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <p className="text-black-60 m-0">
                      Total collected Emissions
                    </p>
                    <div className="graph-300 mt-24">
                      <LineChart data={data} options={options} />
                    </div>
                  </>
                ) : emissionValue === "percent" ? (
                  <>
                    <div
                      className="price-sec m-0"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <h1 className="fw-400 text-black m-0 line-1">26,661</h1>
                      <Badge
                        count="ktCO₂e"
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <p className="text-black-60 m-0">
                      Total collected Emissions
                    </p>
                    <div className="graph-300 mt-24">
                      <BarChart data={dataBar} options={optionsBar} />
                    </div>
                  </>
                ) : emissionValue === "bar" ? (
                  <>
                    <div
                      className="price-sec m-0"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <h1 className="fw-400 text-black m-0 line-1"> 26,661 </h1>
                      <Badge
                        count="ktCO₂e"
                        style={{
                          backgroundColor: "#E9EAEC",
                          color: "rgba(15, 19, 36, 0.6)",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <p className="text-black-60 m-0">
                      Total collected Emissions
                    </p>
                    <div className="graph-300 mt-24">
                      <BarChart data={dataBar} options={optionsBar} />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card>
          </Col>
          <Col md={24}>
            <Card className="scope-card">
              <div className="card-header">
                <Flex wrap justify="space-between" align="center" gap="middle">
                  <h4 className="m-0 fs-16">View by Scope Source</h4>
                  <Button
                    type="text"
                    icon={<InfoCircleOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                  >
                    How the emissions map works
                  </Button>
                </Flex>
              </div>
              <div className="card-body p-0">
                <div className="google-map">
                  <div className="steps-wizard">
                    <div className="ant-form radio-round">
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        value={scopeValue}
                        onChange={onChangeScope}
                      >
                        {radioValue.map((val, index) => {
                          return (
                            <Radio.Button key={index} value={val.id}>
                              {val.title}
                              <Badge
                                count={val.items}
                                style={{
                                  backgroundColor: "#E9EAEC",
                                  color: "#14151A",
                                  fontWeight: "500",
                                  fontSize: 12,
                                  borderRadius: "6px",
                                  boxShadow: "none",
                                  marginLeft: 4,
                                }}
                              />
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="iframe-code">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13745422.410053864!2d-99.42450506359208!3d32.744075710427836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1722294083468!5m2!1sen!2s"
                      width="600"
                      height="450"
                      frameborder="0"
                      style={{ border: 0 }}
                      allowfullscreen="false"
                      aria-hidden="false"
                      tabindex="0"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        open={pickerOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="510px"
        footer={(_) => (
          <Flex
            justify="space-between"
            align="center"
            gap="middle"
            style={{
              paddingTop: 24,
              borderTop: "1px solid rgba(5, 5, 5, 0.06)",
            }}
          >
            <Button type="gray" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="start"
              size="default"
            >
              Search
            </Button>
          </Flex>
        )}
      >
        <Form layout="vertical">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="pq">Past quarter</Radio>
              <Radio value="pm">Past month</Radio>
              <Radio value="py">Past year</Radio>
              <Radio value="at">All time</Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Form.Item label="Custom date range">
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
              <Col md={12}>
                <DatePicker placeholder="MM-DD-YYYY" className="w-100" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Drawer
        title={
          <>
            <h4 className="m-0">Acme Corporation</h4>
          </>
        }
        onClose={onClose}
        open={open}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Space size={[24, 24]} direction="vertical">
          <p className="mt-0 text-blad fw-700 mb-0">Industry Details</p>

          <div>
            <p className="m-0 text-blad fw-600">Sector</p>
            <p className="m-0 text-blad">Health Care and Social Assistance</p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600">Industry</p>
            <p className="m-0 text-blad">
              General Medical and Surgical Hospitals
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600">Sub-Industry</p>
            <p className="m-0 text-blad">
              General Medical and Surgical Hospitals
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600">Section description</p>
            <p className="m-0 text-blad">
              This industry comprises establishments known and licensed as
              general medical and surgical hospitals primarily engaged in
              providing diagnostic and medical treatment (both surgical and
              nonsurgical) to inpatients with any of a wide variety of medical
              conditions. These establishments maintain inpatient beds and
              provide patients with food services that meet their nutritional
              requirements. These hospitals have an organized staff of
              physicians and other medical staff to provide patient care
              services. These establishments usually provide other services,
              such as outpatient services, anatomical pathology services,
              diagnostic X-ray services, clinical laboratory services, operating
              room services for a variety of procedures, and pharmacy services.
            </p>
          </div>
          <div>
            <p className="m-0 text-blad fw-600">Organization description</p>
            <p className="m-0 text-blad">Account level resource group</p>
          </div>
        </Space>
      </Drawer>
    </>
  );
};

export default Overview;
