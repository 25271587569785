import React, { useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Upload,
  Drawer,
  Progress,
  Flex,
  Space,
  Form,
  Select,
  Collapse,
  Input,
  Checkbox,
} from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import {
  ArrowRightOutlined,
  FileTextFilled,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  FileExcelFilled,
  ApartmentOutlined,
  EditOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import DataOptions from "./DataOptions";

import assets from "../../../images/assets.png";
import financed from "../../../images/financed.png";
import peopleAct from "../../../images/peopleAct.png";
import site from "../../../images/site.png";
import status from "../../../images/status.png";
import management from "../../../images/management.png";
import extCalc from "../../../images/extCalc.png";
import HeaderCollapse from "./HeaderCollapse";

const { Header } = Layout;
const { Dragger } = Upload;

const Fileupload = () => {
  const [templateDrawer, setTemplateDrawer] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);

  const options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
  ];

  const handleChange = (info) => {
    setFileList(info.fileList.slice(-1));
    handleUpload();
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    // You can replace this part with your upload logic
    const dummyUpload = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(dummyUpload);
          setFileUploaded(true);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };
  const twoColors = {
    "0%": "#edd20b",
    "100%": "#eb632c",
  };

  const onCloseTemplate = () => {
    setTemplateDrawer(false);
  };

  const getItems = () => [
    {
      key: "1",
      label: <HeaderCollapse icon={assets} label="Assets" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "2",
      label: <HeaderCollapse icon={financed} label="Financed emissions" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "3",
      label: <HeaderCollapse icon={peopleAct} label="People activity" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "4",
      label: <HeaderCollapse icon={site} label="Sites" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "5",
      label: <HeaderCollapse icon={status} label="Supply chain" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "6",
      label: (
        <HeaderCollapse icon={management} label="Organization management" />
      ),
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
    {
      key: "7",
      label: <HeaderCollapse icon={extCalc} label="External calculations" />,
      children: <DataOptions onCloseTemplate={onCloseTemplate} />,
    },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">File Upload</h4>
      </Header>

      <div className="main-content">
        {!fileUploaded ? (
          <Row gutter={[20, 20]}>
            <Col md={6}>
              <div className="upload-section">
                <div className="upload-body">
                  <p className="m-0 text-blad mb-3">
                    Drop, browse, or link to any Microsoft Excel or CSV file to
                    the right and Persefoni Advisor will help identify the best
                    data category for the upload.
                  </p>
                  <Button
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => setTemplateDrawer(true)}
                  >
                    Search templates
                  </Button>
                </div>
                <div className="upload-footer">
                  <Button
                    type="text"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={18}>
              <div className="upload-section">
                <div className="upload-body with-dragger">
                  {fileList.length === 0 ? (
                    <Dragger
                      fileList={fileList}
                      onChange={handleChange}
                      beforeUpload={() => false} // Prevent automatic upload
                      multiple={false}
                    >
                      <p className="ant-upload-text">Drop, Browse, or Import</p>
                    </Dragger>
                  ) : (
                    <div className="uploadinig-progress">
                      <Flex gap="middle">
                        <FileTextFilled
                          style={{
                            fontSize: 40,
                            color: "rgba(15, 19, 36, 0.6)",
                          }}
                        />
                        <div className="uploading-status">
                          <p className="fw-500 m-0 text-black">
                            {fileList[0]?.name}
                          </p>
                          <Progress
                            percent={progress}
                            strokeColor={twoColors}
                            showInfo={false}
                          />
                          <p className="fw-500 m-0 text-black-60 fs-12">
                            Scanning file for viruses
                          </p>
                        </div>
                      </Flex>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={[20, 20]}>
            <Col md={6}>
              <div className="upload-section px-0">
                <div className="upload-body">
                  <h4 className="m-0 px-24" style={{ color: "#000" }}>
                    File Uploaded
                  </h4>
                  <p className="px-24 text-blad">
                    Looks like you uploaded an XLSX file. Here are the details
                    we gathered:
                  </p>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black-60 ff-mont">File Name</h5>
                    </div>
                    <div className="activity-body">
                      <Flex justify="space-between" align="center">
                        <Space gap="small">
                          <div className="icon-40">
                            <FileExcelFilled />
                          </div>
                          <p className="m-0 fs-16 text-black">
                            ghg_inventory_cog
                          </p>
                        </Space>
                        <EditOutlined />
                      </Flex>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black-60 ff-mont">Format</h5>
                    </div>
                    <div className="activity-body">
                      <p className="m-0">XLSX</p>
                    </div>
                  </div>
                  <div className="activity-row">
                    <div className="activity-head">
                      <h5 className="m-0 text-black-60 ff-mont">
                        Sheet Selected
                      </h5>
                    </div>
                    <div className="activity-body">
                      <p className="m-0">Facilities</p>
                    </div>
                  </div>
                </div>
                <div className="upload-footer px-24">
                  <Button
                    type="text"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={18}>
              <div className="upload-section">
                <div className="upload-body">
                  <Form layout="vertical">
                    <h4 className="m-0">XLSX Sheet Name</h4>
                    <p className="text-blad mt-8 mb-24">
                      Looks like you're using a .xlsx file with multiple sheets.
                      Please select the sheet you want to use for the data
                      upload.
                    </p>
                    <Form.Item
                      label="Select Sheet"
                      name="sheet"
                      rules={[
                        {
                          required: true,
                          message: "Required sheet",
                        },
                      ]}
                    >
                      <Select placeholder="Facilities" options={options} />
                    </Form.Item>
                    <h4 className="m-0">Recommended Mapping</h4>
                    <p className="text-blad mb-24 mt-8">
                      Looks like you’re using a .xlsx file with multiple sheets.
                      please select the sheet you want to use for the data
                      upload
                    </p>
                    <Form.Item
                      label="Based on this, here's what we recommend:"
                      name="industry"
                      rules={[
                        {
                          required: true,
                          message: "Required sheet",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Choose my own organizational management category"
                        prefix={<ApartmentOutlined />}
                        suffix={
                          <CheckCircleFilled style={{ color: "#F48E2F" }} />
                        }
                      />
                    </Form.Item>
                    <h4 className="m-0">Footprint Activity</h4>
                    <p className="text-blad mb-24 mt-8">
                      Sorry, we're unable to determine the footprint activity
                      you're trying to upload. Please select the right Footprint
                      Activity information below to initiate Data Mapping.
                    </p>
                    <Form.Item
                      label="Based on this, here's what we recommend:"
                      name="industry"
                      rules={[
                        {
                          required: true,
                          message: "Required sheet",
                        },
                      ]}
                    >
                      <Row gutter={[16, 24]} className="checkbox-round">
                        <Col md={6}>
                          <Checkbox>Account Export</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>Activity Date</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>Activity Date - Custom EF</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>Audit Reports</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>External Calculation</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>Organizational Data</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>Transaction Export</Checkbox>
                        </Col>
                        <Col md={6}>
                          <Checkbox>User Management</Checkbox>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </div>
                <div
                  className="upload-footer"
                  style={{ paddingTop: 16, borderTop: "1px solid #DEE0E3" }}
                >
                  <Button className="btn-h-40 fw-500" shape="round">
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    className="btn-h-40 fw-500"
                    onClick={() => setFileUploaded(false)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>

      <Drawer
        className="footprintDrawer"
        title={<h4 className="m-0 pl-3">Activity data template</h4>}
        onClose={onCloseTemplate}
        open={templateDrawer}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onCloseTemplate}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <div className="activity-row">
            <div className="activity-body">
              <div className="mt-0">
                <Form.Item
                  label="Search Templates"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Required organization",
                    },
                  ]}
                >
                  <Select placeholder="Search" options={options} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="px-40">
            <div className="collapse-panel data-template">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={
                  ({ isActive }) =>
                    isActive ? <MinusOutlined /> : <PlusOutlined />
                  // <CaretRightOutlined rotate={isActive ? 90 : 0} />
                }
                items={getItems()}
                expandIconPosition="end"
              />
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default Fileupload;
