import React, { useState } from "react";
import { Row, Col, Form, Input, Space, Button, Card, Spin } from "antd";
import {
  InfoCircleFilled,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../config";
import { saveTaxInfo } from "../../redux/slices/taxInfoSlice";
import { saveBankInfo } from "../../redux/slices/bankInfoSlice";
import CountriesSelect from "../../sharedModules/verification/CountriesSelect";
import CitiesSelect from "../../sharedModules/verification/CitiesSelect";

const CompanyInformation = ({ current, onNext, onPrev }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.bankInfo);
  const [errorMsg, setErrorMsg] = useState(null);
  const [files, setFiles] = useState({
    companyIdImage: null,
    taxDocument: null,
    otherImage: null,
  });
  const [countryId, setCountryId] = useState(null);

  const handleFileChange = (e, field) => {
    console.log("files ", e.target.files?.[0]);
    if (e.target.files[0]) {
      setFiles({
        ...files,
        [field]: e.target.files[0],
      });
      form.setFieldsValue({
        [field]: e.target.files[0],
      });
    }
  };

  const onFinish = (values) => {
    // Integrate with API here
    const taxUrl = `${API_BASE_URL}/v1/profile-verfication/registration-tax/`;
    const bankUrl = `${API_BASE_URL}/v1/profile-verfication/bank-information`;

    dispatch(saveTaxInfo({ url: taxUrl, raw: { ...values, files } })).then(
      (action) => {
        if (saveTaxInfo.fulfilled.match(action)) {
          onNext();
          localStorage.setItem("companyInfo", JSON.stringify(values));
        } else {
          setErrorMsg(
            typeof action.payload === "string"
              ? action.payload
              : "Something went wrong, please try again."
          );
          console.log("Error:", action);
        }
        if (saveTaxInfo.rejected.match(action)) {
          console.log("action ", action);
        }
      }
    );

    dispatch(saveBankInfo({ url: bankUrl, raw: values })).then((action) => {
      if (saveBankInfo.fulfilled.match(action)) {
        onNext();
        localStorage.setItem("companyInfo", JSON.stringify(values));
      } else {
        setErrorMsg(
          typeof action.payload === "string"
            ? action.payload
            : "Something went wrong, please try again."
        );
        console.log("Error:", action);
      }
      if (saveBankInfo.rejected.match(action)) {
        console.log("action ", action);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errorInfo) => {
        onFinishFailed(errorInfo);
      });
  };

  return (
    <>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Company registration and tax</h3>
          <p className="mt-6 mb-0 text-black-60">
            Provide legal documentation and information to verify your business.
          </p>
        </div>
        <div className="card-body">
          <Form layout="vertical" form={form} className="mt-24">
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Form.Item
                  label="Company ID image"
                  name="companyIdImage"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      overflow: "hidden",
                      borderRadius: "16px",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <input
                      id="companyIdImageInput"
                      type="file"
                      name="companyIdImage"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "companyIdImage")}
                    />
                    <Input
                      value={
                        files.companyIdImage ? files.companyIdImage.name : ""
                      }
                      name="companyIdImage"
                      placeholder="pdf or jpeg; 10 mb"
                      readOnly
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    />
                    <Button
                      onClick={() =>
                        document.getElementById("companyIdImageInput").click()
                      }
                      style={{
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Upload
                    </Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Tax number"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                    },
                  }}
                  name="taxNumber"
                  rules={[{ required: true, message: "Required tax number" }]}
                >
                  <Input placeholder="eg: 483765837536481" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Tax document"
                  name="taxDocument"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      overflow: "hidden",
                      borderRadius: "16px",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <input
                      id="taxDocumentInput"
                      name="taxDocument"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "taxDocument")}
                    />
                    <Input
                      value={files.taxDocument ? files.taxDocument.name : ""}
                      name="taxDocument"
                      placeholder="pdf or jpeg; 10 mb"
                      readOnly
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    />
                    <Button
                      onClick={() =>
                        document.getElementById("taxDocumentInput").click()
                      }
                      style={{
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Upload
                    </Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Others"
                  name="otherImage"
                  tooltip={{
                    title:
                      "Company ID or any similar document is required to substantiate your role and designation.",
                    icon: (
                      <InfoCircleFilled
                        style={{ color: "rgb(10, 15, 41, .25)" }}
                      />
                    ),
                    color: "#fff",
                    overlayInnerStyle: {
                      color: "#000",
                      fontSize: "12px",
                      width: "315px",
                      boxShadow: "0px 10px 16px -3px rgba(20,21,26,0.05)",
                      overflow: "hidden",
                      borderRadius: "16px",
                    },
                  }}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <input
                      id="otherImageInput"
                      type="file"
                      name="otherImage"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, "otherImage")}
                    />
                    <Input
                      value={files.otherImage ? files.otherImage.name : ""}
                      name="otherImage"
                      placeholder="pdf or jpeg; 10 mb"
                      readOnly
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    />
                    <Button
                      onClick={() =>
                        document.getElementById("otherImageInput").click()
                      }
                      style={{
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Upload
                    </Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <Card style={{ marginTop: 24 }}>
        <div className="card-header">
          <h3 className="m-0">Bank account information</h3>
          <p className="mt-6 mb-0 text-black-60">
            Provide your banking information to make digital transactions.
          </p>
        </div>
        <div className="card-body">
          <Form layout="vertical" form={form} className="mt-24">
            <Row gutter={[16, 0]}>
              <Col md={12}>
                <Form.Item
                  label="IBAN"
                  name="iban"
                  rules={[{ required: true, message: "Required IBAN" }]}
                >
                  <Input placeholder="eg: 483765837536481" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Account title" name="accountTitle">
                  <Input placeholder="eg. Ahya Technologies" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Bank name"
                  name="bankName"
                  rules={[{ required: true, message: "Required bank name" }]}
                >
                  <Input placeholder="eg. Emirates NBD" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label="Branch location"
                  name="branchLocation"
                  rules={[
                    { required: true, message: "Required branch location" },
                  ]}
                >
                  <Input placeholder="eg. Group Head Office" />
                </Form.Item>
              </Col>
              <Col md={12}>
                {/* <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: "Required country" }]}
                >
                  <Select
                    placeholder="eg. United Arab Emirates"
                    options={options}
                  />
                </Form.Item> */}
                <CountriesSelect setCountryId={setCountryId} />
              </Col>
              <Col md={12}>
                {/* <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "Required city" }]}
                >
                  <Input placeholder="eg. Dubai" />
                </Form.Item> */}
                <CitiesSelect countryId={countryId} filterByCountry={true} />
              </Col>

              <Col md={24}>
                {errorMsg && (
                  <p
                    style={{
                      color: "#FF4D4F",
                      marginTop: "-5px",
                    }}
                  >
                    {errorMsg}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Card>

      <div
        style={{ marginTop: 24 }}
        className={!current > 0 ? "flex-center-end" : "flex-center-between"}
      >
        {current > 0 && (
          <Button
            shape="round"
            icon={<ArrowLeftOutlined />}
            onClick={onPrev}
            size="large"
          >
            Back
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
          size="large"
          onClick={handleNext}
          disabled={loading}
        >
          Continue
          {loading && <Spin tip="Loading" size="small" />}
        </Button>
      </div>
    </>
  );
};

export default CompanyInformation;
