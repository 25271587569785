import React, { useState } from "react";
import {
  Button,
  Select,
  Space,
  Tag,
  Table,
  Drawer,
  Row,
  Col,
  Form,
} from "antd";
import {
  EditOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

const ReportingPreferences = () => {
  const [form] = Form.useForm();
  const [drawerTitle, setDrawerTitle] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const column = [
    {
      title: "",
      dataIndex: "reportingYear",
      key: "title",
      render: (_, record) => {
        return (
          <>
            <h5 className="mt-0 mb-2 fs-18 ff-mont fw-700">{record.title}</h5>
            <p className="m-0 fs-14">{record.description}</p>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "control",
      key: "control",
      render: (_, record) => {
        return (
          <>
            <Tag
              color="warning"
              style={{
                color: "#AE590A",
                fontSize: 14,
                fontWeight: 500,
                background: "#FDEAD8",
                border: 0,
                borderRadius: 6,
              }}
            >
              {record.control}
            </Tag>
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Button
            type="text"
            icon={<EditOutlined />}
            iconPosition="start"
            onClick={() => {
              setDrawerTitle(record.title);
              setOpenDrawer(true);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      title: "Organizational Boundary",
      description:
        "Setting organizational boundaries is an approach selected by a company to determine what type of ownership they take of their businesses and operations. The selected organizational boundary determines the percentage of emissions a reporting organization is responsible for in its GHG Inventory.",
      control: "Operational Control",
    },
    {
      key: "1",
      title: "Scope 2 Display Preference",
      description:
        "Location-Based reflects the average emissions intensity of grids on which energy consumption occurs, where on-site and direct-line sources are reflected. Market-Based reflects emissions from the electricity that companies may have chosen purposefully or through a lack of choice, typically supported by contractual agreements.",
      control: "Location Based",
    },
    {
      key: "1",
      title: "Fallback GWP Set",
      description:
        "AhyaOS recommends using the GWP Set preference on each reporting year to consistently apply a single GWP to your inventory. This fallback setting will only be used for activities created outside reporting years, or for activities created in date ranges where overlapping reporting years have conflicting GWP Sets.",
      control: "IPCC Fourth Assessment Report (AR4)",
    },
  ];

  const onClose = () => {
    setOpenDrawer(false);
  };

  const optionsAS = [
    { value: "yes", label: "IPCC Fourth Assessment Report (AR4)" },
  ];
  return (
    <>
      <div
        className="adapt-height hide-tableHeader"
        style={{ height: "calc(100vh - 330px)" }}
      >
        <Table dataSource={data} columns={column} pagination={false} />
      </div>
      <Drawer
        className="footprintDrawer"
        title={<>Edit {drawerTitle}</>}
        onClose={onClose}
        open={openDrawer}
        width={675}
        closable={false}
        extra={
          <Space>
            <Button type="text" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                shape="round"
                className="btn-h-40 fw-500"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                className="btn-h-40"
                onClick={onClose}
              >
                Add
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="activity-row">
          <div className="activity-body">
            <Form layout="vertical" form={form}>
              <Form.Item
                label="Select"
                name="fName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select placeholder="Please Select" options={optionsAS} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ReportingPreferences;
