import React, { useState } from "react";
import {
  Row,
  Col,
  Flex,
  Input,
  Dropdown,
  Layout,
  Button,
  Tabs,
  Table,
  Empty,
  Card,
} from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import {
  SearchOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import leadIcon from "../../../images/leadIcon.svg";
import connectIcon from "../../../images/connectIcon.svg";
import IntegrationCard from "./IntegrationCard";
import Search from "../../../images/search.svg";
import logo1 from "../../../images/Measure-Integrationhub-cards/logo1.svg"
import logo2 from "../../../images/Measure-Integrationhub-cards/logo2.svg"
import logo3 from "../../../images/Measure-Integrationhub-cards/logo3.svg"
import logo4 from "../../../images/Measure-Integrationhub-cards/logo4.svg"
import logo5 from "../../../images/Measure-Integrationhub-cards/logo5.svg"


const { Header } = Layout;
const { TabPane } = Tabs;

const IntegrationHub = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [activeTab, setActiveTab] = useState("1");
  const columns = [
    {
      title: "Integration Name",
      dataIndex: "integrationName",
      key: "integrationName",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
    },
    {
      title: "Footprint Source",
      dataIndex: "footprintSource",
      key: "footprintSource",
    },
    {
      title: "Connection",
      dataIndex: "connection",
      key: "connection",
    },
    {
      title: "Last Task",
      dataIndex: "lastTask",
      key: "lastTask",
    },
    {
      title: "Last Task Status",
      dataIndex: "lastTaskStatus",
      key: "lastTaskStatus",
    },
  ];
  const items = [
    {
      label: "Test",
      key: "0",
    },
  ];

  const IntegrationCardData = [
    { logo: logo1 , heading: 'Purchased Goods & Services', subtitle: 'AWS', paragraph: 'Automate the collection of your organization’s relevant cloud computing usage information from AWS.' },
    { logo: logo2, heading: 'Commercial Air Travel', subtitle: 'Concor', paragraph: 'Automate the collection of your organization’s employee flight expenses from Concur.' },
    { logo: logo3, heading: 'Hotel Stay', subtitle: 'Concor', paragraph: 'Automate the collection of your organization’s employee hotel stay expenses from Concur.' },
    { logo: logo4, heading: 'Commercial Air Travel', subtitle: 'Expensify', paragraph: 'Automate the collection of your flight expenses from Expensify and the calculation of your footprint from commercial air travel.' },
    { logo: logo5, heading: 'SAS Technologies', subtitle: 'Concor', paragraph: 'Eliminate manual data gathering with our automated processes, saving time and reducing errors.' },
  ]


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const data = [];

  const goToBrowse = () => {
    setActiveKey("3");
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Integration Hub </h4>
      </Header>

      <div className="main-content">
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeKey}
          onChange={setActiveKey}
        >
          <TabPane tab="My Integrations" key="1">
            <Row justify="space-between" alignItems="center" gutter={[16]}>
              <Col md={8}>
                <Input
                  placeholder="Search"
                  prefix={<img src={Search} />}
                  style={{ borderRadius: 25 }}
                />
              </Col>
              <Col md={8}>
                <Flex align="center" justify="end" gap={12}>
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <Button
                      shape="round"
                      icon={<DownOutlined />}
                      iconPosition="end"
                      className="btn-h-40 fw-500 text-black"
                    >
                      Application
                    </Button>
                  </Dropdown>
                </Flex>
              </Col>
            </Row>
            <div
              className="adapt-height mt-24"
              style={{ height: "calc(100vh - 270px)" }}
            >
              <Table
                dataSource={data}
                locale={{ emptyText: <CustomEmpty goToBrowse={goToBrowse} /> }}
                pagination={{
                  pageSize: 10,
                  position: ["bottomCenter"],
                  itemRender: (current, type, originalElement) => {
                    if (type === "prev") {
                      return <ArrowLeftOutlined />;
                    }
                    if (type === "next") {
                      return <ArrowRightOutlined />;
                    }
                    return originalElement;
                  },
                }}
                // rowSelection={rowSelection}
                columns={columns}
              ></Table>
            </div>
          </TabPane>
          <TabPane tab="My Connections" key="2">
            <div
              className="adapt-height flex-center-center"
              style={{ height: "calc(100vh - 270px)", background: "#fff" }}
            >
              <CustomEmptyConnection />
            </div>
          </TabPane>
          <TabPane tab="Browse Integration" key="3">
          <Row gutter={[20, 24]} className="mt-8">
          {IntegrationCardData.map((card, index) => (
            <Col md={6} key={index}>
              <IntegrationCard
                logo={card.logo}
                heading={card.heading}
                subtitle={card.subtitle}
                paragraph={card.paragraph}
              />
            </Col>
          ))}
        </Row>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};



const CustomEmpty = ({ goToBrowse }) => {
  const goToTab = () => {
    if (goToBrowse) {
      goToBrowse();
    }
  };
  return (
    <Empty
      description={
        <div className="text-center">
          <img src={leadIcon} />
          <p className="m-0 text-black fw-500">No Integrations</p>
          <p className="m-0 text-black-60">
            You do not have any integrations currently
          </p>
          <Button
            type="primary"
            shape="round"
            className="btn-h-40 mt-2"
            onClick={goToTab}
          >
            Browse Integration
          </Button>
        </div>
      }
    />
  );
};

const CustomEmptyConnection = () => {
  return (
    <Empty
      description={
        <div className="text-center">
          <img src={connectIcon} />
          <p className="m-0 text-black fw-500">No Connections</p>
          <p className="m-0 text-black-60">
            You do not have any integrations currently
          </p>
          <Button type="primary" shape="round" className="btn-h-40 mt-2">
            Add Connection
          </Button>
        </div>
      }
    />
  );
};

export default IntegrationHub;
