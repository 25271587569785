import React, { useState } from "react";
import { Layout } from "antd";
import Layouts from "../../../sharedModules/defaultLayout";
import InnerSidebar from "./InnerSidebar";

const { Header, Content, Sider } = Layout;

const CatalogLayout = (props) => {
  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Catalogs</h4>
      </Header>

      <div className="main-content">
        <Layout>
          <Sider width={380} style={{ background: "transparent" }}>
            <InnerSidebar />
          </Sider>
          <Layout style={{ marginLeft: 20 }}>
            <Content
              style={{ padding: "0", display: "flex", flexDirection: "column" }}
            >
              <div className="upload-section">
                <div className="upload-body">{props.children}</div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default CatalogLayout;
