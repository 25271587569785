import React, { useState } from "react";
import {
  Button,
  Tag,
  Dropdown,
  Modal,
  Flex,
  Form,
  Input,
  Divider,
  Checkbox,
} from "antd";
import {
  EllipsisOutlined,
  ClockCircleOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

const ScopeRow = ({ DescDrawer, ScopeRowCard }) => {
  const [openAddMember, setOpenAddMember] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [noUser, setNoUser] = useState(true);
  const items = [
    {
      label: "Mark as complete",
      key: "0",
    },
    {
      label: "Remove from checklist",
      key: "1",
    },
    {
      label: "Assign footprint source",
      key: "2",
    },
  ];
  const descClick = () => {
    if (DescDrawer) {
      DescDrawer();
    }
  };

  const showMemberModal = () => {
    setOpenAddMember(true);
  };
  const handleOk = () => {
    setOpenAddMember(false);
  };
  const handleCancel = () => {
    setOpenAddMember(false);
  };

  const onClickMenu = ({ key }) => {
    if (key == 2) {
      showMemberModal();
    }
  };

  const searchUser = (e) => {
    if (e.target.value === "j") {
      setShowSuggestion(true);
    } else {
      setShowSuggestion(false);
    }
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      setNoUser(false);
    } else {
      setNoUser(true);
    }
  };

  return (
    <>
      <div className="scopeList" key={ScopeRowCard?.id}>
        <div className="scope-col desc cursor-pointer" onClick={descClick}>
          <p className="m-0 fw-500"> {ScopeRowCard?.label} </p>
          <p className="text-black-60 fs-12 m-0">{ScopeRowCard?.category}</p>
        </div>
        <div className="scope-col chm">
          <p className="m-0 fw-500">{ScopeRowCard?.checmicalVal}</p>
          <p className="text-black-60 fs-12 m-0">{ScopeRowCard?.checmical}</p>
        </div>
        <div className="scope-col req">
          <p className="m-0 fw-500">{ScopeRowCard?.recordsVal}</p>
          <p className="text-black-60 fs-12 m-0">{ScopeRowCard?.records}</p>
        </div>
        <div className="scope-col req">
          <p className="m-0 fw-500">{ScopeRowCard?.requestVal}</p>
          <p className="text-black-60 fs-12 m-0">{ScopeRowCard?.request}</p>
        </div>
        <div className="scope-col status">
          <Tag
            color="warning"
            style={{
              color: "#AE590A",
              fontSize: 12,
              fontWeight: 500,
              background: "#FDEAD8",
              border: 0,
              borderRadius: 6,
            }}
          >
            <ClockCircleOutlined style={{ marginRight: 3 }} /> In progress
          </Tag>
        </div>
        <div className="scope-col req">
          <Tag
            color="error"
            style={{
              color: "#9A1C13",
              fontSize: 12,
              fontWeight: 500,
              background: "#FCE5E4",
              border: 0,
              borderRadius: 6,
            }}
          >
            Unassigned
          </Tag>
        </div>
        <div className="scope-col action">
          <Dropdown menu={{ items, onClick: onClickMenu }} trigger={["click"]}>
            <EllipsisOutlined style={{ fontSize: "16px" }} />
          </Dropdown>
        </div>
      </div>

      <Modal
        open={openAddMember}
        title={
          <p style={{ fontSize: 18 }} className="m-0 fw-500">
            Assign footprint source
          </p>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        footer={(_) => (
          <Flex
            justify="space-between"
            align="center"
            gap="middle"
            style={{
              paddingTop: 24,
              borderTop: "1px solid rgba(5, 5, 5, 0.06)",
            }}
          >
            <Button type="gray" onClick={handleCancel} shape="round">
              Cancel
            </Button>
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              iconPosition="end"
              size="default"
              shape="round"
              disabled={noUser}
              onClick={handleCancel}
            >
              Assign User
            </Button>
          </Flex>
        )}
      >
        <Form layout="vertical">
          <Form.Item
            label="Select an assignee"
            name="assignee"
            extra="Search by name or email address"
          >
            <Input
              placeholder="Search for a user"
              prefix={<UserOutlined />}
              onChange={searchUser}
            />
          </Form.Item>
          {showSuggestion ? (
            <div className="result-sugg">
              <Divider />
              <p className="m-0">Results</p>
            </div>
          ) : (
            ""
          )}

          {showSuggestion ? (
            <>
              <ul className="assignee-list">
                <li>
                  <Checkbox onChange={onChangeCheckbox}>
                    <p className="m-0 fw-500">John Doe</p>
                    <p className="m-0 fs-14 text-black-60">
                      jack@acmecorporation.com
                    </p>
                  </Checkbox>
                  <p className="m-0 fw-500">Admin</p>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ScopeRow;
