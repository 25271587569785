import React, { useState } from "react";
import {
  Layout,
  Button,
  Modal,
  Space,
  Dropdown,
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Flex,
  Radio,
  List,
  Select,
  DatePicker,
  Form,
  Table,
} from "antd";
import {
  EllipsisOutlined,
  DownloadOutlined,
  SyncOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import BarChart from "../../../sharedModules/charts/BarChart";
import LineChart from "../../../sharedModules/charts/LineChart";
import BubbleChart from "../../../sharedModules/charts/BubbleChart";

const { Header } = Layout;
const ValueAnalysis = () => {
  const dataBar = {
    labels: [
      "Category 1 - (Purchased goods and services)",
      "Category 9 - (Downstream transportation and distribution)",
      "Category 4 - (Upstream transportation and distribution)",
      "Category 14 - (Franchises)",
      "Category 9 - (Downstream transportation and distribution)",
      "Category 2 - (Capital goods)",
      "Category 6 - (Business travel)",
      "Category 10 - (Processing of sold products)",
      "Category 5 - (Waste generated in operations)",
      "Category 13 - (Downstream leased assets)",
      "Category 11 - (Use of sold products)",
      "Category 12 - (End-of-life-treatment of sold products)",
      "Category 7 - (Employee commuting)",
      "Category 3 - (Fuel and energy related activities)",
    ],
    datasets: [
      {
        label: "Scope 1",
        data: [3.01, 3, 1, 1, 0.9, 0.5, 0.6, 0.7, 0.8, 0.7, 0.6, 0.7, 0.5, 0.4],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 38,
        stack: "combined",
        type: "bar",
        yAxisID: "y",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Dataset 2",
        data: [
          0.63, 0.625, 0.3, 0.3, 0.3, 0.2, 0.21, 0.23, 0.27, 0.25, 0.22, 0.23,
          0.21, 0.14,
        ],
        borderColor: "#000000",
        stack: "combined",
        pointStyle: "circle",
        pointRadius: 4,
        yAxisID: "y1",
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: true,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBar1 = {
    labels: ["Apex material", "distributor", "Ecogo ods supply"],
    datasets: [
      {
        label: "Scope 1",
        data: [3.5, 3.25, 0.4],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 68,
        stack: "combined",
        type: "bar",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Dataset 2",
        data: [0.2, 0.4, 0.14],
        borderColor: "#000000",
        stack: "combined",
        pointStyle: "circle",
        pointRadius: 4,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: true,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };
  const dataBar2 = {
    labels: ["Courier", "Freight Transportation"],
    datasets: [
      {
        label: "Scope 1",
        data: [3.5, 3.25],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 68,
        stack: "combined",
        type: "bar",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Dataset 2",
        data: [1.25, 0.9],
        borderColor: "#000000",
        stack: "combined",
        pointStyle: "circle",
        pointRadius: 4,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: true,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };

  const dataBar4 = {
    labels: ["Jeans", "Shirt"],
    datasets: [
      {
        label: "Scope 1",
        data: [5.4, 4.6],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 68,
        stack: "combined",
        type: "bar",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Dataset 2",
        data: [1.85, 0.9],
        borderColor: "#000000",
        stack: "combined",
        pointStyle: "circle",
        pointRadius: 4,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: true,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };

  const dataBar3 = {
    labels: ["Jeans", "Shirt"],
    datasets: [
      {
        label: "Scope 1",
        data: [4.2, 1.25],
        backgroundColor: "#3581A8",
        borderWidth: 1,
        borderColor: "#fff",
        borderSkipped: false,
        barThickness: 68,
        stack: "combined",
        type: "bar",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Dataset 2",
        data: [1.85, 0.9],
        borderColor: "#000000",
        stack: "combined",
        pointStyle: "circle",
        pointRadius: 4,
        datalabels: {
          color: "#000",
          anchor: "end",
          align: "top",
          display: true,
          formatter: (value, context) => {
            // data[0].dataset.label
            return `${value}`;
          },
          font: {
            weight: "500",
            family: "'Montserrat', sans-serif",
            size: 12,
          },
        },
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 6,
          topRight: 6,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    },
    scales: {
      x: {
        display: true,
        stacked: false,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "#000",
          font: {
            size: 10,
            weight: "500",
            family: "Montserrat",
            color: "#000",
          },
        },
      },
      y: {
        stacked: true,
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Total GHG Emissions (tCO2e)",
          color: "#14151a",
          font: {
            size: 14,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Subcategory Emission (%)",
          color: "#14151a",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "400",
          },
        },

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        display: true,
        font: {
          weight: "600",
          family: "'Montserrat', sans-serif",
          size: 10,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: true,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "#14151A",
        bodyColor: "#14151A",
        borderColor: "white",
        borderWidth: 1,
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        titleFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
          weight: "bold",
        },
        bodyFont: {
          family: "'Montserrat', sans-serif",
          size: 12,
        },
        callbacks: {
          // Customize the tooltip label to include a prefix and suffix
          label: function (data) {
            return data.formattedValue + " tCO₂e";
          },
          title: function (data) {
            return data[0].dataset.label;
          },
        },
      },
      // tooltip: false,
    },
  };

  const data = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [2.28, 2.28, 2.029, 1.968, 1.967, 2.032],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };
  const dataSupplier = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [900, 816, 768, 732, 672, 648],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const dataLogistics = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [852, 756, 756, 732, 816, 852],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };
  const dataProduct = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [120, 120, 108, 84, 96, 108],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const dataFranchise = {
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Year",
        data: [132, 133, 133, 138, 143, 148],
        borderColor: "#7A884E",
        pointStyle: "circle",
        pointRadius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        title: {
          display: true,
          text: "Year",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        display: true,
        title: {
          display: false,
          text: "Total GHG Emissions (tCO2e)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        border: {
          dash: [8, 4],
        },
        ticks: {
          beginAtZero: true,
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      datalabels: {
        color: "white",
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: false,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };

  const dataBubble = {
    datasets: [
      {
        label: "UAE Warehouse",
        data: [
          { x: 10, y: 20 },
          { x: 15, y: 10 },
          { x: 20, y: 30 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon1)}`;
          return image;
        },
        backgroundColor: "#4778F5",
        borderColor: "#4778F5",
        borderWidth: 0,
      },
      {
        label: "KSA Headquarters",
        data: [
          { x: 12, y: 22 },
          { x: 18, y: 12 },
          { x: 22, y: 32 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon2)}`;
          return image;
        },
        backgroundColor: "#26BD6C",
        borderColor: "#26BD6C",
        borderWidth: 0,
      },
      {
        label: "UAE Factory",
        data: [
          { x: 14, y: 24 },
          { x: 19, y: 14 },
          { x: 24, y: 34 },
        ],
        pointStyle: () => {
          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgIcon3)}`;
          return image;
        },
        backgroundColor: "#EB632C",
        borderColor: "#EB632C",
        borderWidth: 0,
      },
    ],
  };

  const svgIcon1 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#4778F5"/></svg>`;
  const svgIcon2 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#26BD6C"/></svg>`;
  const svgIcon3 = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L11.1962 9H0.803848L6 0Z" fill="#EB632C"/></svg>`;

  const optionsBubble = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
        title: {
          display: true,
          text: "Year",
          color: "#000",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Emissions Intensity (tCO2e / SF)",
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 14,
            family: "Montserrat",
            weight: "500",
          },
        },
        grid: {
          display: true, // Disable grid lines on the x-axis
        },
        ticks: {
          color: "rgba(15, 19, 36, 0.6)",
          font: {
            size: 12,
            family: "Montserrat",
          },
        },
      },
    },
    title: {
      display: true,
      text: "",
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            alert(JSON.stringify(context.dataset.label));
            return `(${context.raw.x}, ${context.raw.y})`;
          },
        },
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
          tooltipEl.classList.remove("below", "no-transform");

          // Set HTML & Data
          if (tooltipModel.body) {
            const dataFromCurrentElement = tooltipModel.dataPoints[0];
            const currentElement = dataFromCurrentElement.dataIndex;
            const formattedValue = dataFromCurrentElement.formattedValue.trim();
            const currentDataToShow = formattedValue
              .substr(1, formattedValue.length - 2)
              .split(" ");
            const innerHtml = `
              <div style="border-collapse: separate; overflow: hidden; border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">
                <div style="display: flex; padding: 1.2rem; background-color: #FDEAD8">
                    <div style="display: flex;  flex-direction: column;  font-family: 'Montserrat'; font-size: 12px">
                      <div style="display: flex;justify-content: space-between; gap: 10px">Facility: <span style="font-weight: 600">${
                        dataFromCurrentElement.dataset.label
                      }</span></div>
                      <div style="display: flex;justify-content: space-between; gap: 10px">Total Emissions (tonnes of CO2e): <span style="font-weight: 600">${currentDataToShow[0].substr(
                        0,
                        currentDataToShow[0].length - 1
                      )}</span></div>
                      <div style="display: flex;justify-content: space-between; gap: 10px">Emission Intensity (tCO2e / SF):  <span style="font-weight: 600">${
                        currentDataToShow[1]
                      }</span></div>
                    </div>
                </div>
              </div>`;
            tooltipEl.querySelector("table").innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        display: true,
        formatter: (value, context) => {
          // data[0].dataset.label
          return `${context.dataset.label}`;
        },
        font: {
          weight: "500",
          family: "'Montserrat', sans-serif",
          size: 12,
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          padding: 50,
          color: "#14151a",
          usePointStyle: false,
          pointStyleWidth: 16,
          font: {
            size: 12,
            family: "'Montserrat', sans-serif",
            weight: 500,
          },
        },
      },
    },
  };

  const radioValue = [
    {
      id: "intensityMetrics",
      title: "Scope 3 overview",
    },
    {
      id: "emissionsIntensity",
      title: "Supplier analysis",
    },
    {
      id: "energyIntensity",
      title: "Logistics analysis",
    },
    {
      id: "emissionsOvertime",
      title: "Product analysis",
    },
    {
      id: "energyOvertime",
      title: "Franchise analysis",
    },
  ];
  const [scopeValue, setScopeValue] = useState(radioValue[0].id);
  const [cautionText, setCautionText] = useState(
    "Value Chain emissions include all scope 3 categories from 1 to 14 and exclude category 15 (Financed Emissions)"
  );
  const onChangeScope = (e) => {
    setScopeValue(e.target.value);
    if (e.target.value === "emissionsIntensity") {
      setCautionText(
        "Supplier emissions are taken as the sum of Scope 3 - Category 1; Purchased goods and services and Scope 3 - Category 2; Capital goods."
      );
    }
    if (e.target.value === "energyIntensity") {
      setCautionText(
        "Logistics emissions are taken as a sum of Scope 3 - Category 4; Upstream Transportation & Distribution and Scope 3 - Category 9; Downstream Transportation & Distribution"
      );
    }
    if (e.target.value === "emissionsOvertime") {
      setCautionText(
        "Product emissions are taken as a sum of Scope 3 - Category 10; Processing of sold products. Scope 3 - Category 11; Use of sold products & Scope 3 - Category 12: End of life treatment of sold products"
      );
    }
    if (e.target.value === "energyOvertime") {
      setCautionText(
        "Franchise emissions are taken as the total of Scope 3 - Category 14; Franchises (as input from the activity source checklist)."
      );
    }
    if (e.target.value === "intensityMetrics") {
      setCautionText(
        "Value Chain emissions include all scope 3 categories from 1 to 14 and exclude category 15 (Financed Emissions)"
      );
    }
  };

  // FOR SCOPE 3 OVERVIEW
  const columns = [
    {
      title: "Scope 3 Category",
      dataIndex: "scope3Category",
      key: "scope3Category",
      width: 970,
      sorter: (a, b) => a.scope3Category - b.scope3Category,
    },
    {
      title: "Total GHG Emissions tCO2e",
      dataIndex: "totalGHG",
      key: "totalGHG",
      width: 320,
      sorter: (a, b) => a.totalGHG - b.totalGHG,
    },
    {
      title: "% of  Value Chain Emissions",
      dataIndex: "chainEmissions",
      key: "chainEmissions",
      width: 250,
      sorter: (a, b) => a.chainEmissions - b.chainEmissions,
    },
  ];
  const dataSource = [
    {
      key: "1",
      scope3Category: "Category 1 - Purchased goods and services",
      totalGHG: 4128,
      chainEmissions: 33.34,
      children: [
        {
          key: "1_1",
          scope3Category: "Average data based",
          totalGHG: 4128,
          chainEmissions: 33.34,
        },
        {
          key: "1_2",
          scope3Category: "Spend based",
          totalGHG: 4128,
          chainEmissions: 33.34,
        },
      ],
    },
    {
      key: "2",
      scope3Category: "Category 2 - Capital goods",
      totalGHG: 3756,
      chainEmissions: 3.29,
      children: [
        {
          key: "2_1",
          scope3Category: "Average data based",
          totalGHG: 3756,
          chainEmissions: 3.29,
        },
        {
          key: "2_2",
          scope3Category: "Spend based",
          totalGHG: 3756,
          chainEmissions: 3.29,
        },
      ],
    },
    {
      key: "3",
      scope3Category: "Category 3 - Fuel and energy related activities",
      totalGHG: 1044,
      chainEmissions: 0.87,
      children: [
        {
          key: "3_1",
          scope3Category: "Average data based",
          totalGHG: 1044,
          chainEmissions: 0.87,
        },
      ],
    },
    {
      key: "4",
      scope3Category: "Category 4 - Upstream transportation and distribution",
      totalGHG: 827,
      chainEmissions: 8.43,
      children: [
        {
          key: "4_1",
          scope3Category: "Average data based",
          totalGHG: 827,
          chainEmissions: 8.43,
        },
      ],
    },
    {
      key: "5",
      scope3Category: "Category 5 - Waste generated in operations",
      totalGHG: 432,
      chainEmissions: 2.33,
      children: [
        {
          key: "5_1",
          scope3Category: "Average data based",
          totalGHG: 432,
          chainEmissions: 2.33,
        },
      ],
    },
    {
      key: "6",
      scope3Category: "Category 6 - Business travel",
      totalGHG: 408,
      chainEmissions: 2.91,
      children: [
        {
          key: "6_1",
          scope3Category: "Average data based",
          totalGHG: 408,
          chainEmissions: 2.91,
        },
      ],
    },
    {
      key: "7",
      scope3Category: "Category 7 - Employee commuting",
      totalGHG: 360,
      chainEmissions: 0.97,
      children: [
        {
          key: "7_1",
          scope3Category: "Average data based",
          totalGHG: 360,
          chainEmissions: 0.97,
        },
      ],
    },
    {
      key: "8",
      scope3Category: "Category 8 - Upstream leased assets",
      totalGHG: 324,
      chainEmissions: 3.48,
      children: [
        {
          key: "8_1",
          scope3Category: "Average data based",
          totalGHG: 324,
          chainEmissions: 30.33,
        },
      ],
    },
    {
      key: "9",
      scope3Category: "Category 9 - Downstream transportation and distribution",
      totalGHG: 288,
      chainEmissions: 30.33,
      children: [
        {
          key: "9_1",
          scope3Category: "Average data based",
          totalGHG: 288,
          chainEmissions: 30.33,
        },
      ],
    },
    {
      key: "10",
      scope3Category: "Category 10 - Processing of sold products",
      totalGHG: 276,
      chainEmissions: 2.62,
      children: [
        {
          key: "10_1",
          scope3Category: "Average data based",
          totalGHG: 276,
          chainEmissions: 2.62,
        },
      ],
    },
    {
      key: "11",
      scope3Category: "Category 11 - Use of sold products",
      totalGHG: 180,
      chainEmissions: 1.45,
      children: [
        {
          key: "11_1",
          scope3Category: "Average data based",
          totalGHG: 180,
          chainEmissions: 1.45,
        },
      ],
    },
    {
      key: "12",
      scope3Category: "Category 12 - End-of-life-treatment of sold products",
      totalGHG: 132,
      chainEmissions: 1.07,
      children: [
        {
          key: "12_1",
          scope3Category: "Average data based",
          totalGHG: 132,
          chainEmissions: 1.07,
        },
      ],
    },
    {
      key: "13",
      scope3Category: "Category 13 - Downstream leased assets",
      totalGHG: 120,
      chainEmissions: 2.23,
      children: [
        {
          key: "13_1",
          scope3Category: "Average data based",
          totalGHG: 120,
          chainEmissions: 2.23,
        },
      ],
    },
    {
      key: "14",
      scope3Category: "Category 14 - Franchises",
      totalGHG: 108,
      chainEmissions: 6.68,
      children: [
        {
          key: "14_1",
          scope3Category: "Average data based",
          totalGHG: 108,
          chainEmissions: 6.68,
        },
      ],
    },
  ];

  // FOR "SUPPLIER ANALYSIS"

  const columnsSupplierAnalysis = [
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      width: 150,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: 150,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 250,
    },
    {
      title: "Calculation Method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 150,
    },
    {
      title: "Emissions (tonnes of CO2e)",
      dataIndex: "totalGHG",
      key: "totalGHG",
      width: 200,
    },
    {
      title: "% of Supplier Emissions",
      dataIndex: "supplierEmissionsPercent",
      key: "supplierEmissionsPercent",
      width: 200,
    },
    {
      title: "% of Value Chain Emissions",
      dataIndex: "chainEmissions",
      key: "chainEmissions",
      width: 200,
    },
  ];

  const dataSourceSupplierAnalysis = [
    {
      key: "1",
      industry: "Textile",
      supplier: "Star distributor",
      subCategory: "Upstream",
      source: "Purchased goods and services (Category 1)",
      calculationMethod: "Average data based",
      totalGHG: 1224,
      supplierEmissionsPercent: 26.98,
      chainEmissions: 10.59,
    },
    {
      key: "2",
      industry: "Textile",
      supplier: "Star distributor",
      subCategory: "Upstream",
      source: "Capital goods (Category 2)",
      calculationMethod: "Average data based",
      totalGHG: 84,
      supplierEmissionsPercent: 1.85,
      chainEmissions: 0.73,
    },
    {
      key: "3",
      industry: "Technology",
      supplier: "Ecogoods supply",
      subCategory: "Upstream",
      source: "Capital goods (Category 2)",
      calculationMethod: "Spend based",
      totalGHG: 96,
      supplierEmissionsPercent: 2.12,
      chainEmissions: 0.83,
    },
    {
      key: "4",
      industry: "Technology",
      supplier: "Ecogoods supply",
      subCategory: "Upstream",
      source: "Capital goods (Category 2)",
      calculationMethod: "Average data based",
      totalGHG: 84,
      supplierEmissionsPercent: 1.85,
      chainEmissions: 0.73,
    },
    {
      key: "5",
      industry: "Textile",
      supplier: "Apex material",
      subCategory: "Upstream",
      source: "Capital goods (Category 2)",
      calculationMethod: "Spend based",
      totalGHG: 144,
      supplierEmissionsPercent: 3.17,
      chainEmissions: 1.25,
    },
    {
      key: "6",
      industry: "Textile",
      supplier: "Apex material",
      subCategory: "Upstream",
      source: "Purchased goods and services (Category 1)",
      calculationMethod: "Spend based",
      totalGHG: 2904,
      supplierEmissionsPercent: 64.02,
      chainEmissions: 25.13,
    },
  ];

  /// For LogisticAnalysis

  const columnsLogisticAnalysis = [
    {
      title: "Mode of Transport",
      dataIndex: "modeOfTransport",
      key: "modeOfTransport",
      width: 150,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 150,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: 150,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 250,
    },
    {
      title: "Calculation Method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 200,
    },
    {
      title: "Emissions (tonnes of CO2e)",
      dataIndex: "totalGHG",
      key: "totalGHG",
      width: 220,
      sorter: (a, b) => a.totalGHG - b.totalGHG,
    },
    {
      title: "% of Logistics Emissions",
      dataIndex: "logisticsEmissionsPercent",
      key: "logisticsEmissionsPercent",
      width: 220,
      sorter: (a, b) =>
        a.logisticsEmissionsPercent - b.logisticsEmissionsPercent,
    },
    {
      title: "% of Value Chain Emissions",
      dataIndex: "chainEmissions",
      key: "chainEmissions",
      width: 220,
      sorter: (a, b) => a.chainEmissions - b.chainEmissions,
    },
    {
      title: "Fuel Name",
      dataIndex: "fuelName",
      key: "fuelName",
      width: 220,
    },
  ];

  const dataSourceLogisticAnalysis = [
    {
      key: "1",
      modeOfTransport: "Courier",
      supplier: "Ecogoods supply",
      subCategory: "Downstream",
      source: "Downstream transportation and distribution (Category 9)",
      calculationMethod: "Distance based: weight-distance",
      totalGHG: 3756,
      logisticsEmissionsPercent: 78,
      chainEmissions: 33,
      fuelName: "Petrol (100% Mineral Petrol)", // This is displayed in the Fuel Name column
    },
    {
      key: "2",
      modeOfTransport: "Freight Transportation",
      supplier: "Ecogoods supply",
      subCategory: "Upstream",
      source: "Upstream transportation and distribution (Category 4)",
      calculationMethod: "Fuel based: Fuel type",
      totalGHG: 384,
      logisticsEmissionsPercent: 8,
      chainEmissions: 3,
      fuelName: "Gas oil", // This is displayed in the Fuel Name column
    },
    {
      key: "3",
      modeOfTransport: "Freight Transportation",
      supplier: "Ecogoods supply",
      subCategory: "Upstream",
      source: "Upstream transportation and distribution (Category 4)",
      calculationMethod: "Spend based: industry",
      totalGHG: 660,
      logisticsEmissionsPercent: 14,
      chainEmissions: 6,
      fuelName: "Gas oil", // This is displayed in the Fuel Name column
    },
  ];

  // For ProductAnalysis
  const columnsProductAnalysis = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 150,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: 150,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 250,
    },
    {
      title: "Calculation Method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 200,
    },
    {
      title: "Emissions (tonnes of CO2e)",
      dataIndex: "totalGHG",
      key: "totalGHG",
      width: 220,
      sorter: (a, b) => a.totalGHG - b.totalGHG,
    },
    {
      title: "% of Product Emissions",
      dataIndex: "productEmissionsPercent",
      key: "productEmissionsPercent",
      width: 220,
      sorter: (a, b) => a.productEmissionsPercent - b.productEmissionsPercent,
    },
    {
      title: "% of Value Chain Emissions",
      dataIndex: "chainEmissions",
      key: "chainEmissions",
      width: 220,
      sorter: (a, b) => a.chainEmissions - b.chainEmissions,
    },
  ];

  const dataProductAnalysis = [
    {
      key: "1",
      product: "Jeans",
      subCategory: "Downstream",
      source: "Processing of sold products (Category 10)",
      calculationMethod: "Site specific : Reported emissions",
      totalGHG: 324,
      productEmissionsPercent: 51,
      chainEmissions: 2.616,
    },
    {
      key: "2",
      product: "Shirt",
      subCategory: "Downstream",
      source: "Use of sold products (Category 11)",
      calculationMethod: "Products that directly consume energy",
      totalGHG: 180,
      productEmissionsPercent: 28,
      chainEmissions: 1.454,
    },
    {
      key: "3",
      product: "Jeans",
      subCategory: "Downstream",
      source: "End-of-life-treatment of sold products (Category 12)",
      calculationMethod: "Waste type: specific",
      totalGHG: 132,
      productEmissionsPercent: 21,
      chainEmissions: 1.066,
    },
  ];

  // For FranchiseAnalysis

  const columnsFranchiseAnalysis = [
    {
      title: "Organization Entity",
      dataIndex: "organizationEntity",
      key: "organizationEntity",
      width: 180,
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
      width: 150,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 150,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: 150,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 250,
    },
    {
      title: "Calculation Method",
      dataIndex: "calculationMethod",
      key: "calculationMethod",
      width: 200,
    },
    {
      title: "Emissions (tonnes of CO2e)",
      dataIndex: "totalGHG",
      key: "totalGHG",
      width: 220,
      sorter: (a, b) => a.totalGHG - b.totalGHG,
    },
    {
      title: "% of Franchise Emissions",
      dataIndex: "franchiseEmissionsPercent",
      key: "franchiseEmissionsPercent",
      width: 220,
      sorter: (a, b) =>
        a.franchiseEmissionsPercent - b.franchiseEmissionsPercent,
    },
    {
      title: "% of Value Chain Emissions",
      dataIndex: "chainEmissions",
      key: "chainEmissions",
      width: 220,
      sorter: (a, b) => a.chainEmissions - b.chainEmissions,
    },
  ];

  const dataFranchiseAnalysis = [
    {
      key: "1",
      organizationEntity: "Acme Inc",
      franchise: "Acme Downtown",
      country: "United Arab Emirates",
      subCategory: "Downstream",
      source: "Franchises (Category 14)",
      calculationMethod: "Franchise specific: Reported emissions",
      totalGHG: 382,
      franchiseEmissionsPercent: 46.19,
      chainEmissions: 0.58,
    },
    {
      key: "2",
      organizationEntity: "Acme Inc",
      franchise: "Acme Studios",
      country: "KSA",
      subCategory: "Downstream",
      source: "Franchises (Category 14)",
      calculationMethod: "Franchise specific: Reported emissions",
      totalGHG: 445,
      franchiseEmissionsPercent: 0.5,
      chainEmissions: 0.008721634,
    },
  ];

  const items = [
    {
      label: "Download Data",
      key: "0",
      icon: <DownloadOutlined />,
    },
  ];

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="m-0">Value Chain Analysis</h4>
        <Button
          shape="round"
          icon={<SyncOutlined />}
          iconPosition="end"
          className="btn-h-40 fw-500"
        >
          Clear Filters
        </Button>
      </Header>

      <div className="main-content">
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Card>
              <Form layout="vertical">
                <Row gutter={[8, 8]}>
                  <Col md={4}>
                    <Form.Item
                      label="Date start"
                      name="actStart"
                      className="m-0"
                    >
                      <DatePicker
                        placeholder="DD / MM / YYYY"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item className="m-0" label="Date end" name="actEnd">
                      <DatePicker
                        placeholder="DD / MM / YYYY"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item
                      className="m-0"
                      label="Organization"
                      name="organ"
                    >
                      <Select
                        placeholder="All"
                        options={[
                          {
                            value: "AcmeCorporation",
                            label: "Acme Corporation",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item
                      className="m-0"
                      label="Scope 3 Category"
                      name="es"
                    >
                      <Select
                        placeholder="All"
                        options={[
                          {
                            value: "feig",
                            label: "Fugitive emmissions - Industrial gases",
                          },
                          {
                            value: "fersc",
                            label:
                              "Fugitive emmissions - Refrigeration  Stationary combustion",
                          },
                          {
                            value: "mc",
                            label: "Mobile combustion",
                          },
                          {
                            value: "mc",
                            label: "Stationary combustion",
                          },
                          {
                            value: "mc",
                            label: "Purchased electricity - facility ",
                          },
                          {
                            value: "mc",
                            label: "Purchased cooling",
                          },
                          {
                            value: "mc",
                            label:
                              "Purchased electricity - Fleet onSites fueling ",
                          },
                          {
                            value: "mc",
                            label: "Purchased goods and services (Category 1)",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item className="m-0" label="Source" name="scope">
                      <Select
                        placeholder="All"
                        options={[
                          {
                            value: "Scope1",
                            label: "Scope 1",
                          },
                          {
                            value: "Scope2",
                            label: "Scope 2",
                          },
                          {
                            value: "Scope3",
                            label: "Scope 3",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item className="m-0" label="Location" name="location">
                      <Select
                        placeholder="All"
                        options={[
                          {
                            value: "KSA",
                            label: "KSA",
                          },
                          {
                            value: "UAE",
                            label: "UAE",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          <Col md={24}>
            <Card>
              <Row gutter={[16, 24]}>
                <Col md={24}>
                  <Flex justify="space-between" align="center" gap="small">
                    <div className="steps-wizard">
                      <div className="ant-form radio-round">
                        <Radio.Group
                          buttonStyle="solid"
                          size="large"
                          value={scopeValue}
                          onChange={onChangeScope}
                        >
                          {radioValue.map((val, index) => {
                            return (
                              <Radio.Button key={index} value={val.id}>
                                {val.title}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    <Flex justify="space-between" align="center" gap="small">
                      <p
                        className="m-0 text-bald fw-500 fs-12 text-right"
                        style={{ maxWidth: 600 }}
                      >
                        {cautionText}
                      </p>
                      <InfoCircleOutlined />
                    </Flex>
                  </Flex>
                  {scopeValue === "intensityMetrics" ? (
                    <Row gutter={[24, 24]} className="mt-24">
                      <Col md={24}>
                        <Card title="Value chain emissions over time (tCO₂e)">
                          <div className="graph-300 mt-24">
                            <LineChart data={data} options={options} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions by scope 3 category"
                          className="card-body-px-0 card-body-py-0"
                        >
                          <div className="p-12 mt-2" style={{ height: 400 }}>
                            <LineChart data={dataBar} options={optionsBar} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions Details"
                          className="card-body-px-0"
                          extra={
                            <>
                              <Dropdown menu={{ items }} trigger={["click"]}>
                                <Button
                                  type="text"
                                  style={{ transform: "rotate(90deg)" }}
                                >
                                  <EllipsisOutlined />
                                </Button>
                              </Dropdown>
                            </>
                          }
                        >
                          <div className="adapt-height">
                            <Table
                              dataSource={dataSource}
                              pagination={false}
                              scroll={{
                                x: 1540,
                              }}
                              columns={columns}
                              summary={(pageData) => {
                                let totalGHGSum = 0;
                                let chainEmissionsSum = 0;

                                pageData.forEach(
                                  ({ totalGHG, chainEmissions }) => {
                                    totalGHGSum += totalGHG;
                                    chainEmissionsSum += chainEmissions;
                                  }
                                );

                                return (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                      Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                      {totalGHGSum}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                      {chainEmissionsSum}%
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }}
                            ></Table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  ) : scopeValue === "emissionsIntensity" ? (
                    <Row gutter={[24, 24]} className="mt-24">
                      <Col md={12}>
                        <Card title="Supplier emissions over time (tCO₂e)">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataSupplier} options={options} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={12}>
                        <Card title="Emissions by Supplier">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataBar1} options={optionsBar} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions Details"
                          className="card-body-px-0"
                          extra={
                            <>
                              <Dropdown menu={{ items }} trigger={["click"]}>
                                <Button
                                  type="text"
                                  style={{ transform: "rotate(90deg)" }}
                                >
                                  <EllipsisOutlined />
                                </Button>
                              </Dropdown>
                            </>
                          }
                        >
                          <div className="adapt-height">
                            <Table
                              dataSource={dataSourceSupplierAnalysis}
                              pagination={false}
                              scroll={{
                                x: 1540,
                              }}
                              columns={columnsSupplierAnalysis}
                              summary={(pageData) => {
                                // Initialize totals
                                let totalGHGSum = 0;
                                let totalSupplierEmissions = 0;
                                let totalChainEmissionsSum = 0;

                                pageData.forEach(
                                  ({
                                    totalGHG,
                                    supplierEmissionsPercent,
                                    chainEmissions,
                                  }) => {
                                    totalGHGSum += parseFloat(totalGHG) || 0;
                                    totalChainEmissionsSum +=
                                      parseFloat(chainEmissions) || 0;
                                    totalSupplierEmissions +=
                                      parseFloat(supplierEmissionsPercent) || 0;
                                  }
                                );

                                // If no data, set to 0
                                totalGHGSum = totalGHGSum || 0;
                                totalChainEmissionsSum =
                                  totalChainEmissionsSum || 0;

                                // Calculate the percentage of total supplier emissions
                                const totalSupplierEmissionsPercent =
                                  totalGHGSum > 0
                                    ? (totalSupplierEmissions / totalGHGSum) *
                                      100
                                    : 0;

                                return (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                      Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={2}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={3}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={4}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                      {totalGHGSum.toFixed(2)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                      {totalSupplierEmissionsPercent.toFixed(2)}
                                      %
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                      {totalChainEmissionsSum.toFixed(2)}%
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }}
                            ></Table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  ) : scopeValue === "energyIntensity" ? (
                    <Row gutter={[24, 24]} className="mt-24">
                      <Col md={12}>
                        <Card title="Logistics emissions over time (tCO₂e)">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataLogistics} options={options} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={12}>
                        <Card title="Emissions by Mode of Transport">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataBar2} options={optionsBar} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions Details"
                          className="card-body-px-0"
                          extra={
                            <>
                              <Dropdown menu={{ items }} trigger={["click"]}>
                                <Button
                                  type="text"
                                  style={{ transform: "rotate(90deg)" }}
                                >
                                  <EllipsisOutlined />
                                </Button>
                              </Dropdown>
                            </>
                          }
                        >
                          <div className="adapt-height">
                            <Table
                              dataSource={dataSourceLogisticAnalysis}
                              pagination={false}
                              scroll={{
                                x: 1540,
                              }}
                              columns={columnsLogisticAnalysis}
                              summary={(pageData) => {
                                // Initialize totals
                                let totalGHGSum = 0;
                                let totalLogisticsEmissions = 0; // Direct sum of this column
                                let totalChainEmissionsSum = 0;

                                pageData.forEach(
                                  ({
                                    totalGHG,
                                    logisticsEmissionsPercent,
                                    chainEmissions,
                                  }) => {
                                    totalGHGSum += parseFloat(totalGHG) || 0;
                                    totalChainEmissionsSum +=
                                      parseFloat(chainEmissions) || 0;
                                    totalLogisticsEmissions +=
                                      parseFloat(logisticsEmissionsPercent) ||
                                      0;
                                  }
                                );

                                return (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                      Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={2}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={3}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={4}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                      {totalGHGSum.toFixed(2)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                      {totalLogisticsEmissions.toFixed(2)}%
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                      {totalChainEmissionsSum.toFixed(2)}%
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={8}
                                    ></Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }}
                            ></Table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  ) : scopeValue === "emissionsOvertime" ? (
                    <Row gutter={[24, 24]} className="mt-24">
                      <Col md={12}>
                        <Card title="Product emissions over time (tCO₂e)">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataProduct} options={options} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={12}>
                        <Card title="Emissions by Products">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataBar3} options={optionsBar} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions Details"
                          className="card-body-px-0"
                          extra={
                            <>
                              <Dropdown menu={{ items }} trigger={["click"]}>
                                <Button
                                  type="text"
                                  style={{ transform: "rotate(90deg)" }}
                                >
                                  <EllipsisOutlined />
                                </Button>
                              </Dropdown>
                            </>
                          }
                        >
                          <div className="adapt-height">
                            <Table
                              dataSource={dataProductAnalysis}
                              pagination={false}
                              scroll={{
                                x: 1540,
                              }}
                              columns={columnsProductAnalysis}
                              summary={(pageData) => {
                                // Initialize totals
                                let totalGHGSum = 0;
                                let totalProductEmissionsPercent = 0; // Direct sum of this column
                                let totalChainEmissionsSum = 0;

                                pageData.forEach(
                                  ({
                                    totalGHG,
                                    productEmissionsPercent,
                                    chainEmissions,
                                  }) => {
                                    totalGHGSum += parseFloat(totalGHG) || 0;
                                    totalChainEmissionsSum +=
                                      parseFloat(chainEmissions) || 0;
                                    totalProductEmissionsPercent +=
                                      parseFloat(productEmissionsPercent) || 0;
                                  }
                                );

                                return (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                      Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={2}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={3}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                      {totalGHGSum.toFixed(2)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                      {totalProductEmissionsPercent.toFixed(2)}%
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                      {totalChainEmissionsSum.toFixed(2)}%
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }}
                            ></Table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  ) : scopeValue === "energyOvertime" ? (
                    <Row gutter={[24, 24]} className="mt-24">
                      <Col md={12}>
                        <Card title="Franchise emissions over time (tCO₂e)">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataFranchise} options={options} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={12}>
                        <Card title="Emissions by Franchise">
                          <div className="graph-300 mt-24">
                            <LineChart data={dataBar4} options={optionsBar} />
                          </div>
                        </Card>
                      </Col>

                      <Col md={24}>
                        <Card
                          title="Emissions Details"
                          className="card-body-px-0"
                          extra={
                            <>
                              <Dropdown menu={{ items }} trigger={["click"]}>
                                <Button
                                  type="text"
                                  style={{ transform: "rotate(90deg)" }}
                                >
                                  <EllipsisOutlined />
                                </Button>
                              </Dropdown>
                            </>
                          }
                        >
                          <div className="adapt-height">
                            <Table
                              dataSource={dataFranchiseAnalysis}
                              pagination={false}
                              scroll={{
                                x: 1540,
                              }}
                              columns={columnsFranchiseAnalysis}
                              summary={(pageData) => {
                                // Initialize totals
                                let totalGHGSum = 0;
                                let totalFranchiseEmissionsPercent = 0; // Direct sum of this column
                                let totalChainEmissionsSum = 0;

                                pageData.forEach(
                                  ({
                                    totalGHG,
                                    franchiseEmissionsPercent,
                                    chainEmissions,
                                  }) => {
                                    totalGHGSum += parseFloat(totalGHG) || 0;
                                    totalChainEmissionsSum +=
                                      parseFloat(chainEmissions) || 0;
                                    totalFranchiseEmissionsPercent +=
                                      parseFloat(franchiseEmissionsPercent) ||
                                      0;
                                  }
                                );

                                return (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                      Total
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={1}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={2}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={3}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={4}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell
                                      index={5}
                                    ></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                      {totalGHGSum.toFixed(2)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                      {totalFranchiseEmissionsPercent.toFixed(
                                        2
                                      )}
                                      %
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                      {totalChainEmissionsSum.toFixed(2)}%
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }}
                            ></Table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ValueAnalysis;
