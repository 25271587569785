import React, { useState } from "react";
import { Tabs, Checkbox, Table } from "antd";

const { TabPane } = Tabs;
const Scope2Tab = () => {
  const [selectAll, setSelectAll] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRowClick = (record) => {
    const key = record.key;
    const newSelectedRowKeys = [...selectedRowKeys];

    if (newSelectedRowKeys.includes(key)) {
      newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(key), 1);
    } else {
      newSelectedRowKeys.push(key);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          <p className="m-0 fw-500 text-black">{_}</p>
          <p className="m-0 fs-12 text-black-60">{record.category}</p>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Direct Line Energy Transfer",
      category: "Sites",
    },
    {
      key: "2",
      name: "Purchased Cooling",
      category: "Sites",
    },
    {
      key: "3",
      name: "Purchased Electricity - Facilitys",
      category: "Sites",
    },
    {
      key: "4",
      name: "Purchased Heat & Steam",
      category: "Sites",
    },
    {
      key: "5",
      name: "Purchased Electricity - Fleet On Site Fueling",
      category: "Assets",
    },
    {
      key: "6",
      name: "Purchased Electricity - Fleet Remote Fueling",
      category: "Assets",
    },
 
  ];

  const onSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedRowKeys(checked ? data.map((item) => item.key) : []);
  };
  const extraContent = (
    <Checkbox onChange={onSelectAllChange} checked={selectAll}>
      Select All
    </Checkbox>
  );
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={extraContent}
      style={{ marginTop: 36 }}
    >
      <TabPane tab="All" key="1">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: () => {
              onRowClick(record);
            },
          })}
        />
      </TabPane>
      <TabPane tab="Assets" key="2"></TabPane>
      <TabPane tab="Sites" key="3"></TabPane>
    </Tabs>
  );
};

export default Scope2Tab;
